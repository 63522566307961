import dayjs from 'dayjs';
import { useSitemap } from 'hooks';
import { ExpertNotificationRelationsType, ExpertNotificationTypeEnum } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';
import { useMarkExpertNotificationAsViewedMutation } from '../../../../hooks/api/markExpertNotificationAsViewed/markExpertNotificationAsViewed.generated';
import i18n from '../../../../locale/i18n';
import { generateNotificationContent } from './notificationTypes';

interface NotificationItemProps {
  hasViewDate?: boolean;
  date: string;
  type?: string;
  notificationId: string;
  matchScore?: number;
  relatedTo?: ExpertNotificationRelationsType;
  jobOrderName: string;
  jobOrderId: string;
}

const NotificationItem = ({
  hasViewDate,
  date,
  type,
  notificationId,
  matchScore,
  jobOrderName,
  relatedTo,
  jobOrderId,
}: NotificationItemProps) => {
  const today = dayjs().startOf('day').toISOString();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const notificationData = generateNotificationContent(type, matchScore, jobOrderName);

  const { label: notificationLabel, body: notificationBody } = notificationData;
  const isToday = date === today;
  const [markExpertNotificationAsViewed] = useMarkExpertNotificationAsViewedMutation();
  const handleOnNotificationClick = async () => {
    await markExpertNotificationAsViewed({ variables: { notificationId } });
    if (type === ExpertNotificationTypeEnum.JobOrderMatched) {
      navigate(
        sitemap.offer(
          (relatedTo?.jobOrderId ? relatedTo?.jobOrderId : relatedTo?.jobOrderApplicationId) ||
            jobOrderId,
        ),
      );
    }

    if (type === ExpertNotificationTypeEnum.JobOrderApplicationStatusChange) {
      navigate(sitemap.application(jobOrderId));
    }
  };

  return (
    <div
      className={mergeClasses(
        'text-sm pt-[15px] pb-[22px] border-b border-gray-200 px-6 cursor-pointer lg:hover:bg-gray-400/[0.2]',
        !hasViewDate && 'bg-primary-500/[.05]',
      )}
      onClick={() => handleOnNotificationClick()}
    >
      <div className="flex flex-row justify-between items-center">
        <p className="font-semibold pb-2">{notificationLabel}</p>
        <p className="text-gray-450">
          {isToday ? t('datePicker:today') : dayjs(date).locale(i18n.language).format('DD.MM.YYYY')}
        </p>
      </div>
      <span
        className={mergeClasses(
          'line-clamp-2 w-[90%]',
          !hasViewDate ? 'font-medium' : 'font-normal',
        )}
      >
        {notificationBody}
      </span>
    </div>
  );
};

export default NotificationItem;
