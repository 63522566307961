import { useReactiveVar } from '@apollo/client';
import { ExternalOffer, PageLoadingSpinner } from 'components';
import { useSitemap } from 'hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isAuthenticatedVar } from 'reactive-vars';
import { ExpiredOfferInfo } from '../../components/Modals/OfferDetailsModal/subcomponents/ExpiredOfferInfo';
import { usePublicOfferQuery } from '../../hooks/api/publicOffer/publicOffer.generated';

const OfferNotFound = () => {
  const navigate = useNavigate();
  const sitemap = useSitemap();

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center px-4 md:px-0">
      <ExpiredOfferInfo onSeeMoreOffers={() => navigate(sitemap.signIn)} />
    </div>
  );
};

export const ExternalOfferPage = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const { offerId } = useParams();
  const [searchParams] = useSearchParams();
  const isPreview = !!searchParams.get('preview');

  const {
    data: offerData,
    loading: offerLoading,
    error,
  } = usePublicOfferQuery({
    variables: {
      offerId: offerId ?? '',
      shouldCreateEvent: !isPreview,
    },
  });

  if (!offerId) {
    return <OfferNotFound />;
  }

  if (offerLoading) {
    return <PageLoadingSpinner />;
  }

  const { publicOffer } = offerData ?? {};

  if (!publicOffer || !!error) {
    return <OfferNotFound />;
  }

  if (isAuthenticated && !isPreview) {
    navigate(sitemap.offer(publicOffer.jobOrderId, offerId));
  }

  return <ExternalOffer offer={publicOffer} />;
};
