import { CircularProgress, SmallButton } from 'components';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

export const ExternalOfferCTASection = () => {
  const navigate = useNavigate();

  return (
    <div className="p-4 lg:p-6 border border-gray-200 rounded-2xl flex flex-col lg:flex-row gap-x-6 gap-y-1 lg:gap-y-0">
      <div className="flex flex-row gap-3 lg:block items-center">
        <CircularProgress
          alternativeText="?"
          className="min-w-[64px] lg:min-w-[144px] w-16 lg:w-36 h-16 lg:h-36"
          staticColor="green"
          textClassName="text-black text-[14px] lg:text-[28px] leading-[15px] font-semibold"
          value={40}
        />
        <p className="text-[16px] font-bold lg:hidden">
          {t('offers:externalOffer.matchingHeader')}
        </p>
      </div>
      <div className="flex flex-col gap-y-2 text-sm w-full">
        <p className="text-[16px] lg:text-[20px] font-bold hidden lg:block">
          {t('offers:externalOffer.matchingHeader')}
        </p>
        <div>
          <p className="font-semibold">{t('offers:externalOffer.matchingSubheader')}</p>
          <ul className="list-disc ml-6">
            <li>{t('offers:externalOffer.bulletPoints:skillsAndRequirements')}</li>
            <li>{t('offers:externalOffer.bulletPoints:newestOffers')}</li>
            <li>{t('offers:externalOffer.bulletPoints:meetings')}</li>
          </ul>
        </div>
        <SmallButton
          className="pt-2"
          label={t('offers:externalOffer.checkOffersLink')}
          labelClassName="text-[14px] lg:text-[16px] text-primary-500 font-medium underline"
          onClick={() => navigate('/OTP-sign-up')}
        />
      </div>
    </div>
  );
};
