import { t } from 'i18next';
import { FC, useState } from 'react';
import { PublicOfferQuery } from '../../hooks/api/publicOffer/publicOffer.generated';
import { SkillGrid } from '../Modals/OfferDetailsModal/subcomponents';
import {
  ExternalOfferApplicationForm,
  ExternalOfferContactPerson,
  ExternalOfferCTASection,
  ExternalOfferDescription,
  ExternalOfferFinishOnboarding,
  ExternalOfferHeader,
  ExternalOfferRate,
} from './subcomponents';

export type ExternalOfferType = PublicOfferQuery['publicOffer'];

interface ExternalOfferProps {
  offer: ExternalOfferType;
  isOnOnboarding?: boolean;
  onFinishOnboardingClick?: () => void;
}

export const ExternalOffer: FC<ExternalOfferProps> = ({
  offer,
  isOnOnboarding = false,
  onFinishOnboardingClick,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { id, description, owner, jobOrderId, jobOrder, isRateDisplayed } = offer;
  const {
    role,
    leadingTechnologyName,
    minEnglishLevel,
    locationPreference,
    rateRangeTo,
    rateRangeFrom,
    seniority,
    workMode,
    skillsMustHave,
    skillsNiceToHave,
  } = jobOrder ?? {};

  return (
    <div className="flex flex-col items-center">
      <div className="p-4 mb-20 lg:mb-0 lg:p-16 max-w-[100vw] lg:max-w-[1440px] w-full">
        <ExternalOfferHeader leadingTechnologyName={leadingTechnologyName} role={role} />
        <div className="flex flex-col lg:flex-row gap-x-14">
          <div className="flex flex-col gap-y-8 lg:gap-y-10 w-full">
            <ExternalOfferCTASection />
            <ExternalOfferRate
              className="lg:hidden"
              isRateDisplayed={isRateDisplayed}
              locationPreference={locationPreference}
              minEnglishLevel={minEnglishLevel}
              rateFrom={rateRangeFrom || 0}
              rateTo={rateRangeTo || 0}
              seniority={seniority}
              workMode={workMode}
            />
            <div className="block lg:hidden">
              {isOnOnboarding ? (
                <ExternalOfferFinishOnboarding onFinishOnboardingClick={onFinishOnboardingClick} />
              ) : (
                <ExternalOfferApplicationForm
                  contextContactEmail={offer.contextContactEmail}
                  isSuccess={isSuccess}
                  jobOrderId={jobOrderId}
                  offerId={id}
                  onApplySuccess={() => setIsSuccess(true)}
                />
              )}
            </div>
            {(!!skillsMustHave?.length || !!skillsMustHave?.length) && (
              <div>
                <p className="text-[16px] lg:text-[18px] font-bold pb-6 lg:pb-8">
                  {t('forms:labels.skills')}
                </p>
                {!!skillsMustHave?.length && (
                  <SkillGrid
                    className="pb-10 lg:pb-12"
                    isPrimary
                    skills={skillsMustHave}
                    title={t('forms:labels.required')}
                  />
                )}
                {!!skillsNiceToHave?.length && (
                  <SkillGrid
                    noLevelSkill
                    skills={skillsNiceToHave}
                    title={t('forms:labels.preferred')}
                  />
                )}
              </div>
            )}
            {description && (
              <ExternalOfferDescription className="w-full" description={description} />
            )}
            <ExternalOfferContactPerson owner={owner} />
          </div>
          <div className="flex flex-col gap-y-8 lg:min-w-[424px] lg:max-w-[424px] mt-8 lg:mt-0">
            <div className="hidden lg:flex">
              <ExternalOfferRate
                isRateDisplayed={isRateDisplayed}
                locationPreference={locationPreference}
                minEnglishLevel={minEnglishLevel}
                rateFrom={rateRangeFrom || 0}
                rateTo={rateRangeTo || 0}
                seniority={seniority}
                workMode={workMode}
              />
            </div>
            <div className="lg:block hidden">
              {isOnOnboarding ? (
                <ExternalOfferFinishOnboarding onFinishOnboardingClick={onFinishOnboardingClick} />
              ) : (
                <ExternalOfferApplicationForm
                  contextContactEmail={offer.contextContactEmail}
                  isSuccess={isSuccess}
                  jobOrderId={jobOrderId}
                  offerId={id}
                  onApplySuccess={() => setIsSuccess(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
