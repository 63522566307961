import { HomeIcon, IconType, UserIcon } from 'icons';
import {
  JobOrderApplicationProcessStatusEnum,
  JobOrderApplicationStatusEnum,
  JobOrderQueryType,
} from 'interfaces';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { JobOrderPickedMeetingDates } from './JobOrderPickedMeetingDates';

interface ContactPersonProps {
  jobOrder: JobOrderQueryType | null;
}

export const IconListItem = ({
  icon: Icon,
  children,
}: {
  children?: ReactNode;
  icon: IconType;
}) => (
  <li className="flex items-center py-2 text-[14px] md:text-[16px] leading-[18px]">
    <Icon className="w-4 min-w-4 h-4 mr-4 text-gray-400" />
    {children}
  </li>
);

export const JobOrderDetailsContactPerson: FC<ContactPersonProps> = ({ jobOrder }) => {
  const { t } = useTranslation();
  const { application } = jobOrder ?? {};
  const { meetingDates, selectedMeetingDate, processStatus, status } = application ?? {};
  const owner = jobOrder?.owner;

  const isApplied = status && status !== JobOrderApplicationStatusEnum.Cancelled;
  const isApplicationExpired = processStatus === JobOrderApplicationProcessStatusEnum.OfferExpired;

  const showPickedMeetingDates =
    !isApplicationExpired && (!isApplied || !selectedMeetingDate) && !!meetingDates?.length;

  return (
    <div className="mt-4 md:mt-8 md:mx-8">
      {owner && (
        <div>
          <p className="mb-2 font-medium text-[14px] md:text-[16px] leading-[19px] md:leading-[22px]">
            {t('offers:contactPerson.title')}
          </p>
          <ul>
            <li>
              <IconListItem key={`contactPerson_${jobOrder?.owner?.name}`} icon={UserIcon}>
                {jobOrder?.owner?.name}
              </IconListItem>
            </li>
            <li>
              <IconListItem key={`contactPerson_${jobOrder?.owner?.email}`} icon={HomeIcon}>
                <a className="hover:underline" href={`mailto:${jobOrder?.owner?.email}`}>
                  {jobOrder?.owner?.email}
                </a>
              </IconListItem>
            </li>
          </ul>
        </div>
      )}
      {showPickedMeetingDates && <JobOrderPickedMeetingDates jobOrder={jobOrder} />}
    </div>
  );
};
