import { JsonObject } from 'type-fest';

export const offers: JsonObject = {
  recommendedOffers: {
    title: 'Wybrane oferty dla Ciebie',
    subtitle: 'Dopasowane oferty na podstawie Twojego profilu',
  },
  publicOfferAppliedOffers: {
    title: 'Moje zgłoszenia',
    subtitle: 'Oferty na które wysłano CV z zewnętrznych platform',
  },
  notFoundPlaceholders: {
    jobOrders: 'Nie znaleziono żadnych ofert',
    recommendedOffers:
      'Obecnie nie ma ofert dopasowanych do Twoich kryteriów.\nOtrzymasz powiadomienie gdy takie się pojawią.',
  },
  offerListItem: {
    hideOffer: 'Ukryj',
    showOffer: 'Pokaż na liście',
    saveOffer: 'Zapisz',
    unsaveOffer: 'Usuń z zapisanych',
    scheduleMeeting: 'Aplikuj i umów spotkanie',
    added: 'Dodana',
    new: 'Nowa',
    applied: 'Aplikowano',
    expired: 'Wygasła',
    meetingScheduled: 'Spotkanie umówione',
    arrangingMeeting: 'Umawiamy spotkanie',
    meetingDate: 'Termin spotkania',
    selectMeetingDate: 'Wybierz termin',
    notEnoughScore: 'Niewystarczający procent dopasowania, aby móc aplikować na tę ofertę',
    pickedMeetingDates: 'Wybrane terminy: ',
    pickedMeetingDate: 'Wybrany termin: ',
    offerAddedToSaved: 'Dodano ofertę do zapisanych',
    goToMeeting: 'Przejdź do spotkania',
    copiedToClipboard: 'Skopiowano do schowka',
    failCopyToClipboard: 'Nie udało się skopiować do schowka',
  },
  offerRecommendation: {
    seeOtherOffer: 'Zobacz inną ofertę, która do Ciebie pasuje',
    seeDetails: 'Zobacz szczegóły',
    wantToSeeOffers: 'Chcesz zobaczyć oferty, które będą dopasowane?',
    fillProfileDesc:
      'Uzupełnij profil i zwiększ szanse na dopasowanie do ofert. Pamiętaj, aby na bieżąco dodawać informacje do swojego profilu.',
    fillProfile: 'Uzupełnij profil',
  },
  processStatus: {
    rejected: 'Niestety tym razem się nie udało',
    offerExpired: 'Oferta wygasła',
    scheduledForCq: 'Wstępna rozmowa, poznajmy się!',
    acceptedAfterCq: 'Rekrutacja w toku',
    appointmentForCi: 'Rozmowa rekrutacyjna',
    ciPerformed: 'Decyzja jest blisko!',
    offering: 'Sukces! zaproponowano współpracę',
    placement: 'Rekrutacja zakończona pozytywnie',
  },
  otherOffers: {
    title: 'Inne dostępne oferty',
  },
  tabs: {
    all: 'Wszystkie',
    applied: 'Moje aplikacje',
    saved: 'Zapisane',
    hidden: 'Ukryte',
  },
  contactPerson: {
    title: 'Masz dodatkowe pytania? Porozmawiajmy',
  },
  externalOffer: {
    matchingHeader: 'Chcesz sprawdzić jak bardzo pasujesz do tej oferty?',
    matchingSubheader: 'Dołącz do platformy C_hub! Sprawdź jakie korzyści na Ciebie czekają:',
    bulletPoints: {
      skillsAndRequirements: 'Porównywanie Twoich umiejętności z wymaganiami z oferty',
      newestOffers: 'Informacje na bieżąco o najnowszych ofertach',
      meetings: 'Aplikowanie i umawianie spotkania z rekruterem',
    },
    checkOffersLink: 'Sprawdź oferty na C_hub',
    offerDescriptionHeader: 'Opis oferty',
    addCV: 'Dodaj CV',
    allowedCVFormats: 'Dozwolone formaty: PDF, DOCX. Maksymalny rozmiar 5 MB.',
    apply: 'Wyślij zgłoszenie i dołącz do C_hub!',
    dragAndDropFile: 'Przeciągnij i upuść plik',
    chooseFile: 'Wybierz plik',
    notFound: 'Oferta nie istnieje lub nie jest już aktywna!',
    successBox: {
      title: 'Twoja aplikacja została wysłana!',
      thankYou: 'Dziękujemy za zainteresowanie się ofertą.',
      emailSent: 'Na adres e-mail została wysłana wiadomość z potwierdzeniem.',
    },
    loginForm: {
      title: 'Chcesz zobaczyć więcej ofert?',
    },
    onboardingBox: {
      title: 'Aplikuj i umów spotkanie',
      description:
        'Dokończ onboarding na swoim profilu, aby móc sprawdzić dopasowanie do oferty, aplikować i umówić spotkanie.',
      finishOnboarding: 'Dokończ onboarding',
      fillInProfile: 'Uzupełnij profil',
    },
  },
  expiredOffer: {
    title: 'Niestety oferta wygasła',
    notFoundTitle: 'Nie znaleziono oferty',
    subtitle: 'Kliknij poniżesz i sprawdź jakie inne oferty dla Ciebie przygotowaliśmy',
    checkOffers: 'Sprawdź oferty',
  },
};
