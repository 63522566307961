import { useMemo } from 'react';

const featureFlags = {
  notifications: process.env.REACT_APP_FEATURE_FLAG_NOTIFICATIONS !== 'false',
  prefillEmailDisabled: process.env.REACT_APP_FEATURE_FLAG_DISABLE_PREFILL !== undefined,
};

const useFeatureFlags = () => {
  const memoizedFeatureFlags = useMemo(() => featureFlags, []);

  return memoizedFeatureFlags;
};

export default useFeatureFlags;
