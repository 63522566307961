import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResendOtpMutation } from './api/resendOtp/resendOtp.generated';
import { useStartPasswordResetMutation } from './api/startPasswordReset/startPasswordReset.generated';
import { useVerifyPasswordResetOtpMutation } from './api/verifyPasswordResetOtp/verifyPasswordResetOtp.generated';
import { useAuth } from './useAuth';
import { useSitemap } from './useSitemap';

export type AuthFlowType = 'EMAIL_OTP' | 'USER_PASSWORD' | null;

export const useAuthWithOTP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const [resendOtp] = useResendOtpMutation();
  const [startPasswordReset] = useStartPasswordResetMutation();
  const [verifyPasswordResetOtp] = useVerifyPasswordResetOtpMutation();

  const {
    signInToCognito,
    signInToCognitoWithPassword,
    signInToCognitoWithOtp,
    authLoading,
    resetCognitoPassword,
  } = useAuth();

  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isPasswordResetFlowStarted, setIsPasswordResetFlowStarted] = useState<boolean>(false);
  const [flowType, setFlowType] = useState<AuthFlowType>(null);
  const [initiateAuthResult, setInitiateAuthResult] = useState<any>(undefined);

  const [passwordResetRetriesCount, setPasswordResetRetriesCount] = useState(0);
  const [isInErrorState, setIsInErrorState] = useState(false);

  const onEmailSubmit = async (email: string) => {
    setUserEmail(email);
    const result = await signInToCognito(email);
    setFlowType(
      typeof result.flowType === 'string'
        ? (result.flowType as 'EMAIL_OTP' | 'USER_PASSWORD')
        : null,
    );
    setInitiateAuthResult(result.initiateAuthResult);
  };

  const onOTPSubmit = async (otp: string, displayErrorToast = true) => {
    try {
      const result = await signInToCognitoWithOtp({
        initiateAuthResult,
        otp,
      });

      if (result.Session && displayErrorToast) {
        toast.error(t('toasts:signInForm.invalidCode'));
      }

      if (result.attributes) {
        toast.success(t('toasts:signInForm.signInSuccess'));
      }

      return result;
    } catch (err) {
      console.error(err);
      setIsInErrorState(true);
      setFlowType(null);
    }
  };

  const onStartAgainClick = () => {
    setFlowType(null);
    setIsPasswordResetFlowStarted(false);
    setIsInErrorState(false);
    setPasswordResetRetriesCount(0);
    navigate(sitemap.signIn);
  };

  const onPasswordSubmit = async (password: string): Promise<string> => {
    const res = await signInToCognitoWithPassword({
      email: userEmail as string,
      password,
    });
    if (res?.code) {
      return res.code;
    }
    return 'Success';
  };

  const onResendCodeClick = useCallback(async () => {
    if (flowType === 'EMAIL_OTP' && userEmail) {
      resetCognitoPassword({ email: userEmail });
    }

    if (!userEmail) {
      throw new Error('Empty userEmail');
    }

    await resendOtp({
      variables: {
        email: userEmail,
      },
    });

    toast.success(t('toasts:signInForm.codeResend'));
  }, [userEmail]);

  const onResetPasswordStartClick = useCallback(async () => {
    setPasswordResetRetriesCount(0);
    setIsPasswordResetFlowStarted(true);
    await startPasswordReset({
      variables: {
        email: userEmail as string,
      },
    });
  }, [setIsPasswordResetFlowStarted, startPasswordReset, userEmail, setPasswordResetRetriesCount]);

  const onResetPasswordOtpSubmit = useCallback(
    async (otp: string) => {
      const newRetriesCount = passwordResetRetriesCount + 1;
      setPasswordResetRetriesCount(newRetriesCount);

      if (newRetriesCount >= 3) {
        setFlowType(null);
        setIsPasswordResetFlowStarted(false);
        setIsInErrorState(true);
      }

      const result = await verifyPasswordResetOtp({
        variables: {
          email: userEmail as string,
          otp,
        },
      });

      if (!result.data?.verifyPasswordResetOtp) {
        toast.error(t('toasts:signInForm.invalidCode'));
        return;
      }

      await onEmailSubmit(userEmail as string);
      await onOTPSubmit(otp);
    },
    [
      verifyPasswordResetOtp,
      userEmail,
      onEmailSubmit,
      onOTPSubmit,
      setPasswordResetRetriesCount,
      passwordResetRetriesCount,
      flowType,
      isPasswordResetFlowStarted,
    ],
  );

  return {
    flowType,
    loading: authLoading,
    onEmailSubmit,
    onOTPSubmit,
    onPasswordSubmit,
    onResendCodeClick,
    onResetPasswordStartClick,
    isPasswordResetFlowStarted,
    onResetPasswordOtpSubmit,
    isInErrorState,
    onStartAgainClick,
    setIsPasswordResetFlowStarted,
  };
};
