import { t } from 'i18next';
import { mergeClasses } from 'utils';
import privacyPolicy from '../../assets/pdfs/Regulations-and-Privacy-Policy-v2.pdf';

interface LegalAgreementsProps {
  className?: string;
  textClassName?: string;
}

export const LegalAgreements = ({ className, textClassName }: LegalAgreementsProps) => {
  return (
    <>
      <div className={mergeClasses('flex', className)}>
        <p
          className={mergeClasses(
            'text-[14px] lg:text-[16px] leading-[19.5px] lg:leading-[22.5px] text-gray-500',
            textClassName,
          )}
        >
          <span>{t('auth:signUpForm.legalAgreement.byCreatingAccount')}</span>
          <a className="link-primary" href={privacyPolicy} rel="noreferrer" target="_blank">
            {t('auth:signUpForm.legalAgreement.terms')}
          </a>
          <span> {t('auth:signUpForm.legalAgreement.and')} </span>
          <a
            className="link-primary"
            href={`${privacyPolicy}#page=11`}
            rel="noreferrer"
            target="_blank"
          >
            {t('auth:signUpForm.legalAgreement.policy')}
          </a>
          <span>. {t('auth:signUpForm.legalAgreement.accountRegistration')}</span>
        </p>
      </div>

      <p
        className={mergeClasses(
          'text-[14px] lg:text-[16px] leading-[19.5px] lg:leading-[22.5px] text-gray-400 pt-6 lg:pt-[18px]',
          textClassName,
        )}
      >
        <span>{t('auth:signUpForm.legalAgreement.administrativeInfo')}</span>
        <a className="link-secondary" href="mailto:office@connectis.pl">
          {t('auth:signUpForm.legalAgreement.officeEmail')}
        </a>
        <span>. {t('auth:signUpForm.legalAgreement.contactInfo')}</span>
        <a className="link-secondary" href="mailto:office@connectis.pl">
          {t('auth:signUpForm.legalAgreement.officeEmail')}
        </a>
        <span> {t('auth:signUpForm.legalAgreement.dataInspectorCopy')} </span>
        <a className="link-secondary" href="mailto:iod@connectis.pl">
          {t('auth:signUpForm.legalAgreement.dataInspectorEmail')}
        </a>
        <span>.</span>
      </p>
    </>
  );
};
