import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from 'components';
import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ResendCodeButton } from './ResendCodeButton';

interface SignInOTPInputStepFormValues {
  otp: string;
}

const signInOTPInputStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SignInOTPInputStepFormValues>>({
    otp: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.verificationCode'),
      }) ?? '',
    ),
  });

interface SignInOTPInputStepProps {
  onOTPSubmit: (otp: string) => any;
  onResendCodeClick: () => any;
  loading: boolean;
}

export const SignInOTPInputStep = ({
  onOTPSubmit,
  onResendCodeClick,
  loading,
}: SignInOTPInputStepProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInOTPInputStepFormValues>({
    resolver: yupResolver(signInOTPInputStepSchema(t)),
  });

  const onSubmit = (values: SignInOTPInputStepFormValues) => onOTPSubmit(values.otp);

  return (
    <div className="flex flex-col items-stretch justify-center w-full max-w-[538px] pt-2 pb-0 lg:py-10">
      <p className="font-bold text-[24px] lg:text-[40px] mb-2 lg:mb-4">
        {t('auth:OTPcode.typeCode')}
      </p>
      <p className="text-[14px] lg:text-[16px] text-gray-500 mb-6 lg:mb-8">
        {t('auth:OTPcode.codeSubtitle')}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register('otp')}
          error={errors.otp}
          label={t('forms:labels.verificationCode')}
          labelClassName="text-[14px] lg:text-[16px]"
        />
        <ResendCodeButton onClick={onResendCodeClick} />
        <Button
          className="w-full mt-8 !rounded-lg"
          isLoading={loading}
          label={t('auth:OTPcode.checkOffers')}
          labelClassName="text-[14px] lg:text-[16px]"
          type="submit"
        />
      </form>
    </div>
  );
};
