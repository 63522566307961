import { CheckIcon } from 'icons';
import { useTranslation } from 'react-i18next';

export const SuccessBox = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center border border-gray-200 rounded-xl p-4 lg:p-8 lg:pb-24 gap-4">
      <CheckIcon className="text-green-500 w-14 h-14" />
      <p className="text-lg lg:text-xl font-bold text-center max-w-[320px]">
        {t('offers:externalOffer.successBox.title')}
      </p>
      <p className="text-center text-sm lg:text-base">
        {t('offers:externalOffer.successBox.thankYou')}
      </p>
      <p className="text-center text-sm lg:text-base">
        {t('offers:externalOffer.successBox.emailSent')}
      </p>
    </div>
  );
};
