import { IconButton } from 'components';
import { MobileMenuIcon } from 'icons';
import { FC, useState } from 'react';
import { mergeClasses } from 'utils';
import connectisWhiteLogo from '../../../assets/images/connectis-white-logo.svg';
import bgImageSrc from '../../../assets/images/profile-bg.png';
import { ExpertNotificationsQuery } from '../../../hooks/api/expertNotifications/expertNotifications.generated';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import NotificationButton from '../../Buttons/NotificationButton/NotificationButton';
import NotificationModule from '../../Navigation/NotificationModule/NotificationModule';

interface MobileFloatingMenuProps {
  isVisible?: boolean;
  onMobileMenuToggle?: () => void;
  className?: string;
  notificationsData?: ExpertNotificationsQuery;
}

export const MobileFloatingMenu: FC<MobileFloatingMenuProps> = ({
  onMobileMenuToggle,
  className,
  isVisible = false,
  notificationsData,
}) => {
  const featureFlags = useFeatureFlags();
  const [isNotificationModuleOpen, setIsNotificationModuleOpen] = useState(false);
  const onNotificationIconClick = () => {
    setIsNotificationModuleOpen((prev) => !prev);
  };

  const hasUnviewedNotifications = notificationsData?.expertNotifications.notifications.some(
    (notification) => !notification.viewedAt,
  );
  return (
    <div
      className={mergeClasses(
        'lg:hidden flex items-center h-[72px] bg-primary-800 bg-center bg-cover bg-blend-soft-light rounded-b-2xl fixed top-0 w-full transition-all duration-150 ease-in-out z-10 p-6',
        isVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-full',
        className,
      )}
      style={{
        backgroundImage: `url(${bgImageSrc})`,
      }}
    >
      <img alt="logo" className="w-[120px] h-fit" src={connectisWhiteLogo} />
      <div className="!p-0 text-white !w-6 !min-h-[24px] !min-w-[24px] absolute right-24 top-6 flex flex-row gap-4">
        {featureFlags.notifications && (
          <NotificationButton
            className="text-white !w-6 min-h-[24px] mb-2"
            isNotificationModuleOpen={isNotificationModuleOpen}
            isNotificationUnread={hasUnviewedNotifications}
            onClick={onNotificationIconClick}
          />
        )}
        <IconButton
          className="!p-0 text-white !w-6 min-h-[24px] min-w-[24px]"
          icon={MobileMenuIcon}
          iconClassName="!w-6 transition-all min-h-[24px] min-w-[24px] md:w-full z-[19] drop-shadow-[3px_2px_1px_rgba(0,0,0,1)]"
          onClick={onMobileMenuToggle}
        />
      </div>
      <NotificationModule
        isOpen={isNotificationModuleOpen}
        notificationData={notificationsData?.expertNotifications.notifications}
        onClose={() => setIsNotificationModuleOpen(false)}
      />
    </div>
  );
};
