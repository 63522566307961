import { IconButton } from 'components';
import dayjs from 'dayjs';
import { useCopyToClipboard } from 'hooks';
import i18n, { t } from 'i18next';
import { CopyToClipboardIcon } from 'icons';
import {
  isNil,
  JobOrderApplicationProcessStatusEnum,
  JobOrderApplicationStatusEnum,
  JobOrderQueryType,
  LanguageLevelEnum,
} from 'interfaces';
import { FC } from 'react';
import { findElementByKey, mergeClasses } from 'utils';
import ConnectisLogo from '../../../../assets/images/connectis-logo.svg';
import { useOnboardingDataQuery } from '../../../../hooks/api/onboardingData/onboardingData.generated';
import useBreakpoint, { isMinimumBreakpoint } from '../../../../hooks/useBreakpoint';
import useMobile from '../../../../hooks/useMobile';
import {
  getStatusBadge,
  recruitmentInterviewStatuses,
} from '../../../JobOrderList/subcomponents/JobOrderListItem/utils';

export const determineRequiredLanguageLevel = (
  englishLevel: LanguageLevelEnum | null | undefined,
) => {
  switch (englishLevel) {
    case LanguageLevelEnum.None:
      return t('dictionaries:languageLevelsShort.none');
    case LanguageLevelEnum.Starter:
      return t('dictionaries:languageLevelsShort.1');
    case LanguageLevelEnum.Elementary:
      return t('dictionaries:languageLevelsShort.2');
    case LanguageLevelEnum.PreIntermediate:
      return t('dictionaries:languageLevelsShort.3');
    case LanguageLevelEnum.Intermediate:
      return t('dictionaries:languageLevelsShort.4');
    case LanguageLevelEnum.UpperIntermediate:
      return t('dictionaries:languageLevelsShort.5');
    case LanguageLevelEnum.Advanced:
      return t('dictionaries:languageLevelsShort.6');
    default:
      if (!isNil(englishLevel)) {
        const unknownLevel: never = englishLevel;
        console.warn('Unknown level:', unknownLevel);
      }
      return '';
  }
};

interface JobOrderDetailsHeaderProps {
  jobOrder: JobOrderQueryType | null;
}

export const JobOrderDetailsHeader: FC<JobOrderDetailsHeaderProps> = ({ jobOrder }) => {
  const { role, assignmentDate } = jobOrder ?? {
    score: {},
  };

  const { copyToClipboard } = useCopyToClipboard();

  const timeFromNow = dayjs(assignmentDate).locale(i18n.language).fromNow();

  const { processStatus, status, meetingUrl, selectedMeetingDate } = jobOrder?.application ?? {};

  const isMeetingStatus =
    processStatus === JobOrderApplicationProcessStatusEnum.ScheduledForCq ||
    (processStatus && recruitmentInterviewStatuses.includes(processStatus));

  const isApplied = status && status !== JobOrderApplicationStatusEnum.Cancelled;
  const isMeetingScheduled = !!(isApplied && selectedMeetingDate && isMeetingStatus);

  const { isMobile } = useMobile();
  const breakpoint = useBreakpoint();
  const isMinMdBreakpoint = isMinimumBreakpoint('lg');

  const leadingTechnology = jobOrder?.leadingTechnology;
  const { data: onboardingData } = useOnboardingDataQuery();
  const { tags } = onboardingData ?? { tags: [] };
  const leadingTechName = leadingTechnology
    ? findElementByKey(tags, 'id', leadingTechnology)?.name
    : '';

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-8">
        <div className="flex flex-col md:flex-row md:gap-6 lg:items-center md:pb-8">
          <p className="text-[20px] leading-[19px] md:text-4xl font-bold pb-[18px] md:pb-2">
            {role} {leadingTechName}
          </p>
          {isMobile && (
            <div className="flex items-start self-start pb-4">
              <p className="text-sm flex flex-col space-y-0.5">
                <img alt="logo" className="w-[93px]" src={ConnectisLogo} />
              </p>
            </div>
          )}
          <div className="flex items-center !gap-6">
            {assignmentDate ? (
              <p
                className={mergeClasses(
                  'text-gray-400 text-[14px] md:text-[16px] leading-[21px] md:leading-[24px] whitespace-nowrap',
                  isApplied ? 'self-start' : '',
                )}
              >
                {t('offers:offerListItem.added')}: {timeFromNow}
              </p>
            ) : undefined}

            {isMinMdBreakpoint(breakpoint) && (
              <div className="whitespace-nowrap">
                {isMobile && getStatusBadge(t, { processStatus })}
              </div>
            )}
          </div>

          <div className="flex flex-col items-end absolute right-4 top-12 lg:right-[100px]">
            {isMinMdBreakpoint(breakpoint) && (isMeetingScheduled || isApplied) && (
              <div className="flex flex-col gap-4 items-end">
                {getStatusBadge(t, { processStatus })}
                {isMeetingScheduled && (
                  <p className="text-[20px] leading-[27px] font-semibold capitalize whitespace-nowrap">
                    {dayjs(jobOrder?.application?.selectedMeetingDate)
                      .locale(i18n.language)
                      .format('dddd, DD.MM.YY HH:mm')}
                  </p>
                )}
                {meetingUrl && isMeetingStatus && (
                  <div className="flex flex-row items-center gap-2 xl:justify-end">
                    <a
                      className="text-sm text-primary-500 hover:underline"
                      href={meetingUrl}
                      onClick={(e) => e.stopPropagation()}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t('offers:offerListItem.goToMeeting')}
                    </a>
                    <IconButton
                      className="!p-1"
                      icon={CopyToClipboardIcon}
                      iconClassName="w-4"
                      onClick={(e) => copyToClipboard(meetingUrl, e)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="flex items-start">
          <p className="text-sm flex flex-col space-y-0.5">
            <img alt="logo" className="w-[170px]" src={ConnectisLogo} />
          </p>
        </div>
      )}
      <div
        className={mergeClasses(
          'flex space-x-6 md:mt-6',
          (!isMinMdBreakpoint(breakpoint) && isMeetingScheduled) || isApplied ? 'flex-col' : '',
        )}
      >
        {!isMinMdBreakpoint(breakpoint) && (isMeetingScheduled || isApplied) && (
          <div className="!m-0 pt-4 whitespace-nowrap">
            {getStatusBadge(t, { processStatus })}
            {isMeetingScheduled && (
              <p className="text-[16px] leading-[22px] font-semibold capitalize whitespace-nowrap pt-2">
                {dayjs(jobOrder?.application?.selectedMeetingDate)
                  .locale(i18n.language)
                  .format('dddd, DD.MM.YY HH:mm')}
              </p>
            )}
            {meetingUrl && (
              <div className="flex flex-row pt-2 items-center gap-2 xl:justify-end">
                <a
                  className="text-sm text-primary-500 hover:underline"
                  href={meetingUrl}
                  onClick={(e) => e.stopPropagation()}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('offers:offerListItem.goToMeeting')}
                </a>
                <IconButton
                  className="!p-1"
                  icon={CopyToClipboardIcon}
                  iconClassName="w-4"
                  onClick={(e) => copyToClipboard(meetingUrl, e)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
