import { AddItemButton, InputLabel } from 'components';
import { FC, Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { findElementByKey } from 'utils';
import { OnboardingDataQuery } from '../../../../hooks/api/onboardingData/onboardingData.generated';
import { ErrorMessage } from '../../../Inputs/ErrorMessage';
import { RadioItem } from '../../../Inputs/Radio/RadioItem';
import { RoleStepValues } from '../../steps';
import { MainTechnologySelect } from './MainTechnologySelect';

type JobPositionsType = OnboardingDataQuery['jobPositions'];
interface JobPositionSelectProps {
  jobPositions: JobPositionsType;
}

export const JobPositionSelect: FC<JobPositionSelectProps> = ({ jobPositions }) => {
  const [mainJobPositions, setMainJobPositions] = useState<JobPositionsType>([]);
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    formState: { errors, isValid },
  } = useFormContext<RoleStepValues>();
  const jobPositionIdValue = watch('jobPositionId');
  const manuallyAddedJobPositionsValue = watch('manuallyAddedJobPositions');
  const requiredMainTechnologyLengthValue = watch('requiredMainTechnologyLength');

  const fieldError = errors.jobPositionId;

  const otherJobPositionOptions = jobPositions
    .filter(
      ({ jobPositionId }) => !findElementByKey(mainJobPositions, 'jobPositionId', jobPositionId),
    )
    .map(({ jobPositionId, name }) => ({
      value: jobPositionId,
      label: name,
    }));

  const handleAddOtherRole = (jobPositionId: string) => {
    const jobPosition = findElementByKey(jobPositions, 'jobPositionId', jobPositionId);
    if (!jobPosition) return;

    setValue('manuallyAddedJobPositions', [...manuallyAddedJobPositionsValue, jobPositionId]);
    setMainJobPositions((prev) => [...prev, jobPosition]);
  };

  const handleJobPositionChange = (jobPositionId: string) => {
    const jobPosition = findElementByKey(jobPositions, 'jobPositionId', jobPositionId);
    if (!jobPosition) return;

    setValue('requiredMainTechnologyLength', jobPosition.availableMainTechnologies.length);
    setValue('jobPositionId', jobPositionId, { shouldValidate: !isValid });
    setValue('mainTechnologyIds', []);
    setValue('tags', []);
    setValue('tagsSuperPowers', []);
  };

  useEffect(() => {
    if (!jobPositions.length) return;
    setMainJobPositions(
      jobPositions.filter(
        ({ jobPositionId, isOnMainList }) =>
          isOnMainList || manuallyAddedJobPositionsValue.includes(jobPositionId),
      ),
    );
  }, [jobPositions]);

  useEffect(() => {
    if (typeof requiredMainTechnologyLengthValue !== 'undefined') return;
    const jobPosition = findElementByKey(jobPositions, 'jobPositionId', jobPositionIdValue);
    if (!jobPosition) return;
    setValue('requiredMainTechnologyLength', jobPosition.availableMainTechnologies.length);
  }, [jobPositionIdValue, jobPositions]);

  return (
    <div className="flex flex-col md:block">
      <InputLabel label={t('onboarding:roleStep.jobPositionLabel')} />
      <div className="block md:grid md:gap-x-4 grid-area-jobPositionSelect order-2 md:order-1">
        {mainJobPositions.map(({ jobPositionId, name, availableMainTechnologies }) => {
          const isMainTechSelectCollapsed =
            jobPositionIdValue !== jobPositionId || !availableMainTechnologies.length;
          return (
            <Fragment key={jobPositionId}>
              <RadioItem
                className="mb-2 md:mb-4 min-h-[65px]"
                id={jobPositionId}
                isError={!!fieldError}
                label={name}
                name={jobPositionId}
                onChange={handleJobPositionChange}
                value={jobPositionIdValue}
              />
              {!!availableMainTechnologies.length && (
                <MainTechnologySelect
                  availableMainTechnologies={availableMainTechnologies}
                  isCollapsed={isMainTechSelectCollapsed}
                  jobPositionId={jobPositionId}
                />
              )}
            </Fragment>
          );
        })}
        <AddItemButton
          buttonClassName="text-xs mb-2 md:mb-4"
          centerButtonForGrid
          label={t('onboarding:roleStep.addOtherRole')}
          onChange={handleAddOtherRole}
          options={otherJobPositionOptions}
          usedInRadioComponent
        />
      </div>
      <ErrorMessage error={fieldError} />
      <div>
        <hr className="md:hidden block my-6 w-full" />
      </div>
    </div>
  );
};
