import { t } from 'i18next';
import privacyPolicy from '../../../assets/pdfs/Regulations-and-Privacy-Policy-v2.pdf';

export const ExternalOfferLegalNotes = () => {
  return (
    <div>
      <p className="text-[15px] lg:text-base text-gray-500 mt-4 lg:mt-6">
        <span>{t('auth:signUpForm.legalAgreement.byCreatingAccount')}</span>
        <a className="link-primary" href={privacyPolicy} rel="noreferrer" target="_blank">
          {t('auth:signUpForm.legalAgreement.terms')}
        </a>
        <span> {t('auth:signUpForm.legalAgreement.and')} </span>
        <a
          className="link-primary"
          href={`${privacyPolicy}#page=11`}
          rel="noreferrer"
          target="_blank"
        >
          {t('auth:signUpForm.legalAgreement.policy')}
        </a>
        <span>. {t('auth:signUpForm.legalAgreement.accountRegistration')}</span>
      </p>
      <p className="text-[15px] lg:text-base text-gray-400 mt-4 lg:mt-6">
        <span>{t('auth:signUpForm.legalAgreement.administrativeInfo')}</span>
        <a className="link-secondary" href="mailto:office@connectis.pl">
          {t('auth:signUpForm.legalAgreement.officeEmail')}
        </a>
        <span>. {t('auth:signUpForm.legalAgreement.contactInfo')}</span>
        <a className="link-secondary" href="mailto:office@connectis.pl">
          {t('auth:signUpForm.legalAgreement.officeEmail')}
        </a>
        <span> {t('auth:signUpForm.legalAgreement.dataInspectorCopy')} </span>
        <a className="link-secondary" href="mailto:iod@connectis.pl">
          {t('auth:signUpForm.legalAgreement.dataInspectorEmail')}
        </a>
        <span>.</span>
      </p>
    </div>
  );
};
