import { Button, InfoBox, SmallButton } from 'components';
import { useAsyncCallback } from 'hooks';
import {
  CrossIcon,
  EyeIcon,
  HeartIcon,
  HeartSolidIcon,
  IconType,
  LanguageIcon,
  LevelsIcon,
  LocationIcon,
  OfficeIcon,
} from 'icons';
import { JobOrderQueryType } from 'interfaces';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { determineRequiredLanguageLevel } from './JobOrderDetailsHeader';

interface RateProps {
  jobOrder: JobOrderQueryType | null;
  onScheduleMeetingClick?: (jobOrderId: string) => void;
  onFavouriteClick?: (jobOrderId: string) => void;
  onHideOfferClick?: (jobOrderId: string) => void;
  isMeetingScheduled: boolean;
  isApplyButtonDisabled?: boolean;
}

interface IconListItemProps {
  icon: IconType;
  label: string;
  value?: string;
  className?: string;
}

export const RateIconListItem: FC<IconListItemProps> = ({
  icon: Icon,
  value,
  label,
  className,
}) => (
  <li className="flex items-start py-2 max-h-[58px] lg:py-[20px]">
    <Icon className="w-4 min-w-[16px] h-4 mr-2 text-gray-400" />
    <span className="text-gray-500 mr-1 text-[14px] lg:text-[16px] leading-[18px]">{label}:</span>
    <span
      className={mergeClasses('font-medium text-[14px] lg:text-[16px] leading-[18px]', className)}
    >
      {value}
    </span>
  </li>
);

export const JobOrderDetailsRate: FC<RateProps> = ({
  jobOrder,
  onFavouriteClick,
  onScheduleMeetingClick,
  onHideOfferClick,
  isMeetingScheduled,
  isApplyButtonDisabled = false,
}) => {
  const { t } = useTranslation();
  const {
    rateRangeFrom,
    rateRangeTo,
    workMode,
    locationPreference,
    jobOrderId,
    isSaved,
    isHidden,
    seniority,
    minEnglishLevel,
  } = jobOrder ?? {};

  const roundedRateRangeFrom = rateRangeFrom ? Math.round(rateRangeFrom) : 0;
  const roundedRateRangeTo = rateRangeTo ? Math.round(rateRangeTo) : 0;

  const showScheduleMeetingButton = !isApplyButtonDisabled && !isMeetingScheduled;

  const [handleHideOffer, { loading: hideJobOrderLoading }] = useAsyncCallback(onHideOfferClick);
  const [handleFavouriteOffer, { loading: favouriteJobOrderLoading }] =
    useAsyncCallback(onFavouriteClick);

  return jobOrderId ? (
    <div className="border border-gray-200 w-full md:w-auto md:min-w-[424px] rounded-xl">
      <div className="p-4 md:p-8 lg:pb-2">
        <p className="text-base md:text-lg font-bold mb-1 lg:mb-4">
          {t('forms:labels.rateDesired')}
        </p>
        <p className="md:mb-2 md:border-b md:border-b-gray-200">
          <span className="text-[24px] lg:text-[40px] leading-[56px] font-semibold mr-1">{`${roundedRateRangeFrom}-${roundedRateRangeTo} PLN`}</span>
          <span className="text-gray-400 text-[16px] lg:text-[20px] leading-[48px]">
            /{t('common:hourShort')}
          </span>
          <span className="text-gray-400 text-[16px] lg:text-[20px] leading-[48px]"> - B2B</span>
        </p>
        <ul>
          <RateIconListItem
            icon={LocationIcon}
            label={t('forms:labels.location')}
            value={locationPreference ?? '-'}
          />
          <RateIconListItem
            icon={OfficeIcon}
            label={t('forms:labels.workType')}
            value={workMode ? t(`dictionaries:workTypes.${workMode}`) ?? '-' : '-'}
          />
          <RateIconListItem
            icon={LevelsIcon}
            label={t('forms:labels.seniority')}
            value={seniority ?? '-'}
          />
          <RateIconListItem
            icon={LanguageIcon}
            label={t('forms:labels.foreignLanguage')}
            value={`${t('forms:labels.english')} ${determineRequiredLanguageLevel(
              minEnglishLevel,
            )}`}
          />
        </ul>
      </div>
      <div className="bg-gray-100 border-t border-t-gray-200 md:pt-6 p-4 md:pb-4 md:px-8 flex flex-col items-center gap-4">
        <div className="flex w-full space-x-2">
          {onFavouriteClick && (
            <SmallButton
              className="!bg-transparent !p-0 text-[14px] md:text-[16px] leading-[19px] md:leading-[22px] font-normal items-center border-0 !h-auto"
              icon={isSaved ? HeartSolidIcon : HeartIcon}
              iconClassName="text-primary-500 mr-0.5"
              isDisabled={favouriteJobOrderLoading}
              isLoading={favouriteJobOrderLoading}
              label={
                isSaved
                  ? t('offers:offerListItem.unsaveOffer')
                  : t('offers:offerListItem.saveOffer')
              }
              labelClassName="!text-[14px] md:!text-[16px] !leading-[19px] md:!leading-[22px] text-primary-500 gap-1 whitespace-nowrap"
              onClick={() => {
                handleFavouriteOffer(jobOrderId);
              }}
            />
          )}
          <SmallButton
            className="whitespace-nowrap text-primary-500 !gap-1 text-[14px] md:text-[16px] leading-[19px] md:leading-[22px] font-normal !h-auto"
            icon={isHidden ? EyeIcon : CrossIcon}
            iconClassName="text-primary-500 w-4 min-w-4 !text-[14px] md:!text-[16px] !leading-[19px] md:!leading-[22px] mr-0"
            isDisabled={hideJobOrderLoading}
            isLoading={hideJobOrderLoading}
            label={
              isHidden ? t('offers:offerListItem.showOffer') : t('offers:offerListItem.hideOffer')
            }
            labelClassName="!text-[14px] md:!text-[16px] !leading-[19px] md:!leading-[22px] gap-1"
            onClick={() => handleHideOffer(jobOrderId)}
          />
        </div>
        {isApplyButtonDisabled && (
          <InfoBox className="mb-2" text={t('offers:offerListItem.notEnoughScore')} />
        )}
        {showScheduleMeetingButton && (
          <Button
            className="w-full text-sm md:text-base"
            label={t('offers:offerListItem.scheduleMeeting')}
            onClick={() => onScheduleMeetingClick && onScheduleMeetingClick(jobOrderId)}
          />
        )}
      </div>
    </div>
  ) : null;
};
