import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFileUpload, useSitemap } from 'hooks';
import { JobOrdersQuerySortByEnum } from 'interfaces';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { profileVar } from 'reactive-vars';
import { useDebouncedCallback } from 'use-debounce';
import { getOnlyDirtyValues } from 'utils';
import { ActiveApplicationJobOrdersDocument } from '../../hooks/api/activeApplicationJobOrders/activeApplicationJobOrders.generated';
import { EndedApplicationJobOrdersDocument } from '../../hooks/api/endedApplicationJobOrders/endedApplicationJobOrders.generated';
import { HiddenJobOrdersDocument } from '../../hooks/api/hiddenJobOrders/hiddenJobOrders.generated';
import { JobOrdersUnderMinScoreToApplyDocument } from '../../hooks/api/jobOrdersUnderMinScoreToApply/jobOrdersUnderMinScoreToApply.generated';
import { MyProfileQuery } from '../../hooks/api/myProfile/myProfile.generated';
import { PublicOfferAppliedJobOrdersDocument } from '../../hooks/api/publicOfferAppliedJobOrders/publicOfferAppliedJobOrders.generated';
import { RecommendedJobOrdersDocument } from '../../hooks/api/recommendedJobOrders/recommendedJobOrders.generated';
import { SavedJobOrdersDocument } from '../../hooks/api/savedJobOrders/savedJobOrders.generated';
import { useUpdateExpertProfileMutation } from '../../hooks/api/updateExpertProfile/updateExpertProfile.generated';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { TabsHeader } from '../Tabs';
import { ProfileValues } from './interfaces';
import { ProfileChubId, ProfileFormsContainer } from './subcomponents';
import { mapProfileToValues } from './utils';
import { profileValidationSchema } from './validationSchemas';

export enum ProfileTabEnum {
  MyData = 'myData',
  Preferences = 'preferences',
  Experience = 'experience',
}

export type ExpertProfile = MyProfileQuery['myProfile'];

export const Profile = () => {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(ProfileTabEnum.MyData);
  const formMethods = useForm<ProfileValues>({
    resolver: yupResolver(profileValidationSchema(t)),
    shouldFocusError: false,
  });
  const {
    formState: { dirtyFields },
  } = formMethods;
  const { tabName } = useParams();
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const { uploadExpertCv } = useFileUpload();

  const profile = useReactiveVar(profileVar);
  const [updateExpertProfile] = useUpdateExpertProfileMutation({
    refetchQueries: [
      {
        query: RecommendedJobOrdersDocument,
      },
      {
        query: JobOrdersUnderMinScoreToApplyDocument,
        variables: {
          params: {
            sortBy: JobOrdersQuerySortByEnum.BestMatch,
          },
        },
      },
      {
        query: PublicOfferAppliedJobOrdersDocument,
      },
      {
        query: ActiveApplicationJobOrdersDocument,
      },
      {
        query: EndedApplicationJobOrdersDocument,
      },
      {
        query: SavedJobOrdersDocument,
      },
      {
        query: HiddenJobOrdersDocument,
      },
    ],
  });

  const handleProfileUpdate = async (values: ProfileValues) => {
    const { expertId } = profile ?? {};
    const { expertCv, ...updateValues } = values;
    if (!expertId) return;

    const isValid = formMethods.trigger();
    if (!isValid) return;
    const onlyDirtyValues = getOnlyDirtyValues(
      updateValues,
      dirtyFields as Record<string, boolean>,
    );

    let cvId;
    if (dirtyFields.expertCv) {
      if (!values.expertCv) {
        cvId = null;
      }
      if (values.expertCv) {
        try {
          cvId = await uploadExpertCv(expertId, values.expertCv);
        } catch (error) {
          console.error('Error uploading CV:', error);
        }
      }
    }

    if (!Object.keys(onlyDirtyValues).length && !dirtyFields.expertCv) return;
    const res = await updateExpertProfile({
      variables: {
        expertId,
        params: {
          ...onlyDirtyValues,
          ...(typeof cvId !== 'undefined' && { cvId }),
        },
      },
    });
    const { updateExpertProfile: updatedProfile } = res.data ?? {};
    profileVar(updatedProfile);
    formMethods.reset(
      {},
      {
        keepValues: true,
      },
    );
  };
  const debouncedExpertProfileUpdate = useDebouncedCallback(handleProfileUpdate, 500);

  const handleTabChange = (newTab: ProfileTabEnum) => {
    setCurrentTab(newTab);
    navigate(sitemap.profile(newTab === ProfileTabEnum.MyData ? '' : newTab));
  };

  const tabOptions = Object.values(ProfileTabEnum).map((name) => ({
    label: t(`profile:profileHeader.tabs.${name}`),
    value: name,
  }));

  useEffectOnce(() => {
    if (!profile) return;
    formMethods.reset(mapProfileToValues(profile));
  }, !!profile);

  useEffect(() => {
    const { handleSubmit, watch } = formMethods;
    const subscription = watch(() => handleSubmit(debouncedExpertProfileUpdate)());
    return () => subscription.unsubscribe();
  }, [formMethods]);

  useEffect(() => {
    if (tabName && currentTab !== tabName) {
      setCurrentTab(tabName as ProfileTabEnum);
    }
  }, [tabName]);

  return (
    <div className="relative w-screen md:w-auto pb-10">
      <div className="absolute md:w-[calc(100%-80px)] -top-20 xl:-top-12 left-10 mt-0 hidden lg:block">
        <TabsHeader onChange={handleTabChange} options={tabOptions} value={currentTab} />
      </div>
      <div className="grid gap-y-4 lg:gap-x-8 lg:grid-cols-[minmax(60%,_1fr)_minmax(min-content,_384px)] lg:grid-rows-none lg:mx-10 mt-4 xl:-mt-12 items-start md:justify-center">
        <FormProvider {...formMethods}>
          <ProfileFormsContainer currentTab={currentTab} />
        </FormProvider>
        <ProfileChubId className="hidden lg:block" />
      </div>
    </div>
  );
};
