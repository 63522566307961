import { IconButton } from 'components/Buttons';
import { useElementSize } from 'hooks';
import { CrossIcon, LevelsIcon, StarIcon, StarSolidIcon, UserIcon } from 'icons';
import { useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { Animation } from '../Animation';
import { Detail } from '../JobOrderList/subcomponents/JobOrderListItem/JobOrderListItem';
import { SkillDots } from './subcomponents/SkillDots';

export type DefaultSkillLevelType = null | 1 | 2 | 3 | 4 | 5;

export type SkillVariant = 'primary' | 'white';

interface SkillItemProps<TSkillLevel> {
  id: string;
  name: string;
  level: TSkillLevel;
  dotsNumber?: number;
  isSuperPower?: boolean;
  error?: FieldError;
  onRemove?: (tagId: string) => void;
  onLevelChange?: (tagId: string, level: TSkillLevel) => void;
  onClick?: (tagId: string) => void;
  isReadOnly?: boolean;
  levelDiscName: string;
  className?: string;
  superPowerStarPosition?: 'top' | 'bottom';
  isSuperPowerStarAlwaysVisible?: boolean;
  onSuperPowerStarClick?: () => void;
  variant?: SkillVariant;
  noLevelSkill?: boolean;
  isMatchingMustHaveSkill?: boolean;
  isMatchingNiceToHaveSkill?: boolean;
  areTechnicalDetailsVisible?: boolean;
  expertLevel?: TSkillLevel;
  expertLevelLabel?: string;
  hideDeleteButton?: boolean;
}

export function Skill<TSkillLevel = DefaultSkillLevelType>({
  id,
  name,
  level,
  dotsNumber,
  isSuperPower,
  onLevelChange,
  onRemove,
  onClick,
  isReadOnly = false,
  error,
  levelDiscName,
  className = '',
  superPowerStarPosition = 'top',
  isSuperPowerStarAlwaysVisible,
  onSuperPowerStarClick,
  variant = 'white',
  noLevelSkill,
  isMatchingMustHaveSkill,
  isMatchingNiceToHaveSkill,
  areTechnicalDetailsVisible,
  expertLevel,
  expertLevelLabel,
  hideDeleteButton = false,
}: SkillItemProps<TSkillLevel>) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [, wrapperHeight] = useElementSize(wrapperRef);
  const [hoveredItemIndex, setHoveredItemIndex] = useState<TSkillLevel>(null as TSkillLevel);
  const levelLabel =
    hoveredItemIndex || level
      ? t(`dictionaries:${levelDiscName}.${hoveredItemIndex || level}`)
      : '';

  const isPrimary = variant === 'primary';

  const determineComparedTechSkillClass = () => {
    if (!expertLevel && areTechnicalDetailsVisible && !isMatchingNiceToHaveSkill) {
      return '!bg-gray-150/30 text-gray-250';
    }
  };

  const showExpertSkills =
    !noLevelSkill &&
    areTechnicalDetailsVisible &&
    isMatchingMustHaveSkill &&
    expertLevelLabel &&
    expertLevel;

  const noExpertSkillToCompare =
    !noLevelSkill && areTechnicalDetailsVisible && !expertLevel && !expertLevelLabel;

  const generateClassName = () => {
    const classes = [
      'rounded-lg relative p-4 !transition-all !duration-300 !ease-in-out',
      isSuperPower ? 'border-primary-500 shadow-primary-md' : 'border-gray-200',
      error ? '!border-red-500' : '',
      !level ? 'opacity-75 hover:opacity-100' : '',
      onClick ? 'cursor-pointer' : '',
      isPrimary ? 'bg-primary-500/10' : 'bg-white',
      determineComparedTechSkillClass(),
    ];

    return classes.join(' ');
  };

  const isMatchingSkill = expertLevel && expertLevel >= level;

  return (
    <AnimateHeight duration={300} height={areTechnicalDetailsVisible ? wrapperHeight : 'auto'}>
      <div
        ref={wrapperRef}
        className={mergeClasses(
          'border transition-all duration-300 ease-in-out',
          generateClassName(),
          className,
          isMatchingSkill && areTechnicalDetailsVisible ? '!border-primary-500' : '',
          isPrimary ? '' : 'border',
          determineComparedTechSkillClass(),
        )}
        onClick={() => onClick && onClick(id)}
      >
        <p
          className={mergeClasses(
            'text-[17px] font-semibold leading-[19px] max-w-[94%]',
            noLevelSkill ? 'pb-[19px]' : 'pb-3',
            noExpertSkillToCompare && 'text-gray-250',
            noLevelSkill && noExpertSkillToCompare && '!text-gray-250',
          )}
        >
          {name}
        </p>

        {!noLevelSkill && (
          <SkillDots<TSkillLevel>
            activeDotClassName={noExpertSkillToCompare ? '!bg-gray-300' : 'bg-primary-500'}
            dotClassName={noExpertSkillToCompare ? '!bg-gray-150' : ''}
            dotsNumber={dotsNumber}
            hoveredItemIndex={hoveredItemIndex}
            isReadOnly={isReadOnly}
            level={level}
            onDotClick={onLevelChange ? (newLevel) => onLevelChange(id, newLevel) : undefined}
            setHoveredItemIndex={setHoveredItemIndex}
            variant={variant}
          />
        )}

        {(isSuperPower || isSuperPowerStarAlwaysVisible) && (
          <div
            className={mergeClasses(
              'w-4 h-6 flex justify-center items-center rounded-lg absolute border',
              superPowerStarPosition === 'bottom' ? 'bottom-4 right-3.5' : 'top-2 right-2',
              isSuperPower ? 'bg-primary-500 border-primary-500' : 'border-gray-300',
              onSuperPowerStarClick ? 'cursor-pointer' : '',
            )}
            onClick={onSuperPowerStarClick}
          >
            {isSuperPower ? (
              <StarSolidIcon className="text-white w-3 h-3" />
            ) : (
              <StarIcon className="text-gray-300 w-3 h-3 " />
            )}
          </div>
        )}
        {onRemove && !hideDeleteButton && (
          <IconButton
            ariaLabel={t('aria:delete')}
            className="absolute top-2 right-2.5 text-gray-400 p-0"
            icon={CrossIcon}
            iconClassName="w-6 h-6"
            onClick={(e) => {
              e.stopPropagation();
              onRemove(id);
            }}
            size="sm"
          />
        )}

        {!noLevelSkill && (
          <Detail
            className={mergeClasses(
              'text-[14px] font-normal text-gray-500',
              noExpertSkillToCompare ? 'text-gray-250' : 'text-gray-500',
            )}
            icon={LevelsIcon}
            iconClassName={mergeClasses(
              '!w-4 !h-4 min-w-[16px] text-gray-400 mt-0.5',
              noExpertSkillToCompare ? 'text-gray-250' : 'text-gray-500',
            )}
            value={levelLabel}
          />
        )}

        <Animation animationName="slide-down" isShowed={!!areTechnicalDetailsVisible}>
          {/* Expert's skill level */}
          {showExpertSkills && (
            <div className="pt-3">
              <SkillDots<TSkillLevel>
                dotsNumber={dotsNumber}
                hoveredItemIndex={hoveredItemIndex}
                isReadOnly={isReadOnly}
                level={expertLevel}
                onDotClick={onLevelChange ? (newLevel) => onLevelChange(id, newLevel) : undefined}
                setHoveredItemIndex={setHoveredItemIndex}
                variant={variant}
              />
              <Detail
                className="text-[14px] font-normal text-gray-500"
                icon={UserIcon}
                iconClassName="!w-4 !h-4 mt-0.5 text-gray-400"
                value={expertLevelLabel}
              />
            </div>
          )}

          {/* No expert skill to compare */}
          {noExpertSkillToCompare && (
            <div className="pt-3">
              <SkillDots<TSkillLevel>
                dotClassName={areTechnicalDetailsVisible ? '!bg-gray-150' : ''}
                dotsNumber={dotsNumber}
                hoveredItemIndex={hoveredItemIndex}
                isReadOnly={isReadOnly}
                onDotClick={onLevelChange ? (newLevel) => onLevelChange(id, newLevel) : undefined}
                setHoveredItemIndex={setHoveredItemIndex}
                variant={variant}
              />
              <Detail
                className="text-[14px] font-normal lowercase text-gray-250"
                icon={UserIcon}
                iconClassName="!w-4 !h-4 mt-0.5 text-gray-250"
                value={t('dictionaries:skillLevels.0')}
              />
            </div>
          )}
        </Animation>

        {error && <div className="text-xs text-red-500">{error.message}</div>}
      </div>
    </AnimateHeight>
  );
}
