import { InputError, InputLabel } from 'components';
import { OfferIcon, UploadIcon } from 'icons';
import { FC, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses, parseFileSize, trimString } from 'utils';

export interface InitialFileInfo {
  name: string;
  size: number;
  url: string;
}

const EmptyStateLabel = () => {
  const { t } = useTranslation();
  return (
    <>
      <UploadIcon className="w-6 h-6 text-gray-400" />
      <p className="text-gray-500 text-sm lg:text-base">
        {t('offers:externalOffer.dragAndDropFile')}
      </p>
      <p className="text-primary-500 font-medium text-sm lg:text-base">
        {t('offers:externalOffer.chooseFile')}{' '}
      </p>
    </>
  );
};

const FilledStateLabel: FC<{
  file: File | InitialFileInfo | null;
  onRemove: () => void;
  fileNameMaxLength: number;
}> = ({ file, onRemove, fileNameMaxLength }) => {
  const { t } = useTranslation();
  if (!file) {
    return <div />;
  }
  const { name: fileName, size: fileSize } = file;
  const fileUrl = 'url' in file ? file.url : undefined;

  const handleFileNameClick = () => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <>
      <div className="flex items-center gap-1">
        <OfferIcon className="w-6 h-6 text-gray-400" />
        <p
          className="text-sm lg:text-base font-semibold"
          onClick={handleFileNameClick}
          style={{ cursor: fileUrl ? 'pointer' : 'default' }}
        >
          {trimString(fileName, fileNameMaxLength, true)}
        </p>
      </div>
      <p>{parseFileSize(fileSize)}</p>
      <button
        className="text-red-500 hover:text-red-700 transition-all font-medium text-sm lg:text-base"
        onClick={onRemove}
        type="button"
      >
        {t('common:actions.remove')}
      </button>
    </>
  );
};

interface FileInputProps {
  name: string;
  onChange: (file: File | null) => void;
  label?: string | null;
  error?: FieldError;
  accept?: string[];
  disabled?: boolean;
  helperText?: string;
  fileNameMaxLength?: number;
  initialFile?: InitialFileInfo | null;
  onFileRejection?: (rejectedFiles: FileRejection[]) => void;
}

export const FileInput: FC<FileInputProps> = ({
  name,
  onChange,
  label,
  error,
  accept,
  disabled,
  helperText,
  fileNameMaxLength = 100,
  initialFile,
  onFileRejection,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [initialFileData, setInitialFileData] = useState<InitialFileInfo | null>(
    initialFile ?? null,
  );
  const isFilled = !!selectedFile || !!initialFileData;

  const handleChange = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length && onFileRejection) {
      onFileRejection(rejectedFiles);
      return;
    }

    const [file] = acceptedFiles;

    if (!file) {
      return;
    }

    setSelectedFile(file);
    setInitialFileData(null);
    onChange(file);
  };

  const handleRemove = () => {
    onChange(null);
    setInitialFileData(null);
    setSelectedFile(null);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    disabled: disabled || isFilled,
    accept:
      accept &&
      accept.reduce<Accept>((acc, curr) => {
        acc[curr] = [];
        return acc;
      }, {}),
    onDrop: handleChange,
  });
  const isError = isDragReject || !!error;

  return (
    <div>
      {label && <InputLabel label={label} />}
      <div
        {...getRootProps()}
        aria-hidden="true"
        className={mergeClasses(
          'h-[156px] border border-gray-200 border-dashed rounded-lg flex flex-col gap-2 items-center justify-center transition-all',
          isDragActive ? 'bg-gray-100' : '',
          !isFilled && !disabled ? 'hover:bg-gray-100 cursor-pointer' : '',
          isError ? 'border-red-500' : '',
          disabled ? 'bg-gray-100 opacity-50 cursor-not-allowed' : '',
        )}
      >
        <input {...getInputProps()} id={name} name={name} />
        {isFilled ? (
          <FilledStateLabel
            file={selectedFile || initialFile || null}
            fileNameMaxLength={fileNameMaxLength}
            onRemove={handleRemove}
          />
        ) : (
          <EmptyStateLabel />
        )}
      </div>
      {helperText && <p className="text-sm mt-1 -mb-1">{helperText}</p>}
      <InputError error={error} />
    </div>
  );
};
