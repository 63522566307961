import { useGoogleAutocompleteService } from 'hooks';
import { GoogleLocationObject } from 'interfaces';
import { FC, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { SearchInput } from '../SearchInput';

interface LocationInputProps {
  name: string;
  label?: string;
  value?: string;
  onChange: (location: GoogleLocationObject) => void;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  renderValue?: boolean;
}

const DEFAULT_SEARCH_VALUE = 'a';
export const LocationInput: FC<LocationInputProps> = ({
  name,
  onChange,
  className = '',
  error,
  label,
  value,
  placeholder,
  renderValue = true,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<GoogleLocationObject | null>(null);

  const { places, searchPlace, isLoaded } = useGoogleAutocompleteService();

  const placeOptions = (places || []).map((place: GoogleLocationObject) => ({
    value: place,
    label: place.description,
  }));

  const handleChange = (place: GoogleLocationObject) => {
    setSelectedLocation(place);
    onChange(place);
  };

  const selectedOption =
    selectedLocation || value
      ? {
          value: selectedLocation?.place_id ?? '',
          label: selectedLocation?.description ?? value ?? '',
        }
      : undefined;

  useEffect(() => {
    if (places.length) return;
    searchPlace(value || DEFAULT_SEARCH_VALUE);
  }, [isLoaded]);

  return (
    <SearchInput<any>
      className={className}
      error={error}
      label={label}
      name={name}
      onChange={handleChange}
      onInputChange={searchPlace}
      options={placeOptions}
      placeholder={placeholder}
      value={renderValue ? selectedOption : undefined}
    />
  );
};

LocationInput.displayName = 'LocationInput';
