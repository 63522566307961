import { useSitemap } from 'hooks';
import { useNavigate } from 'react-router-dom';
import ConnectisLogo from '../../../assets/images/connectis-logo.svg';

interface ExternalOfferHeaderProps {
  role?: string | null;
  leadingTechnologyName?: string | null;
}

export const ExternalOfferHeader = ({ role, leadingTechnologyName }: ExternalOfferHeaderProps) => {
  const sitemap = useSitemap();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4 lg:gap-6 pb-6 lg:pb-10">
      <p className="leading-[19px] text-[20px] pt-11 lg:pt-0 lg:text-4xl font-bold">
        {role} {leadingTechnologyName}
      </p>
      <img
        alt="logo"
        className="w-[93px] lg:w-[124px] cursor-pointer"
        onClick={() => navigate(sitemap.main)}
        src={ConnectisLogo}
      />
    </div>
  );
};
