import { JsonObject } from 'type-fest';

export const forms: JsonObject = {
  labels: {
    yourEmail: 'Twój e-mail',
    password: 'Hasło',
    verificationCode: 'Kod weryfikacyjny',
    changePassword: 'Zmień hasło',
    changeOfPassword: 'Zmiana hasła',
    currentLoginMethod: 'Aktualny sposób logowania',
    OTPLoginMethod: 'Jednorazowy kod wysyłany na emaila',
    passwordLoginMethod: 'Logowanie za pomocą hasła',
    setUpPassword: 'Utwórz hasło',
    newPassword: 'Nowe hasło',
    confirmPassword: 'Potwierdź hasło',
    currentPassword: 'Aktualne hasło',
    code: 'Kod',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    fullName: 'Imię i nazwisko',
    email: 'Email',
    role: 'Rola',
    technologies: 'Technologie',
    languages: 'Języki',
    foreignLanguage: 'Język obcy',
    location: 'Lokalizacja',
    workType: 'Tryb pracy',
    rateDesired: 'Stawka',
    availability: 'Dostępność',
    yourRole: 'Twoja rola',
    company: 'Firma',
    dateStart: 'Data rozpoczęcia',
    dateEnd: 'Data zakończenia',
    usedTechnologies: 'Wykorzystywane technologie/narzędzia',
    certificateName: 'Nazwa certyfikatu',
    issuingOrganization: 'Organizacja wystawiająca',
    obtainedDate: 'Data uzyskania',
    url: 'URL',
    link: 'Link',
    credentialNumber: 'Numer poświadczenia',
    credentialUrl: 'URL poświadczenia',
    description: 'Opis',
    industry: 'Branża',
    mainTechnologies: 'Technologie wiodące',
    experienceYears: 'Lata doświadczenia',
    seniority: 'Poziom doświadczenia',
    experience: 'Doświadczenie',
    employer: 'Pracodawca',
    level: 'Poziom',
    language: 'Język',
    niceToHave: 'Pozostałe',
    required: 'Wymagane',
    preferred: 'Mile widziane',
    offer: 'Oferta',
    mainTechnology: 'Technologia wiodąca',
    english: 'Angielski',
    yourPhoneNumber: 'Twój numer telefonu',
    linkedInProfile: 'Profil LinkedIn',
    githubProfile: 'Profil Github',
    handler: 'Opiekun',
    matchindDetails: 'Szczegóły dopasowania',
    requiredSkills: 'Wymagane umiejętności',
    preferredSkills: 'Mile widziane umiejętności',
    yourAvailability: 'Twoja dostępność',
    matchingCategoryDetails: 'Kategoria {{fieldName}} odpowiada {{percent}}% ogólnego dopasowania',
    matchingCategoryTooltipHeader:
      'Kategoria <strong>{{fieldName}}</strong> to {{percent}}% z Twojego dopasowania do oferty.',
    matchingCategoryDetailsSubtitle: 'Twoje dopasowanie umiejętności do wymagań oferty:',
    skills: 'Umiejętności',
    showCommonSkills: 'Pokaż wspólne umiejętności',
    hideCommonSkills: 'Ukryj wspólne umiejętności',
    cv: 'CV',
    details: 'Szczegóły',
  },
  placeholders: {
    select: 'Wybierz...',
    selectDate: 'Wybierz datę',
    locationInput: 'Wybierz miejscowość',
    enterField: 'Podaj {{fieldName}}',
    addField: 'Dodaj {{fieldName}}...',
    sort: 'Sortuj',
    expand: 'Rozwiń',
    fold: 'Zwiń',
  },
  validationMessages: {
    wrongEmail: 'Adres email jest nieprawidłowy',
    wrongEmailOrPassword: 'Nieprawidłowy email lub hasło',
    wrongPassword: 'Nieprawidłowe hasło',
    newPasswordMustBeUnique: 'Nowe hasło nie może być takie samo jak aktualne',
    requiredThisField: 'To pole jest wymagane',
    required: 'Pole {{fieldName}} jest wymagane',
    atLeast: 'Przynajmniej {{min}} {{fieldName}} są wymagane',
    passwordsNotSame: 'Hasła muszą być takie same',
    wrongCode: 'Kod wygasł lub jest nieprawidłowy',
    skillLevelMissing: 'Wszystkie technologie muszą mieć określony poziom',
    EnglishSkillMissing: 'Język angielski musi mieć określony poziom',
    atMax: 'Maksymalnie {{max}} znaków jest dozwolone',
    wrongFormat: 'Nie można używać liczb oraz znaków specjalnych',
    maxSalary: 'Maksymalna stawka wynosi {{max}} pln/h',
    negativeAvailability: 'Podaj wartość dodatnią',
    noAvailability: 'Podaj wartość numeryczną',
    urlNotValid: 'Niepoprawny adres URL',
    maxDate: 'Data nie może być w przyszłości',
    fieldDoesntMeetRequirements: 'Pole {{fieldName}} nie spełnia wymagań',
    phoneNumberNotValid: 'Numer telefonu jest niepoprawny',
    noNegativeValues: '{{fieldName}} nie może mieć wartości ujemnej',
    maxExperience: '{{fieldName}} może być maksymalnie dwucyfrowe',
    setPasswordConfirmation: 'Czy na pewno chcesz zmienić sposób logowania?',
    setPasswordExplanation:
      'Po zapisaniu zmian logowanie na Twoje konto będzie wymagało podania utworzonego hasła. Czy na pewno chcesz zapisać zmiany?',
    documentRequired: 'Dodanie pliku jest wymagane',
    fileTooBig: 'Plik jest za duży',
    fileIsEmpty: 'Plik jest pusty',
    invalidFileType: 'Niedozwolony typ pliku',
  },
  matchingValuation: {
    matchingHeader: 'Twój profil pasuje do tej oferty w {{value}}%',
    matchingSubheader: {
      fulfilled: 'Wygląda na to, że mamy dla Ciebie interesującą ofertę. Co powiesz na spotkanie?',
      incomplete: 'Sprawdź, czy masz uzupełnione wszystkie dane w swoim profilu.',
      insufficient:
        'Projekt nie jest dopasowany do Ciebie. Nic straconego, zerknij na kolejne oferty.',
    },
    matchingExplanation: 'Wynik dopasowania do oferty to składowe poniższych kategorii.',
    matchingExtraExplanation: 'W każdej z nich można zdobyć maksymalnie 100%.',
    requiredSkills: 'Wynik to poziom dopasowania Twoich umiejętności z wymaganymi w projekcie.',
    preferredSkills: {
      fulfilled: 'Twoja wiedza w zakresie mile widzianych technologii robi na nas wrażenie.',
      incomplete:
        'Twoja znajomość mile widzianych technologii częściowo spełnia wymagania naszego projektu.',
      insufficient:
        'Niestety, Twoja znajomość mile widzianych technologii nie spełnia oczekiwań tego projektu.',
    },
    otherSkills: {
      required: 'Wymagany: ',
      proposed: 'Proponowana: ',
      preferred: 'Twoje preferencje: ',
    },
  },
};
