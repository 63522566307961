const SITEMAP = Object.freeze({
  main: '/',
  signIn: '/',
  signUp: '/sign-up',
  passwordReset: '/password-reset',
  expertOnboarding: (stepName?: string) => {
    return `/onboarding${stepName ? `/${stepName}` : ''}`;
  },
  setNewPassword: '/new-password',
  offers: (tabName?: string) => `/offers${tabName ? `/${tabName}` : ''}`,
  myApplications: (tabName?: string) => `/applications${tabName ? `/${tabName}` : ''}`,
  application: (jobOrderId: string) => `/applications?jobOrderId=${jobOrderId}`,
  offer: (jobOrderId: string, offerId?: string) =>
    `/offers?jobOrderId=${jobOrderId}${offerId ? `&offerId=${offerId}` : ''}`,
  profile: (tabName?: string) => `/profile${tabName ? `/${tabName}` : ''}`,
  settings: (tabName?: string) => `/settings${tabName ? `/${tabName}` : ''}`,
  homepageAfterLogin: '/offers',
});

export const useSitemap = () => SITEMAP;
