import { t } from 'i18next';
import { LanguageIcon, LevelsIcon, LocationIcon, OfficeIcon } from 'icons';
import { LanguageLevelEnum, SeniorityLevelEnum, WorkModeEnum } from 'interfaces';
import { mergeClasses } from 'utils';
import { determineRequiredLanguageLevel } from '../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsHeader';
import { RateIconListItem } from '../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsRate';

interface ExternalOfferRateProps {
  rateFrom: number;
  rateTo: number;
  locationPreference?: string | null;
  workMode?: WorkModeEnum | null;
  seniority?: SeniorityLevelEnum | null;
  minEnglishLevel?: LanguageLevelEnum | null;
  className?: string;
  isRateDisplayed: boolean;
}

export const ExternalOfferRate = ({
  rateFrom,
  rateTo,
  locationPreference,
  workMode,
  seniority,
  minEnglishLevel,
  className,
  isRateDisplayed,
}: ExternalOfferRateProps) => {
  const roundedRateRangeFrom = rateFrom ? Math.round(rateFrom) : 0;
  const roundedRateRangeTo = rateTo ? Math.round(rateTo) : 0;

  return (
    <div
      className={mergeClasses(
        'p-4 md:p-8 lg:pb-2 border border-gray-200 rounded-xl w-full',
        className,
      )}
    >
      <p className="text-base text-[14px] md:text-lg font-bold mb-1 lg:mb-4">
        {isRateDisplayed ? t('forms:labels.rateDesired') : t('forms:labels.details')}
      </p>
      {isRateDisplayed && (
        <p className="md:mb-2 md:border-b md:border-b-gray-200">
          <span className="text-[24px] lg:text-[40px] leading-[56px] font-semibold mr-1">{`${roundedRateRangeFrom}-${roundedRateRangeTo} PLN`}</span>
          <span className="text-gray-400 text-[16px] lg:text-[20px] leading-[48px]">
            /{t('common:hourShort')}
          </span>
          <span className="text-gray-400 text-[16px] lg:text-[20px] leading-[48px]"> - B2B</span>
        </p>
      )}
      <ul>
        <RateIconListItem
          className="whitespace-nowrap text-ellipsis overflow-hidden mr-4 lg:mr-0 max-w-[350px]"
          icon={LocationIcon}
          label={t('forms:labels.location')}
          value={locationPreference ?? '-'}
        />
        <RateIconListItem
          icon={OfficeIcon}
          label={t('forms:labels.workType')}
          value={workMode ? t(`dictionaries:workTypes.${workMode}`) ?? '-' : '-'}
        />
        <RateIconListItem
          icon={LevelsIcon}
          label={t('forms:labels.seniority')}
          value={seniority ?? '-'}
        />
        <RateIconListItem
          icon={LanguageIcon}
          label={t('forms:labels.foreignLanguage')}
          value={
            minEnglishLevel
              ? `${t('forms:labels.english')} ${determineRequiredLanguageLevel(minEnglishLevel)}`
              : '-'
          }
        />
      </ul>
    </div>
  );
};
