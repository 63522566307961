import { Button, Collapse, IconButton } from 'components';
import { useScrollLock } from 'hooks';
import { t } from 'i18next';
import { CrossIcon } from 'icons';
import { ExpertNotificationType } from 'interfaces';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';
import useWindowSize from '../../../hooks/useWindowSize';
import NotificationItem from './subcomponents/NotificationItem';

interface NotificationModuleProps {
  isOpen: boolean;
  onClose: () => void;
  notificationData?: ExpertNotificationType[];
}

const NotificationModule = ({ isOpen, onClose, notificationData }: NotificationModuleProps) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { unlockPageScroll, lockPageScroll } = useScrollLock();

  useEffect(() => {
    if (isOpen && (width ?? 0) < 1023) {
      return lockPageScroll();
    }
    unlockPageScroll();
  }, [isOpen, width]);

  return (
    <Collapse
      className="absolute left-0 lg:left-auto top-0 right-0 bottom-0 lg:bottom-auto lg:top-[72px] lg:right-0 z-20"
      isCollapsed={!isOpen}
    >
      <div
        className={mergeClasses(
          ' lg:w-[495px] lg:max-h-[711px] lg:h-full h-screen bg-background lg:rounded-2xl shadow-md-down py-6 pt-[60px] lg:pt-0 transition-all max-h-screen overflow-auto',
          isOpen ? 'bg-white' : 'delay-300',
        )}
      >
        <div>
          <div className="flex justify-between px-6">
            <p className="text-xl py-4 font-semibold">{t('pageTitles:notifications')}</p>
            <IconButton
              className="text-gray-400"
              icon={CrossIcon}
              iconClassName="h-8 w-8"
              onClick={onClose}
            />
          </div>
          {notificationData ? (
            <div>
              {notificationData?.map((notification) => {
                const hasViewDate = !!notification.viewedAt;
                const metadata = JSON.parse(notification.metadata || '{}');
                return (
                  <NotificationItem
                    key={notification.id}
                    date={notification.createdAt}
                    hasViewDate={hasViewDate}
                    jobOrderId={metadata.jobOrderId}
                    jobOrderName={metadata.jobOrderName}
                    matchScore={metadata.matchScore}
                    notificationId={notification.id}
                    relatedTo={notification.relatesTo}
                    type={notification.type}
                  />
                );
              })}
            </div>
          ) : (
            <div className="px-6 text-gray-400">
              <p>{t('notifications:noNotifications')}</p>
            </div>
          )}
          <div className="pt-[42px] mb-24 md:mb-0 px-6 lg:pr-6 flex justify-end w-full">
            <Button
              className="!w-full md:!w-auto"
              label="Pokaż wszystkie"
              onClick={() => navigate('/notifications')}
              size="md"
              type="button"
              variant="primary"
            />
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default NotificationModule;
