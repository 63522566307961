import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
import { JobOrderFragmentFragmentDoc } from '../fragments/jobOrder.fragment.generated';
const defaultOptions = {} as const;
export type CheckIfOfferExistsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CheckIfOfferExistsQuery = {
  __typename?: 'Query';
  checkIfOfferExists: {
    __typename?: 'JobOrderExistsResultType';
    status: string;
    content?: {
      __typename?: 'JobOrderWithScoreType';
      jobOrderId: string;
      customId: number;
      role?: string | null;
      clientName?: string | null;
      seniority?: Types.SeniorityLevelEnum | null;
      experienceYears?: number | null;
      description?: string | null;
      dateStart1?: string | null;
      dateStart2?: string | null;
      dateEnd?: string | null;
      locationPreference?: string | null;
      minEnglishLevel?: Types.LanguageLevelEnum | null;
      workMode?: Types.WorkModeEnum | null;
      rateRangeTo?: number | null;
      rateRangeFrom?: number | null;
      isEnglish?: boolean | null;
      maxAvailability?: Types.MaxAvailabilityEnum | null;
      createdAt: string;
      assignmentDate?: string | null;
      isHidden: boolean;
      isSaved: boolean;
      isChubVisible: boolean;
      leadingTechnology?: string | null;
      leadingTechnologyName?: string | null;
      skillsMustHave: Array<{
        __typename?: 'ExpertTagType';
        id: string;
        name: string;
        weight: number;
      }>;
      skillsNiceToHave: Array<{
        __typename?: 'ExpertTagType';
        id: string;
        name: string;
        weight: number;
      }>;
      owner?: { __typename?: 'UserType'; email: string; name: string } | null;
      assignees?: Array<{
        __typename?: 'JobOrderAssigneesParamsType';
        brm?: string | null;
        talentAdvocates?: Array<string> | null;
      }> | null;
      score: {
        __typename?: 'CalculateScoreResultType';
        finalScore: number;
        mustHaveSkillsScore: number;
        niceToHaveSkillsScore: number;
        salaryScore: number;
        workModeScore: number;
        englishLevelScore: number;
        availabilityScore: number;
      };
      application?: {
        __typename?: 'JobOrderApplicationWithScoreType';
        applicationId: string;
        createdAt: string;
        meetingDates: Array<string>;
        selectedMeetingDate?: string | null;
        status: Types.JobOrderApplicationStatusEnum;
        processStatus: Types.JobOrderApplicationProcessStatusEnum;
        meetingUrl?: string | null;
        handler?: { __typename?: 'UserType'; name: string; email: string } | null;
      } | null;
    } | null;
  };
};

export const CheckIfOfferExistsDocument = gql`
  query checkIfOfferExists($id: String!) {
    checkIfOfferExists(id: $id) {
      status
      content {
        ...JobOrderFragment
      }
    }
  }
  ${JobOrderFragmentFragmentDoc}
`;

/**
 * __useCheckIfOfferExistsQuery__
 *
 * To run a query within a React component, call `useCheckIfOfferExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfOfferExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfOfferExistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckIfOfferExistsQuery(
  baseOptions: Apollo.QueryHookOptions<CheckIfOfferExistsQuery, CheckIfOfferExistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckIfOfferExistsQuery, CheckIfOfferExistsQueryVariables>(
    CheckIfOfferExistsDocument,
    options,
  );
}
export function useCheckIfOfferExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckIfOfferExistsQuery,
    CheckIfOfferExistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckIfOfferExistsQuery, CheckIfOfferExistsQueryVariables>(
    CheckIfOfferExistsDocument,
    options,
  );
}
export type CheckIfOfferExistsQueryHookResult = ReturnType<typeof useCheckIfOfferExistsQuery>;
export type CheckIfOfferExistsLazyQueryHookResult = ReturnType<
  typeof useCheckIfOfferExistsLazyQuery
>;
export type CheckIfOfferExistsQueryResult = Apollo.QueryResult<
  CheckIfOfferExistsQuery,
  CheckIfOfferExistsQueryVariables
>;
export function refetchCheckIfOfferExistsQuery(variables: CheckIfOfferExistsQueryVariables) {
  return { query: CheckIfOfferExistsDocument, variables: variables };
}
