import { useSitemap, useUrlTabs } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../Inputs';
import { ProfileChubId } from '../Profile/subcomponents';
import { TabsHeader } from '../Tabs';
import { AccountTab } from './tabs';

export enum SettingsTabEnum {
  account = 'account',
}

const renderTab = (attrs: {
  tab: SettingsTabEnum;
  options: { label: string; value: SettingsTabEnum }[];
  onChange: (newTab: SettingsTabEnum) => void;
  onMobileMenuToggle: () => void;
}) => {
  switch (attrs.tab) {
    case SettingsTabEnum.account:
      return <AccountTab />;
    default: {
      const unknownTab: SettingsTabEnum = attrs.tab;
      console.warn('Unknown tab:', unknownTab);
    }
  }
};

interface SettingsProps {
  onMobileMenuToggle: () => void;
}

export const Settings: FC<SettingsProps> = ({ onMobileMenuToggle }) => {
  const { t } = useTranslation();
  const sitemap = useSitemap();

  const { handleTabChange, currentTab } = useUrlTabs<SettingsTabEnum>(
    SettingsTabEnum.account,
    sitemap.settings,
  );

  const tabOptions = Object.values(SettingsTabEnum).map((name) => ({
    label: t(`settings:tabs.${name}`),
    value: name,
  }));

  return (
    <div className="relative w-screen md:w-auto pb-[69px]">
      <Select
        className="block px-4 pt-4 lg:hidden"
        onChange={handleTabChange}
        options={tabOptions}
        placeholder={`${t('offers:tabs.all')}`}
        value={currentTab}
      />
      <div className="absolute hidden w-screen mt-0 lg:block md:w-auto -top-20 xl:-top-12 left-10">
        <TabsHeader onChange={handleTabChange} options={tabOptions} value={currentTab} />
      </div>
      <div className="grid gap-y-4 lg:gap-x-8 lg:grid-cols-[minmax(60%,_1fr)_minmax(min-content,_384px)] lg:grid-rows-none mx-4 lg:mx-10 mt-4 xl:-mt-16 items-start lg:justify-center">
        {renderTab({
          tab: currentTab,
          options: tabOptions,
          onChange: handleTabChange,
          onMobileMenuToggle,
        })}
        <ProfileChubId className="row-start-2 row-end-3 lg:row-start-1 lg:row-end-2 !w-auto" />
      </div>
    </div>
  );
};
