import { Paper, Select, TextField } from 'components';
import { SeniorityLevelEnum } from 'interfaces';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { findElementByKey, insertItemToArray, mapArrayIntoOptions } from 'utils';
import { useOnboardingDataQuery } from '../../../../../../hooks/api/onboardingData/onboardingData.generated';

interface BasicInfoValues {
  firstName: string;
  lastName: string;
  jobPositionId: string;
  experienceYears: string;
  mainTechnologyIds: string[];
  seniority: string;
}

export const BasicInfo = () => {
  const { t } = useTranslation();

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<BasicInfoValues>();

  const jobPositionIdValue = watch('jobPositionId');
  const seniorityValue = watch('seniority');
  const mainTechnologyIdsValue = watch('mainTechnologyIds');

  const { data } = useOnboardingDataQuery();
  const { jobPositions } = data ?? { jobPositions: [] };

  const { availableMainTechnologies } = useMemo(
    () =>
      findElementByKey(jobPositions, 'jobPositionId', jobPositionIdValue) ?? {
        availableMainTechnologies: [],
      },
    [jobPositions, jobPositionIdValue],
  );

  const handleMainTechnologiesChange = (tagId: string, index: number) => {
    const newValue = insertItemToArray(mainTechnologyIdsValue, index, tagId);
    setValue('mainTechnologyIds', newValue, { shouldDirty: true, shouldValidate: true });
  };

  const handleJobPositionIdChange = (value: string) => {
    setValue('jobPositionId', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('mainTechnologyIds', [], { shouldDirty: true, shouldValidate: true });
  };

  const jobPositionOptions = jobPositions.map(({ jobPositionId, name }) => ({
    value: jobPositionId,
    label: name,
  }));

  const seniorityOptions = Object.values(SeniorityLevelEnum).map((seniority) => ({
    label: seniority,
    value: seniority,
  }));

  return (
    <Paper
      className="!p-4 xl:!p-8 !mt-0"
      id="basicInfo"
      title={t('profile:papers.basicInfo.title') ?? ''}
      titleClassName="!mb-4 xl:!mb-6"
    >
      <div className="grid xl:grid-cols-2 gap-x-6 gap-y-4 xl:gap-y-10 items-end">
        <TextField
          {...register('firstName')}
          autoComplete="given-name"
          error={errors.firstName}
          label={t('forms:labels.firstName')}
          wrapperClassName="self-start"
        />
        <TextField
          {...register('lastName')}
          autoComplete="family-name"
          error={errors.lastName}
          label={t('forms:labels.lastName')}
          wrapperClassName="self-start"
        />
        <Select
          error={errors.jobPositionId}
          label={t('forms:labels.yourRole')}
          onChange={handleJobPositionIdChange}
          options={jobPositionOptions}
          value={jobPositionIdValue}
        />
        <div className="grid lg:grid-cols-2 gap-y-4 gap-x-4 items-end">
          <TextField
            {...register('experienceYears', {
              valueAsNumber: true,
            })}
            error={errors.experienceYears}
            inputAdornment={t('common:years')}
            label={t('forms:labels.experience')}
            type="number"
          />
          <Select
            className="self-start"
            label={t('forms:labels.seniority')}
            onChange={(value) =>
              setValue('seniority', value, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            options={seniorityOptions}
            value={seniorityValue}
          />
        </div>
        {availableMainTechnologies.map(({ name, mainTechnologies }, index) => {
          const mainTechOptions = mapArrayIntoOptions(
            mainTechnologies,
            ({ tag }) => tag.name,
            ({ tag }) => tag.id,
          );
          return (
            <Select
              key={`mainTechnologySelect_${name ?? ''}`}
              label={`${t('forms:labels.mainTechnology')}${name ? ` - ${name}` : ''}`}
              onChange={(value) => handleMainTechnologiesChange(value, index)}
              options={mainTechOptions}
              value={mainTechnologyIdsValue[index] ?? null}
            />
          );
        })}
      </div>
    </Paper>
  );
};
