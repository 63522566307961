import { JobOrderList, Paper } from 'components';
import { t } from 'i18next';
import { useHiddenJobOrdersQuery } from '../../../../hooks/api/hiddenJobOrders/hiddenJobOrders.generated';

export const HiddenOffersTab = () => {
  const { data, loading } = useHiddenJobOrdersQuery();
  const hiddenJobOrders = data?.hiddenJobOrders ?? [];

  return (
    <Paper
      className="lg:relative lg:-top-20 xl:-top-24"
      title={t('offers:tabs.hidden')}
      titleClassName="!mb-2"
    >
      <JobOrderList jobOrders={hiddenJobOrders} loading={loading} name="hiddenJobOrders" />
    </Paper>
  );
};
