import { useReactiveVar } from '@apollo/client';
import { useSitemap } from 'hooks';
import {
  ExternalOfferPage,
  OffersPage,
  OnboardingPage,
  ProfilePage,
  SettingsPage,
  SignInPage,
  SignUpPage,
} from 'pages';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { profileVar } from 'reactive-vars';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { isExpertIdentityVerifiedVar } from '../../reactive-vars/isExpertIdentityVerified';
import { EmailVerificationPage, PleaseVerifyEmailPage } from '../EmailVerification';
import { MyApplicationsPage } from '../MyApplications';
import NotificationsPage from '../Notifications/NotificationsPage';
import { ConditionalRedirectRoute } from './subcomponents';

const EmailNotVerifiedRoutes = () => (
  <Routes>
    <Route element={<EmailVerificationPage />} path="/email-verification/:token" />
    <Route element={<PleaseVerifyEmailPage />} path="*" />
  </Routes>
);

export const AuthenticatedRoutes = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const profile = useReactiveVar(profileVar);
  const sitemap = useSitemap();
  const featureFlags = useFeatureFlags();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const isExpertIdentityVerified = useReactiveVar(isExpertIdentityVerifiedVar);

  if (isExpertIdentityVerified && !profile) {
    return null;
  }

  return !isExpertIdentityVerified ? (
    <EmailNotVerifiedRoutes />
  ) : (
    <Routes>
      <Route
        element={
          <ConditionalRedirectRoute
            condition={() => (profile as any).isOnboardingFinished || false}
            keepPrevPath
            keepSearchParams
            redirectPath={sitemap.expertOnboarding()}
          />
        }
      >
        <Route element={<Navigate to={sitemap.offers()} />} path="/" />
        <Route path="/profile">
          <Route
            element={
              <ProfilePage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <ProfilePage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
        <Route path="/settings">
          <Route
            element={
              <SettingsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <SettingsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
        <Route path="/offers">
          <Route
            element={
              <OffersPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <OffersPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
        <Route path="/applications">
          <Route
            element={
              <MyApplicationsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <MyApplicationsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
      </Route>
      <Route path="/onboarding">
        <Route element={<OnboardingPage />} index />
        <Route element={<OnboardingPage />} path=":stepName" />
      </Route>
      {featureFlags.notifications && (
        <Route
          element={
            <NotificationsPage
              isMobileMenuOpen={isMobileMenuOpen}
              onMobileMenuToggle={handleMobileMenuToggle}
            />
          }
          path="/notifications"
        />
      )}
      <Route element={<ExternalOfferPage />} path="/offer">
        <Route element={<ExternalOfferPage />} index />
        <Route element={<ExternalOfferPage />} path=":offerId" />
      </Route>
      <Route element={<Navigate to={sitemap.offers()} />} path="*" />
    </Routes>
  );
};

export const DefaultRoutes = () => {
  const sitemap = useSitemap();
  const [params] = useSearchParams();
  const { pathname } = useLocation();

  if (pathname) {
    params.append('pageRedirect', pathname);
  }
  const paramsString = params.toString();

  return (
    <Routes>
      <Route element={<SignInPage />} index />
      <Route element={<SignUpPage />} path="/sign-up" />
      <Route element={<EmailVerificationPage />} path="/email-verification/:token" />
      <Route element={<ExternalOfferPage />} path="/offer">
        <Route element={<ExternalOfferPage />} index />
        <Route element={<ExternalOfferPage />} path=":offerId" />
      </Route>
      <Route
        element={<Navigate to={`${sitemap.main}${paramsString ? `?${paramsString}` : ''}`} />}
        path="*"
      />
    </Routes>
  );
};
