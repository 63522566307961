import { useReactiveVar } from '@apollo/client';
import { ExpertVerifiedInfoModal, PageLoadingSpinner } from 'components';
import { useAuth, useExpertProfile, useInterval } from 'hooks';
import { AppRouter } from 'pages';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { profileVar } from 'reactive-vars';
import { useThrottledCallback } from 'use-debounce';
import { useCheckExpertVerifyStatusLazyQuery } from '../../hooks/api/checkExpertVerifyStatus/checkExpertVerifyStatus.generated';
import { useIsExpertIdentityVerifiedLazyQuery } from '../../hooks/api/isExpertIdentityVerified/isExpertIdentityVerified.generated';
import { useMyProfileLazyQuery } from '../../hooks/api/myProfile/myProfile.generated';
import { isExpertIdentityVerifiedVar } from '../../reactive-vars/isExpertIdentityVerified';

export const AuthContainer = () => {
  const { verifyAuthentication, isAuthenticated, signOutFromCognito } = useAuth();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const [initialLoading, setInitialLoading] = useState(true);
  const [isExpertVerifyModalOpen, setIsExpertVerifyModalOpen] = useState(false);

  const { setProfileField, profile } = useExpertProfile();

  const [getMyProfile, { loading: profileLoading }] = useMyProfileLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [getIsExpertIdentityVerified] = useIsExpertIdentityVerifiedLazyQuery({
    fetchPolicy: 'no-cache',
    onError: () => signOutFromCognito(),
  });

  const [checkExpertVerifyStatus] = useCheckExpertVerifyStatusLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleCheckForVerifyStatusChange = async () => {
    const { data } = await checkExpertVerifyStatus();
    const { checkExpertVerifyStatus: isVerified } = data ?? {};
    if (isVerified) {
      setProfileField('isVerified', isVerified);
    }
  };

  const { startInterval, clearInterval, intervalId } = useInterval(
    handleCheckForVerifyStatusChange,
    10000,
  );

  const handleLoadProfileData = async () => {
    try {
      const [isExpertIdentityVerified, profileRes] = await Promise.all([
        getIsExpertIdentityVerified(),
        getMyProfile(),
      ]);
      const { myProfile } = profileRes.data ?? {};

      if (!myProfile || profileRes.error) {
        return await signOutFromCognito();
      }

      if (!isExpertIdentityVerified.data?.isExpertIdentityVerified) {
        isExpertIdentityVerifiedVar(false);
        return;
      }

      if (myProfile.isVerified && !myProfile.isVerifiedNotificationDisplayed) {
        setIsExpertVerifyModalOpen(true);
      }

      if (!myProfile.isVerified && !intervalId) {
        startInterval();
      }

      const pageRedirect = queryParams.get('pageRedirect');
      if (pageRedirect) {
        queryParams.delete('pageRedirect');
        const restParams = queryParams.toString();
        navigate(`${pageRedirect}${restParams ? `?${restParams}` : ''}`);
      }

      profileVar(myProfile);
      isExpertIdentityVerifiedVar(true);
    } catch (err) {
      console.error(err);
    }
  };

  const reloadExpertProfile = useCallback(async () => {
    const profileRes = await getMyProfile();

    const { myProfile } = profileRes.data ?? {};

    if (!myProfile || profileRes.error) {
      return signOutFromCognito();
    }

    if (myProfile.isVerified && !myProfile.isVerifiedNotificationDisplayed) {
      setIsExpertVerifyModalOpen(true);
    }

    if (!myProfile.isVerified && !intervalId) {
      startInterval();
    }

    await profileVar(myProfile);
  }, [getMyProfile, signOutFromCognito, setIsExpertVerifyModalOpen, startInterval, profileVar]);

  const isExpertIdentityVerified = useReactiveVar(isExpertIdentityVerifiedVar);
  useEffect(() => {
    if (!isExpertIdentityVerified || profile || !isAuthenticated) {
      return;
    }

    reloadExpertProfile();
  }, [isExpertIdentityVerified]);

  const handleInitialAuthCheck = async () => {
    if (!initialLoading) {
      setInitialLoading(true);
    }
    const res = await verifyAuthentication();
    if (typeof res !== 'string') {
      await handleLoadProfileData();
    }
    setInitialLoading(false);
  };

  const throttledHandleInitialAuthCheck = useThrottledCallback(handleInitialAuthCheck, 750, {
    trailing: false,
    leading: true,
  });

  useLayoutEffect(() => {
    throttledHandleInitialAuthCheck();
    if (!isAuthenticated) {
      clearInterval();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const { isVerified, isVerifiedNotificationDisplayed } = profile ?? {};
    if (isVerified && !isVerifiedNotificationDisplayed) {
      setIsExpertVerifyModalOpen(true);
      clearInterval();
    }
  }, [profile]);

  return profileLoading || initialLoading ? (
    <PageLoadingSpinner />
  ) : (
    <>
      <ExpertVerifiedInfoModal
        isOpen={isExpertVerifyModalOpen}
        onClose={() => setIsExpertVerifyModalOpen(false)}
      />
      <AppRouter isAuthenticated={isAuthenticated} />
    </>
  );
};
