import { JobOrderList, Paper } from 'components';
import { t } from 'i18next';
import { transformCountForPluralInterval } from 'utils';
import { useEndedApplicationJobOrdersQuery } from '../../../hooks/api/endedApplicationJobOrders/endedApplicationJobOrders.generated';

export const EndedApplications = () => {
  const { data, loading } = useEndedApplicationJobOrdersQuery();
  const appliedJobOrders = data?.endedApplicationJobOrders ?? [];

  return (
    <Paper
      className="lg:relative lg:-top-20 xl:-top-24"
      subtitle={t('applications:applicationsAmount', {
        count: transformCountForPluralInterval(appliedJobOrders.length),
        amount: appliedJobOrders.length,
        postProcess: 'interval',
      })}
      subtitleClassName="inline-block"
      title={t('applications:endedApplications.title')}
      titleClassName="inline-block !mb-2 mr-2"
    >
      <JobOrderList jobOrders={appliedJobOrders} loading={loading} name="appliedJobOrders" />
    </Paper>
  );
};
