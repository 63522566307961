import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from 'components';
import { useAsyncCallback, useSitemap } from 'hooks';
import { CognitoErrorCodesEnum } from 'interfaces';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRegisterExpertLoginFromPublicOfferMutation } from '../../../../hooks/api/registerExpertLoginFromPublicOffer/registerExpertLoginFromPublicOffer.generated';
import { AuthFlowType } from '../../../../hooks/useAuthWithOTP';
import { ResendCodeButton } from '../../../Auth/forms/ResendCodeButton';
import { externalOfferLoginFormValidationSchema } from './validationSchema';

export interface ExternalOfferLoginFormValues {
  otpCode: string;
}

interface ExternalOfferLoginFormProps {
  jobOrderId: string;
  offerId: string;
  expertId: string | null;
  onOTPSubmit: (otpCode: string, displayErrorToast?: boolean) => Promise<any>;
  onResendCodeClick: () => Promise<void>;
  onPasswordSubmit: (password: string) => Promise<string>;
  flowType: AuthFlowType;
  onResetPasswordStartClick?: () => Promise<void>;
  isPasswordResetFlowStarted: boolean;
  onResetPasswordOtpSubmit: (otp: string) => Promise<void>;
}

export const ExternalOfferLoginForm: FC<ExternalOfferLoginFormProps> = ({
  flowType,
  jobOrderId,
  offerId,
  expertId,
  onOTPSubmit,
  onResendCodeClick,
  onPasswordSubmit,
  onResetPasswordStartClick,
  isPasswordResetFlowStarted,
  onResetPasswordOtpSubmit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offer } = useSitemap();
  const [registerExpertLoginFromPublicOffer] = useRegisterExpertLoginFromPublicOfferMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ExternalOfferLoginFormValues>({
    resolver: yupResolver(externalOfferLoginFormValidationSchema(t)),
  });

  const onSubmit = async (values: ExternalOfferLoginFormValues) => {
    const { otpCode } = values;
    if (flowType === 'USER_PASSWORD' && !isPasswordResetFlowStarted) {
      const res = await onPasswordSubmit(otpCode);
      if (res === CognitoErrorCodesEnum.NotAuthorizedException) {
        return setError('otpCode', {
          message: t('forms:validationMessages.wrongPassword') ?? '',
        });
      }
    }

    if (isPasswordResetFlowStarted) {
      await onResetPasswordOtpSubmit(otpCode);
    }

    if (flowType === 'EMAIL_OTP' || (flowType === 'USER_PASSWORD' && isPasswordResetFlowStarted)) {
      const res = await onOTPSubmit(otpCode, false);
      if (res.challengeName) {
        setError('otpCode', { message: t('forms:validationMessages.wrongCode') ?? '' });
        return;
      }
    }

    if (expertId) {
      await registerExpertLoginFromPublicOffer({
        variables: {
          offerId,
          expertId,
        },
      });
    }
    navigate(offer(jobOrderId, offerId));
  };
  const [onSubmitCallback, { loading: submitLoading }] = useAsyncCallback(onSubmit);

  return (
    <form
      className="p-8 space-y-6 border border-gray-20 rounded-xl"
      onSubmit={handleSubmit(onSubmitCallback)}
    >
      <p className="text-xl font-bold">
        {isPasswordResetFlowStarted
          ? t('auth:signInForm.forgotPassword')
          : t('offers:externalOffer.loginForm.title')}
      </p>
      <p className="text-base">
        {flowType === 'USER_PASSWORD'
          ? !isPasswordResetFlowStarted && t('auth:OTPcode.passwordSubtitle')
          : t('auth:OTPcode.codeSubtitle')}
        {flowType === 'USER_PASSWORD' &&
          isPasswordResetFlowStarted &&
          t('auth:OTPcode.codeSubtitle')}
      </p>
      <TextField
        {...register('otpCode')}
        error={errors.otpCode}
        label={
          flowType === 'USER_PASSWORD' && !isPasswordResetFlowStarted
            ? t('forms:labels.password')
            : t('forms:labels.verificationCode')
        }
        type={flowType === 'USER_PASSWORD' && !isPasswordResetFlowStarted ? 'password' : 'text'}
      />
      <ResendCodeButton
        autoStart={false}
        className="!p-0"
        flowType={flowType}
        onClick={
          isPasswordResetFlowStarted && onResetPasswordStartClick
            ? onResetPasswordStartClick
            : onResendCodeClick
        }
        onResetPasswordStartClick={onResetPasswordStartClick}
      />
      <Button
        className="w-full"
        isDisabled={submitLoading}
        isLoading={submitLoading}
        label={t('auth:OTPcode.checkOffers')}
        type="submit"
      />
    </form>
  );
};
