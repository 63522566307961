import { Button } from 'components';
import { MeetingLength } from 'components/MeetingHourSelect/utils';
import dayjs from 'dayjs';
import { CheckedCalendarIcon } from 'icons';
import { JobOrderQueryType } from 'interfaces';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetter,
  filterPastTimeslots,
  sortHoursAscending,
  sortTimeslotsDataAscending,
} from 'utils';
import { TimeslotsData } from '../MeetingScheduleModal';

interface SuccessBoxProps {
  jobOrder: JobOrderQueryType | null;
  timeslotsData: TimeslotsData;
  meetingLength: MeetingLength;
  onClose: () => void;
  hasWorkHistory: boolean | null;
  openWorkHistoryModal: () => void;
}
export const SuccessBox: FC<SuccessBoxProps> = ({
  jobOrder,
  timeslotsData,
  meetingLength,
  onClose,
  hasWorkHistory,
  openWorkHistoryModal,
}) => {
  const { t } = useTranslation();
  const { clientName, role } = jobOrder ?? {
    clientName: '-',
    contactPerson: { email: '-' },
  };

  return (
    <div>
      <div className="max-w-[788px] mx-auto pt-14 pb-0 md:pb-20 flex flex-col justify-between h-full md:block">
        <div className="px-4 pb-40 md:pb-0">
          <div className="flex flex-col items-center border-b-gray-10 border-b-[1px] pb-6 lg:pb-8 mb-6 lg:mb-8">
            <CheckedCalendarIcon className="w-12 h-12 mb-4" />
            <p className="text-[24px] md:text-[32px] font-bold mb-2 lg:mb-4 lg:leading-[40px]">
              {t('datePicker:successBox.title')}
            </p>
            <p className="text-[16px] lg:text-[18px] leading-[24px] lg:leading-[27px] text-center pb-2 lg:pb-6">
              {t('datePicker:successBox.subtitle', { companyName: clientName })}
            </p>
            <p className="text-[16px] lg:text-[18px] leading-[24px] lg:leading-[27px] text-center">
              {t('datePicker:successBox.verificationInfo')}
            </p>
          </div>
          <div className="pb-6 lg:pb-8">
            <p className="font-semibold text-[16px] lg:text-[18px] pb-2 lg:pb-4">
              {t('forms:labels.offer')}
            </p>
            <p className="font-medium text-[14px] lg:text-[16px] leading-[18px] pb-1 lg:pb-2">
              {role}
            </p>
            <p className="text-gray-500 text-[16px] leading-[18px]">{clientName}</p>
          </div>
          {jobOrder?.owner && (
            <div className="pb-6 lg:pb-8">
              <p className="font-semibold text-[16px] lg:text-[18px] pb-2 lg:pb-4">
                {t('datePicker:successBox.contactPerson')}
              </p>
              <a
                className="text-[16px] leading-[18px] text-primary-500"
                href={`mailto:${jobOrder?.owner} ?? ""`}
              >
                {jobOrder?.owner.email ?? ''}
              </a>
            </div>
          )}
          <div>
            <p className="font-semibold text-[16px] lg:text-[18px] pb-2 lg:pb-4">
              {t('datePicker:successBox.selectedTimeslots')}
            </p>
            <div className="grid md:grid-cols-3 gap-4 md:gap-14 max-h-52">
              {Object.entries(sortTimeslotsDataAscending(filterPastTimeslots(timeslotsData))).map(
                ([dayDate, hours]) => {
                  const hoursWithEndHour = sortHoursAscending(hours).map((hour) => [
                    hour,
                    dayjs(hour, 'HH:mm').add(meetingLength, 'minutes').format('HH:mm'),
                  ]);

                  return (
                    <div key={dayDate} className="max-w-[225px]">
                      <p className="font-medium text-[14p] text-[16px] leading-[18px] pb-1 lg:pb-2">
                        {capitalizeFirstLetter(dayjs(dayDate).format('dddd, D MMMM'))}
                      </p>
                      <p className="text-gray-500 text-[14px] lg:text-[16px] leading-[18px]">
                        {hoursWithEndHour.map(([start, end]) => `${start} - ${end}`).join(', ')}
                      </p>
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center md:mt-16 shadow-sm md:shadow-none p-4 md:p-0 fixed md:relative left-0 bottom-0 w-full bg-white">
          {hasWorkHistory ? (
            <Button
              className="w-full max-w-sm md:w-auto md:px-16"
              label={t('datePicker:successBox.close')}
              onClick={onClose}
            />
          ) : (
            <Button
              className="w-full max-w-sm md:w-auto md:px-16"
              label={t('datePicker:successBox.next')}
              onClick={openWorkHistoryModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
