import { LocationInput, Paper } from 'components';
import { GoogleLocationObject } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface LocationValues {
  location: string;
  locationObject: GoogleLocationObject;
}

export const Location = () => {
  const { t } = useTranslation();

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<LocationValues>();

  const locationValue = watch('location');

  const handleLocationChange = (value: GoogleLocationObject) => {
    setValue('location', value.description, { shouldValidate: true, shouldDirty: true });
    setValue('locationObject', value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Paper
      className="!p-4 xl:!p-8 !mt-0"
      id="location"
      title={t('profile:papers.location.title')}
      titleClassName="!mb-4 xl:!mb-6"
    >
      <LocationInput
        className="lg:max-w-xl"
        error={errors.location}
        label={t('profile:papers.location.locationInputLabel') ?? ''}
        name="location"
        onChange={handleLocationChange}
        placeholder={t('forms:placeholders.locationInput') ?? ''}
        value={locationValue}
      />
    </Paper>
  );
};
