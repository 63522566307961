import { Button, CircularProgress, LoadingSpinner } from 'components';
import { useSitemap } from 'hooks';
import { LanguageIcon, LevelsIcon, LocationIcon, OfficeIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConnectisLogo from '../../../../assets/images/connectis-logo.svg';
import { useMostRecommendedOfferQuery } from '../../../../hooks/api/mostRecommendedOffer/mostRecommendedOffer.generated';
import { Detail } from '../../../JobOrderList/subcomponents/JobOrderListItem/JobOrderListItem';
import { JobOrderDetailsFillProfile } from './JobOrderDetailsFillProfile';
import { determineRequiredLanguageLevel } from './JobOrderDetailsHeader';

export const JobOrderDetailsRecommendation: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { data, loading } = useMostRecommendedOfferQuery();
  const sitemap = useSitemap();
  const navigate = useNavigate();

  const jobOrder = data?.recommendedJobOrders[0];
  const {
    jobOrderId,
    role,
    leadingTechnologyName,
    rateRangeFrom,
    rateRangeTo,
    locationPreference,
    seniority,
    workMode,
    minEnglishLevel,
    score,
  } = jobOrder ?? {};

  const roundedRateRangeFrom = Math.round(rateRangeFrom ?? 0);
  const roundedRateRangeTo = Math.round(rateRangeTo ?? 0);
  const jobOrderName = [role, leadingTechnologyName].filter(Boolean).join(' ');

  const handleSeeDetailsClick = () => {
    if (!jobOrderId) {
      return;
    }
    closeModal();
    navigate(sitemap.offer(jobOrderId), {
      replace: true,
    });
  };

  if (loading) {
    return (
      <div className="border border-gray-200 rounded-xl px-8 py-12 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!jobOrder) {
    return <JobOrderDetailsFillProfile closeModal={closeModal} />;
  }

  return (
    <div className="border border-gray-200 rounded-xl p-8 lg:max-w-[424px] mt-8">
      <div className="border-b border-gray-10">
        <p className="md:text-xl font-bold mb-4">{t('offers:offerRecommendation.seeOtherOffer')}</p>
      </div>
      <div className="mt-2 mb-4">
        <div className="flex items-center gap-4">
          <CircularProgress
            className="w-14 h-14"
            textClassName="text-black"
            value={score?.finalScore ?? 0}
          />
          <p className="font-semibold">{jobOrderName}</p>
        </div>
        <div className="flex items-center gap-3 my-[18px]">
          <img alt="logo" className="w-[95px] h-3" src={ConnectisLogo} />
          <p className="self-start">
            <span className="leading-[20px] font-medium">
              {`${roundedRateRangeFrom}-${roundedRateRangeTo}`} PLN
            </span>
            <span className="text-gray-400"> /{t('common:hourShort')}</span>
            <span className="text-gray-400"> - B2B</span>
          </p>
        </div>
        <div className="flex flex-wrap gap-3">
          {locationPreference && (
            <Detail
              className="whitespace-nowrap text-ellipsis overflow-hidden mr-4 lg:mr-0 max-w-[123px]"
              icon={LocationIcon}
              iconClassName="!w-4 !h-4 text-gray-400 !min-w-4"
              value={locationPreference}
            />
          )}
          {workMode && (
            <Detail
              className="mr-4 lg:mr-0"
              icon={OfficeIcon}
              iconClassName="!w-4 !h-4 text-gray-400"
              value={t(`dictionaries:workTypes.${workMode}`)}
            />
          )}
          {seniority && (
            <Detail
              className="mr-4 lg:mr-0"
              icon={LevelsIcon}
              iconClassName="!w-4 !h-4 text-gray-400"
              value={seniority}
            />
          )}
          <Detail
            className="mr-4 lg:mr-0"
            icon={LanguageIcon}
            iconClassName="!w-4 !h-4 text-gray-400"
            value={`${t('forms:labels.english')} ${determineRequiredLanguageLevel(
              minEnglishLevel,
            )}`}
          />
        </div>
      </div>
      <Button
        className="w-full"
        label={t('offers:offerRecommendation.seeDetails')}
        onClick={handleSeeDetailsClick}
      />
    </div>
  );
};
