import { IconType } from 'icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';

interface MenuItemProps {
  label: string;
  icon?: IconType;
  href: string;
  className?: string;
  isActive?: boolean;
  onMobileMenuToggle?: () => void;
  scrollToTop?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  href,
  label,
  icon: Icon,
  className = '',
  isActive,
  onMobileMenuToggle,
  scrollToTop,
}) => {
  const navigate = useNavigate();

  const closeMobileMenu = () => {
    onMobileMenuToggle?.();
    navigate(href);
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
      });
    }
  };

  return (
    <li
      className={mergeClasses(
        'flex items-center text-sm font-medium p-5 md:p-4 cursor-pointer transition-all rounded-md',
        isActive ? 'bg-primary-500 bg-opacity-5 text-primary-500' : 'hover:bg-gray-100',
        className,
      )}
      onClick={closeMobileMenu}
    >
      {Icon && (
        <Icon
          className={mergeClasses(
            'mr-2.5 md:mr-2 w-4 h-4 transition-all',
            isActive ? 'text-primary-500' : 'text-gray-400',
          )}
        />
      )}
      {label}
    </li>
  );
};
