import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleExpertHiddenJobOrderMutationVariables = Types.Exact<{
  expertId: Types.Scalars['String'];
  jobOrderId: Types.Scalars['String'];
}>;

export type ToggleExpertHiddenJobOrderMutation = {
  __typename?: 'Mutation';
  toggleExpertHiddenJobOrder: {
    __typename?: 'ToggleExpertHiddenJobOrderResultType';
    jobOrderId: string;
    isHidden: boolean;
  };
};

export const ToggleExpertHiddenJobOrderDocument = gql`
  mutation toggleExpertHiddenJobOrder($expertId: String!, $jobOrderId: String!) {
    toggleExpertHiddenJobOrder(expertId: $expertId, jobOrderId: $jobOrderId) {
      jobOrderId
      isHidden
    }
  }
`;
export type ToggleExpertHiddenJobOrderMutationFn = Apollo.MutationFunction<
  ToggleExpertHiddenJobOrderMutation,
  ToggleExpertHiddenJobOrderMutationVariables
>;

/**
 * __useToggleExpertHiddenJobOrderMutation__
 *
 * To run a mutation, you first call `useToggleExpertHiddenJobOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleExpertHiddenJobOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleExpertHiddenJobOrderMutation, { data, loading, error }] = useToggleExpertHiddenJobOrderMutation({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      jobOrderId: // value for 'jobOrderId'
 *   },
 * });
 */
export function useToggleExpertHiddenJobOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleExpertHiddenJobOrderMutation,
    ToggleExpertHiddenJobOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleExpertHiddenJobOrderMutation,
    ToggleExpertHiddenJobOrderMutationVariables
  >(ToggleExpertHiddenJobOrderDocument, options);
}
export type ToggleExpertHiddenJobOrderMutationHookResult = ReturnType<
  typeof useToggleExpertHiddenJobOrderMutation
>;
export type ToggleExpertHiddenJobOrderMutationResult =
  Apollo.MutationResult<ToggleExpertHiddenJobOrderMutation>;
export type ToggleExpertHiddenJobOrderMutationOptions = Apollo.BaseMutationOptions<
  ToggleExpertHiddenJobOrderMutation,
  ToggleExpertHiddenJobOrderMutationVariables
>;
