import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FileInput, TextField } from 'components';
import { useFileUpload, useRecaptcha } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { allowedCvFileTypes } from 'utils';
import { useApplyForPublicOfferMutation } from '../../../../hooks/api/applyForPublicOffer/applyForPublicOffer.generated';
import { useAuthWithOTP } from '../../../../hooks/useAuthWithOTP';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import { ExternalOfferLegalNotes } from '../ExternalOfferLegalNotes';
import { ExternalOfferLoginForm } from '../ExternalOfferLoginForm';
import { SuccessBox } from './subcomponents';
import { externalOfferApplicationFormValidationSchema } from './validationSchema';

export interface ApplicationFormValues {
  email: string;
  expertCv: File | null;
}

interface ExternalOfferApplicationFormProps {
  offerId: string;
  jobOrderId: string;
  contextContactEmail?: string | null;
  isSuccess: boolean;
  onApplySuccess: () => void;
}

const recaptchaSignCredentialsAction = 'SIGN_EXPERT_CV_UPLOAD_CREDENTIALS';
const recaptchaApplyAction = 'EXTERNAL_OFFER_APPLY';
export const ExternalOfferApplicationForm: FC<ExternalOfferApplicationFormProps> = ({
  offerId,
  jobOrderId,
  isSuccess,
  contextContactEmail,
  onApplySuccess,
}) => {
  const { t } = useTranslation();
  const { uploadExpertCvWithEmail } = useFileUpload();
  const [applyForPublicOffer] = useApplyForPublicOfferMutation();
  const [applicationExpertId, setApplicationExpertId] = useState<string | null>(null);
  const { executeRecaptcha } = useRecaptcha();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { prefillEmailDisabled: isPublicOfferEmailPrefillDisabled } = useFeatureFlags();

  const {
    onEmailSubmit,
    onOTPSubmit,
    onResendCodeClick,
    onPasswordSubmit,
    onResetPasswordOtpSubmit,
    onResetPasswordStartClick,
    flowType,
    isPasswordResetFlowStarted,
  } = useAuthWithOTP();

  const {
    register,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
  } = useForm<ApplicationFormValues>({
    resolver: yupResolver(externalOfferApplicationFormValidationSchema(t)),
  });

  const handleError = () => {
    toast.error(t('toasts:error'));
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (isPublicOfferEmailPrefillDisabled || !contextContactEmail) {
      return;
    }
    setValue('email', contextContactEmail);
  }, [contextContactEmail]);

  const onSubmit = async (values: ApplicationFormValues) => {
    const { email, expertCv } = values;
    if (!expertCv) {
      return;
    }
    setSubmitLoading(true);

    await executeRecaptcha(
      recaptchaSignCredentialsAction,
      async (uploadCvRecaptchaToken: string) => {
        const { fileId: cvFileId, expertId } = await uploadExpertCvWithEmail({
          expertEmail: email,
          offerId,
          recaptchaToken: uploadCvRecaptchaToken,
          recaptchaAction: recaptchaSignCredentialsAction,
          file: expertCv,
        });
        if (typeof cvFileId !== 'string') {
          return handleError();
        }
        await executeRecaptcha(recaptchaApplyAction, async (applyRecaptchaToken: string) => {
          const res = await applyForPublicOffer({
            onError: () => toast.error(t('toasts:error')),
            variables: {
              params: {
                recaptchaAction: recaptchaApplyAction,
                recaptchaToken: applyRecaptchaToken,
                expertId,
                offerId,
                cvFileId,
              },
            },
          });

          if (res.errors) {
            return handleError();
          }
          setApplicationExpertId(expertId);
          await onEmailSubmit(email);
          onApplySuccess();
          setSubmitLoading(false);
        });
      },
    );
  };

  if (isSuccess) {
    return (
      <div className="space-y-6 lg:space-y-8">
        <SuccessBox />
        <ExternalOfferLoginForm
          expertId={applicationExpertId}
          flowType={flowType}
          isPasswordResetFlowStarted={isPasswordResetFlowStarted}
          jobOrderId={jobOrderId}
          offerId={offerId}
          onOTPSubmit={onOTPSubmit}
          onPasswordSubmit={onPasswordSubmit}
          onResendCodeClick={onResendCodeClick}
          onResetPasswordOtpSubmit={onResetPasswordOtpSubmit}
          onResetPasswordStartClick={onResetPasswordStartClick}
        />
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-6 p-4 border border-gray-200 rounded-t-xl lg:p-8">
          <p className="text-[16px] lg:text-[18px] font-bold">{t('offers:externalOffer.apply')}</p>
          <TextField
            disabled={submitLoading}
            label={t('forms:labels.yourEmail')}
            {...register('email')}
            error={errors.email}
          />
          <FileInput
            accept={allowedCvFileTypes}
            disabled={submitLoading}
            error={errors.expertCv}
            fileNameMaxLength={30}
            helperText={t('offers:externalOffer.allowedCVFormats') ?? ''}
            label={t('offers:externalOffer.addCV')}
            name="expertCv"
            onChange={(cvFile) =>
              setValue('expertCv', cvFile, {
                shouldValidate: true,
              })
            }
            onFileRejection={() => {
              setError('expertCv', {
                message: t('forms:validationMessages.invalidFileType') ?? '',
              });
            }}
          />
        </div>
        <div className="px-8 py-6 border-b border-l border-r border-gray-200 bg-gray-200/20 rounded-b-xl">
          <Button
            className="w-full !rounded-lg"
            isDisabled={submitLoading}
            isLoading={submitLoading}
            label={t('common:actions.next')}
            labelClassName="text-[14px] lg:text-[16px]"
            type="submit"
          />
        </div>
      </form>
      <ExternalOfferLegalNotes />
    </>
  );
};
