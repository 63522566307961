import { Radio } from 'components';
import { ResolvedTagType } from 'interfaces';
import { FC, useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { findElementByKey } from 'utils';
import { useOnboardingDataQuery } from '../../../../hooks/api/onboardingData/onboardingData.generated';
import { RoleStepValues } from '../../steps';
import { AvailableMainTechnologies } from './MainTechnologySelect';

type AvailableMainTechnology = AvailableMainTechnologies[number];

type MainTechnologies = AvailableMainTechnology['mainTechnologies'];

interface MainTechnologyItemProps extends AvailableMainTechnology {
  index: number;
  onChange: (value: string) => void;
  jobPositionId: string;
}

const mapTagToTechnology = (tag: ResolvedTagType): MainTechnologies[number] => ({
  tag,
  defaultTagIds: [],
});

export const MainTechnologyItem: FC<MainTechnologyItemProps> = ({
  name,
  mainTechnologies: initialMainTechnologies,
  index,
  onChange,
  jobPositionId,
}) => {
  const { t } = useTranslation();
  const [mainTechnologies, setMainTechnologies] =
    useState<MainTechnologies>(initialMainTechnologies);

  const { data } = useOnboardingDataQuery();
  const { tags: skills } = data ?? { tags: [] };
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<RoleStepValues>();

  const mainTechnologyIdsValue = watch('mainTechnologyIds');
  const manuallyAddedMainTechnologiesValue = watch('manuallyAddedMainTechnologies');
  const value = `${mainTechnologyIdsValue?.[index]}_${index}_${jobPositionId}`;

  const radioOptions = mainTechnologies.map(({ tag }) => ({
    value: `${tag.id}_${index}_${jobPositionId}`,
    label: tag.name,
  }));

  const otherSkillOptions = skills
    .filter(({ id }) => !mainTechnologies.find(({ tag }) => tag.id === id))
    .map(({ id, displayName, name: tagName }) => ({
      value: id,
      label: displayName || tagName,
    }));

  const handleAddOtherSkill = (tagId: string) =>
    setValue('manuallyAddedMainTechnologies', [...manuallyAddedMainTechnologiesValue, tagId]);

  useEffect(() => {
    if (!skills.length) return;
    setMainTechnologies((prev) => {
      const mappedManuallyAddedTechs: MainTechnologies = manuallyAddedMainTechnologiesValue
        .filter((id) => !prev.find(({ tag }) => tag.id === id))
        .map((tagId) => {
          const tag = findElementByKey(skills, 'id', tagId);
          return mapTagToTechnology(tag as ResolvedTagType);
        });

      return [...prev, ...mappedManuallyAddedTechs];
    });
  }, [manuallyAddedMainTechnologiesValue, skills]);

  return (
    <div>
      <p className="font-medium">
        {t('onboarding:roleStep.selectMainTechnologyLabel')}
        {name && ` - ${name}`}
      </p>
      <Radio
        addNewItemOptions={otherSkillOptions}
        className="mt-2"
        columns={4}
        error={value.includes('undefined') ? (errors.mainTechnologyIds as FieldError) : undefined}
        isUsingAddButton
        name={`mainTechSelect-${jobPositionId}`}
        onAddNewItemChange={handleAddOtherSkill}
        onChange={onChange}
        options={radioOptions}
        radioItemClassName="text-sm gap-2 px-3 sm:mb-2"
        value={value}
      />
    </div>
  );
};
