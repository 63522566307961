export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type ExpertLanguageType = {
  __typename?: 'ExpertLanguageType';
  languageId: Scalars['String'];
  level: LanguageLevelEnum;
};

export enum LanguageLevelEnum {
  None = 'None',
  Starter = 'Starter',
  Elementary = 'Elementary',
  PreIntermediate = 'PreIntermediate',
  Intermediate = 'Intermediate',
  UpperIntermediate = 'UpperIntermediate',
  Advanced = 'Advanced',
}

export type ExpertCertificateType = {
  __typename?: 'ExpertCertificateType';
  name: Scalars['String'];
  issuer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  englishDescription?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
};

export type TagType = {
  __typename?: 'TagType';
  id: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  type: TagTypeEnum;
  aliases?: Maybe<Array<Scalars['String']>>;
};

export enum TagTypeEnum {
  Default = 'Default',
  TechnicalSkill = 'TechnicalSkill',
}

export type ExpertTagType = {
  __typename?: 'ExpertTagType';
  id: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  isUsed?: Maybe<Scalars['Boolean']>;
  type: TagTypeEnum;
  aliases?: Maybe<Array<Scalars['String']>>;
  weight: Scalars['Int'];
};

export type ExpertWorkHistoryType = {
  __typename?: 'ExpertWorkHistoryType';
  jobPositionName?: Maybe<Scalars['String']>;
  englishJobPositionName?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  englishIndustry?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  dateStart: Scalars['DateTime'];
  dateEnd?: Maybe<Scalars['DateTime']>;
  isCurrentJob?: Maybe<Scalars['Boolean']>;
  isCompanyNameSkipped?: Maybe<Scalars['Boolean']>;
  tagsIds?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  englishDescription?: Maybe<Scalars['String']>;
};

export type Term = {
  __typename?: 'Term';
  offset: Scalars['Int'];
  value: Scalars['String'];
};

export type MatchedSubstring = {
  __typename?: 'MatchedSubstring';
  offset: Scalars['Int'];
  length: Scalars['Int'];
};

export type StructuredFormatting = {
  __typename?: 'StructuredFormatting';
  main_text: Scalars['String'];
  secondary_text: Scalars['String'];
  main_text_matched_substrings: Array<MatchedSubstring>;
};

export type GoogleLocationObject = {
  __typename?: 'GoogleLocationObject';
  description: Scalars['String'];
  matched_substrings: Array<MatchedSubstring>;
  place_id: Scalars['String'];
  reference: Scalars['String'];
  structured_formatting: StructuredFormatting;
  terms: Array<Term>;
  types: Array<Scalars['String']>;
};

export type ExpertNotificationConsentsType = {
  __typename?: 'ExpertNotificationConsentsType';
  OffersAndProfiles: Scalars['Boolean'];
  ApplicationAndMeetings: Scalars['Boolean'];
  News: Scalars['Boolean'];
};

export type ExpertType = {
  __typename?: 'ExpertType';
  expertId: Scalars['String'];
  crmId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crmCreatedAt?: Maybe<Scalars['DateTime']>;
  hubCreatedAt?: Maybe<Scalars['DateTime']>;
  lastNoteCreatedAt?: Maybe<Scalars['DateTime']>;
  tags: Array<ExpertTagType>;
  jobPositionName?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  jobPositionId?: Maybe<Scalars['String']>;
  mainTechnologyIds?: Maybe<Array<Scalars['String']>>;
  experienceYears?: Maybe<Scalars['Float']>;
  rateMin?: Maybe<Scalars['Float']>;
  rateMax?: Maybe<Scalars['Float']>;
  rateDesired?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  locationObject?: Maybe<GoogleLocationObject>;
  desiredOfficeLocations?: Maybe<Array<Scalars['String']>>;
  desiredTechnicalSkills?: Maybe<Array<Scalars['String']>>;
  desiredIndustries?: Maybe<Array<Scalars['String']>>;
  desiredFields?: Maybe<Array<Scalars['String']>>;
  workType?: Maybe<WorkModeEnum>;
  seniority?: Maybe<SeniorityLevelEnum>;
  languages?: Maybe<Array<ExpertLanguageType>>;
  leadingTechnologies?: Maybe<Array<TagType>>;
  tagsVisibleOnBlind?: Maybe<Array<Scalars['String']>>;
  tagsSuperPowers?: Maybe<Array<Scalars['String']>>;
  recommendation?: Maybe<Scalars['String']>;
  englishRecommendation?: Maybe<Scalars['String']>;
  certificates: Array<ExpertCertificateType>;
  workHistory: Array<ExpertWorkHistoryType>;
  availability?: Maybe<ExpertAvailabilityEnum>;
  noticeLength?: Maybe<Scalars['Int']>;
  isEmployedIts?: Maybe<Scalars['Boolean']>;
  manuallyAddedJobPositions: Array<Scalars['String']>;
  manuallyAddedMainTechnologies: Array<Scalars['String']>;
  manuallyAddedDesiredTechnicalSkills: Array<Scalars['String']>;
  avatar?: Maybe<ExpertAvatarEnum>;
  photoFileId?: Maybe<Scalars['String']>;
  wordCloudFileId?: Maybe<Scalars['String']>;
  savedJobOrderIds?: Maybe<Array<Scalars['String']>>;
  hiddenJobOrderIds?: Maybe<Array<Scalars['String']>>;
  isOnboardingFinished: Scalars['Boolean'];
  isCertificatesSkipped: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  notificationConsents: ExpertNotificationConsentsType;
  isVerified: Scalars['Boolean'];
  isVerifiedNotificationDisplayed: Scalars['Boolean'];
  dataSource: Array<ExpertDataSourceEnum>;
  githubUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  descriptiveLabel?: Maybe<Scalars['String']>;
  cvId?: Maybe<Scalars['String']>;
  crmDynamicsId?: Maybe<Scalars['String']>;
  jobOrderApplicationsCount: Scalars['Int'];
  fileUrls: ExpertFilesUrlsType;
  cv?: Maybe<ExpertCvType>;
};

export enum WorkModeEnum {
  Office = 'Office',
  Remote = 'Remote',
  Hybrid = 'Hybrid',
}

export enum SeniorityLevelEnum {
  Junior = 'Junior',
  Regular = 'Regular',
  Senior = 'Senior',
}

export enum ExpertAvailabilityEnum {
  Immediately = 'Immediately',
  Days = 'Days',
  Months = 'Months',
}

export enum ExpertAvatarEnum {
  Photo = 'Photo',
  WordCloud = 'WordCloud',
}

export enum ExpertDataSourceEnum {
  Crm = 'CRM',
  Hub = 'Hub',
  Prospect = 'Prospect',
}

export type ContactPersonType = {
  __typename?: 'ContactPersonType';
  id: Scalars['String'];
  oid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ContactPeopleQueryResultType = {
  __typename?: 'ContactPeopleQueryResultType';
  contactPeople: Array<ContactPersonType>;
};

export type JobOrderClientParamsScoreType = {
  __typename?: 'JobOrderClientParamsScoreType';
  roleLeadingTechnologyScore?: Maybe<DifficultyLevelEnum>;
  rateScore?: Maybe<DifficultyLevelEnum>;
  businessDescriptionScore?: Maybe<QualityLevelEnum>;
  jobOrderDescriptionScore?: Maybe<QualityLevelEnum>;
  businessRequirementsDescriptionScore?: Maybe<QualityLevelEnum>;
  businessResponsibilityDescriptionScore?: Maybe<QualityLevelEnum>;
  clientPtcFeedbackScore?: Maybe<FeedbackScoreEnum>;
  clientCiFeedbackScore?: Maybe<FeedbackScoreEnum>;
  clientRecruitmentProcessLength?: Maybe<RecruitmentProcessLengthEnum>;
  /** how many profiles should be sent before JO can be considered "done" */
  fillRateThresholdPerOpening?: Maybe<Scalars['Int']>;
  recruitmentTest?: Maybe<RecruitmentTestEnum>;
  isVerificationNeeded?: Maybe<Scalars['Boolean']>;
  contractSigned?: Maybe<ContractSignedEnum>;
  jobOrderScore?: Maybe<Scalars['Float']>;
};

export enum DifficultyLevelEnum {
  Hard = 'Hard',
  Medium = 'Medium',
  Easy = 'Easy',
}

export enum QualityLevelEnum {
  VeryGood = 'VeryGood',
  Good = 'Good',
  Bad = 'Bad',
  NoDescription = 'NoDescription',
}

export enum FeedbackScoreEnum {
  OneTwoDays = 'OneTwoDays',
  ThreeFourDays = 'ThreeFourDays',
  MorThanFiveDays = 'MorThanFiveDays',
}

export enum RecruitmentProcessLengthEnum {
  OneStage = 'OneStage',
  TwoStages = 'TwoStages',
  MoreThanTwoStages = 'MoreThanTwoStages',
}

export enum RecruitmentTestEnum {
  NoTest = 'NoTest',
  Test = 'Test',
  Assigment = 'Assigment',
}

export enum ContractSignedEnum {
  Yes = 'Yes',
  Processing = 'Processing',
  No = 'No',
}

export type JobOrderFillRateParamsType = {
  __typename?: 'JobOrderFillRateParamsType';
  assignedBlinds: Scalars['Int'];
  processedBlinds: Scalars['Int'];
  placedBlinds: Scalars['Int'];
  /** Correct name is targetPTC */
  fillRate: Scalars['Int'];
  targetCI: Scalars['Int'];
  targetPlacement: Scalars['Int'];
  lostBeforeCIBlinds: Scalars['Int'];
  inGameToCIBlinds: Scalars['Int'];
  ciDoneBlinds: Scalars['Int'];
  lostAfterCIBlinds: Scalars['Int'];
  inGameAfterCIBlinds: Scalars['Int'];
  rejectedBySalesBlinds: Scalars['Int'];
  processedBlindsWithinDeadline: Scalars['Int'];
  processedBlindsAfterDeadline: Scalars['Int'];
  processedBlindsDeadline: Scalars['DateTime'];
  clientInterviewBlindsWithinDeadline: Scalars['Int'];
  clientInterviewBlindsAfterDeadline: Scalars['Int'];
  clientInterviewBlindsDeadline: Scalars['DateTime'];
  placementBlindsAfterDeadline: Scalars['Int'];
  placementBlindsWithinDeadline: Scalars['Int'];
  placementBlindsDeadline: Scalars['DateTime'];
  isCalculatedAlternatively: Scalars['Boolean'];
};

export type JobOrderAssigneesParamsType = {
  __typename?: 'JobOrderAssigneesParamsType';
  brm?: Maybe<Scalars['String']>;
  talentAdvocates?: Maybe<Array<Scalars['String']>>;
};

export type JobOrderStatusHistoryType = {
  __typename?: 'JobOrderStatusHistoryType';
  statusType: JobOrderStatusTypeEnum;
  changedFrom?: Maybe<JobOrderStatusMergeEnum>;
  changedTo: JobOrderStatusMergeEnum;
  changeDate: Scalars['DateTime'];
  changedById: Scalars['String'];
  resultStatus?: Maybe<JobOrderResultEnum>;
  comments?: Maybe<Scalars['String']>;
  isReverted: Scalars['Boolean'];
};

export enum JobOrderStatusTypeEnum {
  BusinessStatus = 'businessStatus',
  DefinitionOfReadyStatus = 'definitionOfReadyStatus',
}

export enum JobOrderStatusMergeEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  ToFill = 'ToFill',
  SubmittedForEvaluation = 'SubmittedForEvaluation',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum JobOrderResultEnum {
  Winning = 'Winning',
  Lost = 'Lost',
}

export type JobOrderBusinessOwnerType = {
  __typename?: 'JobOrderBusinessOwnerType';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type JobOrderAssignmentHistoryType = {
  __typename?: 'JobOrderAssignmentHistoryType';
  assignmentDate: Scalars['DateTime'];
  changedById: Scalars['String'];
  owner: Scalars['String'];
  assignees: Array<JobOrderAssigneesParamsType>;
  isBttlReset: Scalars['Boolean'];
  isReverted: Scalars['Boolean'];
};

export type CreatedByUserType = {
  __typename?: 'CreatedByUserType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type JobOrderPublicationHistoryType = {
  __typename?: 'JobOrderPublicationHistoryType';
  changedAt: Scalars['DateTime'];
  changedById: Scalars['String'];
  publicationStatus: Scalars['Boolean'];
};

export type JobOrderType = {
  __typename?: 'JobOrderType';
  jobOrderId: Scalars['String'];
  customId: Scalars['Int'];
  name: Scalars['String'];
  clientBudget?: Maybe<Scalars['String']>;
  isRelocationRequired: Scalars['Boolean'];
  dateStart1?: Maybe<Scalars['DateTime']>;
  dateStart2?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  acceptanceDate?: Maybe<Scalars['DateTime']>;
  submittedForEvaluationDate?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  assignees: Array<JobOrderAssigneesParamsType>;
  leadingTechnology?: Maybe<Scalars['String']>;
  leadingTechnologyName?: Maybe<Scalars['String']>;
  bttl?: Maybe<Scalars['Int']>;
  stopBttlDate?: Maybe<Scalars['DateTime']>;
  vacancies?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  requirements?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  potentialClientId?: Maybe<Scalars['String']>;
  potentialClientName?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  deliveryInformation?: Maybe<Scalars['String']>;
  isRemotePossible: Scalars['Boolean'];
  jobPositionName?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  targetProjectInfo?: Maybe<Scalars['String']>;
  salesmanComment?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  interviewsCount?: Maybe<Scalars['Int']>;
  industries: Array<Scalars['String']>;
  hiredCount?: Maybe<Scalars['Int']>;
  itsMaxSalary?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  createdByEmail?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  statusCode: Scalars['Int'];
  statusName: Scalars['String'];
  businessStatus: JobOrderBusinessStatusEnum;
  definitionOfReadyStatus: JobOrderDefinitionOfReadyStatusEnum;
  tags: Array<ExpertTagType>;
  role?: Maybe<Scalars['String']>;
  seniority?: Maybe<SeniorityLevelEnum>;
  experienceYears?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  jobOrderDescription?: Maybe<Scalars['String']>;
  skillsMustHave: Array<ExpertTagType>;
  skillsNiceToHave: Array<ExpertTagType>;
  targetProductInfo?: Maybe<Scalars['String']>;
  workMethodology?: Maybe<WorkMethodologyEnum>;
  isEnglish?: Maybe<Scalars['Boolean']>;
  /** to be refined */
  minEnglishLevel?: Maybe<LanguageLevelEnum>;
  workMode?: Maybe<WorkModeEnum>;
  workModeParamsDescription?: Maybe<Scalars['String']>;
  locationPreference?: Maybe<Scalars['String']>;
  rateRangeFrom?: Maybe<Scalars['Float']>;
  rateRangeTo?: Maybe<Scalars['Float']>;
  minMargin?: Maybe<Scalars['Float']>;
  maxItcRate?: Maybe<Scalars['Float']>;
  workOrderLengthInMonths?: Maybe<Scalars['Int']>;
  workOrderLength?: Maybe<WorkOrderLengthEnum>;
  /** For how long the work order is open */
  maxAvailability?: Maybe<MaxAvailabilityEnum>;
  workHoursScope?: Maybe<WorkHourScopeEnum>;
  clientParamsScore: JobOrderClientParamsScoreType;
  fillRateParams: JobOrderFillRateParamsType;
  statusHistory: Array<JobOrderStatusHistoryType>;
  assignmentHistory: Array<JobOrderAssignmentHistoryType>;
  /** Additional custom flag (not mapped from CRM) to mark that JO has all required information */
  allRequiredFieldsAreFilled: Scalars['Boolean'];
  isChubVisible: Scalars['Boolean'];
  fillRateKpi: Scalars['Boolean'];
  fillRateKpiComment?: Maybe<FillRateKpiCommentEnum>;
  businessOwner: Array<JobOrderBusinessOwnerType>;
  hubSpotCompanyId?: Maybe<Scalars['String']>;
  hubSpotCompanyName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<CreatedByUserType>;
  assignedUsers?: Maybe<Array<CreatedByUserType>>;
  resultStatus?: Maybe<JobOrderResultEnum>;
  phoneCallsCount: Scalars['Int'];
  scheduledCqCount: Scalars['Int'];
  completedCqCount: Scalars['Int'];
  lastCqUpdate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<JobOrderTypeEnum>;
  isPTCBusinessDeadlineEnabled: Scalars['Boolean'];
  PTCBusinessDeadline?: Maybe<Scalars['DateTime']>;
  publicationHistory: Array<JobOrderPublicationHistoryType>;
  matchedHubExpertsCount?: Maybe<Scalars['Int']>;
  matchedExpertsCount?: Maybe<Scalars['Int']>;
  summarySentAt?: Maybe<Array<Scalars['DateTime']>>;
  isReadyToEvaluate: Scalars['Boolean'];
  expertMatchedNotificationsCount: Scalars['Int'];
  jobOrderApplicationDetails: JobOrderApplicationDetailsType;
  publications: Array<JobOrderPublicationType>;
};

export enum JobOrderBusinessStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum JobOrderDefinitionOfReadyStatusEnum {
  ToFill = 'ToFill',
  SubmittedForEvaluation = 'SubmittedForEvaluation',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum WorkMethodologyEnum {
  Scrum = 'Scrum',
  Agile = 'Agile',
  Kanban = 'Kanban',
  Waterfall = 'Waterfall',
  Lean = 'Lean',
  SixSigma = 'SixSigma',
  Pmbok = 'PMBOK',
  Cpm = 'CPM',
  Ccpm = 'CCPM',
  Prince2 = 'Prince2',
  Other = 'Other',
}

export enum WorkOrderLengthEnum {
  UpToThreeMonths = 'UpToThreeMonths',
  UpToSixMonths = 'UpToSixMonths',
  MoreThanSevenMonths = 'MoreThanSevenMonths',
}

export enum MaxAvailabilityEnum {
  Asap = 'ASAP',
  Month = 'Month',
  MoreThanTwoMonths = 'MoreThanTwoMonths',
}

export enum WorkHourScopeEnum {
  Elastic = 'Elastic',
  Standard = 'Standard',
  Diffrent = 'Diffrent',
}

export enum FillRateKpiCommentEnum {
  Hunting = 'Hunting',
  FarmersHunting = 'FarmersHunting',
  Deadline = 'Deadline',
  Other = 'Other',
}

export enum JobOrderTypeEnum {
  Hunting = 'Hunting',
  Farming = 'Farming',
}

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  roles: Array<UserRole>;
};

export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  Backoffice = 'Backoffice',
  Planner = 'Planner',
  Sales = 'Sales',
  Delivery = 'Delivery',
}

export type ExpertBlindFlowStatusHistory = {
  __typename?: 'ExpertBlindFlowStatusHistory';
  changedFrom?: Maybe<ExpertBlindFlowStatusEnum>;
  changedTo: ExpertBlindFlowStatusEnum;
  changedAt: Scalars['String'];
  meetingDate?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<ExpertBlindFlowStatusFeedbackEnum>;
  changedById?: Maybe<Scalars['String']>;
  isReverted: Scalars['Boolean'];
};

export enum ExpertBlindFlowStatusEnum {
  NotSent = 'NotSent',
  Sent = 'Sent',
  MeetingsScheduled = 'MeetingsScheduled',
  MeetingsCancelled = 'MeetingsCancelled',
  CiCancelledByExpert = 'CICancelledByExpert',
  CiCancelledByClient = 'CICancelledByClient',
  CiScheduled = 'CIScheduled',
  CiFinished = 'CIFinished',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  RejectedAfterCi = 'RejectedAfterCI',
  RejectedOffer = 'RejectedOffer',
  NoFeedback = 'NoFeedback',
  ExpertWithdrew = 'ExpertWithdrew',
  ClientWithdrew = 'ClientWithdrew',
  Placement = 'Placement',
  CiScheduledSecondStage = 'CIScheduledSecondStage',
  CiFinishedSecondStage = 'CIFinishedSecondStage',
  CiScheduledThirdStage = 'CIScheduledThirdStage',
  CiFinishedThirdStage = 'CIFinishedThirdStage',
  RejectedBySales = 'RejectedBySales',
  RejectedByExpert = 'RejectedByExpert',
}

export enum ExpertBlindFlowStatusFeedbackEnum {
  RateMismatch = 'RateMismatch',
  LocationMismatch = 'LocationMismatch',
  NoticePeriodMismatch = 'NoticePeriodMismatch',
  CandidatePersonalityMismatch = 'CandidatePersonalityMismatch',
  ProjectDidNotMeetWorkModeExpectations = 'ProjectDidNotMeetWorkModeExpectations',
  ProjectDidNotMeetOrganizationalExpectations = 'ProjectDidNotMeetOrganizationalExpectations',
  ProjectDidNotMeetFinancialExpectations = 'ProjectDidNotMeetFinancialExpectations',
  ProjectDidNotMeetTechnicalExpectations = 'ProjectDidNotMeetTechnicalExpectations',
  TechnicallyIncompetentCandidate = 'TechnicallyIncompetentCandidate',
  RequirementsChanged = 'RequirementsChanged',
  ExpectationsChanged = 'ExpectationsChanged',
  ChoosingOtherCandidate = 'ChoosingOtherCandidate',
  NoInformation = 'NoInformation',
  ProcessedDirectlyOrByAnotherVendor = 'ProcessedDirectlyOrByAnotherVendor',
  ClientMadeBadImpression = 'ClientMadeBadImpression',
  Counteroffer = 'Counteroffer',
  BrokenContact = 'BrokenContact',
  TooLongRecruitmentProcess = 'TooLongRecruitmentProcess',
  OurContract = 'OurContract',
  CandidateRemainsWithCurrentCompany = 'CandidateRemainsWithCurrentCompany',
  Formalities = 'Formalities',
  BudgetChanged = 'BudgetChanged',
  AlreadyMetWithClient = 'AlreadyMetWithClient',
  Selection = 'Selection',
  ClientRejected = 'ClientRejected',
}

export type CalculateScoreResultType = {
  __typename?: 'CalculateScoreResultType';
  mustHaveSkillsScore: Scalars['Float'];
  niceToHaveSkillsScore: Scalars['Float'];
  salaryScore: Scalars['Float'];
  workModeScore: Scalars['Float'];
  englishLevelScore: Scalars['Float'];
  availabilityScore: Scalars['Float'];
  finalScore: Scalars['Float'];
  minimalSkillLevel: Scalars['Float'];
};

export type ExpertBlindType = {
  __typename?: 'ExpertBlindType';
  blindId: Scalars['String'];
  expertId: Scalars['String'];
  jobOrderId: Scalars['String'];
  contactPersonId: Scalars['String'];
  refId: Scalars['String'];
  firstName: Scalars['String'];
  jobPositionName: Scalars['String'];
  experienceYears: Scalars['String'];
  location: Scalars['String'];
  availability: Scalars['String'];
  rateMin: Scalars['String'];
  rateMax: Scalars['String'];
  margin: Scalars['Float'];
  createdById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  isMoneyRateDisplayed: Scalars['Boolean'];
  isRecommendationDisplayed: Scalars['Boolean'];
  isWorkHistoryDisplayed: Scalars['Boolean'];
  isCertificatesDisplayed: Scalars['Boolean'];
  isVendorless: Scalars['Boolean'];
  isDisplayOnlyFirstLetterOfLastNameChosen: Scalars['Boolean'];
  isBlindPlus: Scalars['Boolean'];
  isBlindMinus: Scalars['Boolean'];
  isDatepickerHidden: Scalars['Boolean'];
  isFullProfile: Scalars['Boolean'];
  languages: Array<ExpertLanguageType>;
  suggestedMeetingHours: Array<Scalars['String']>;
  cancelledMeetingHours: Array<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  flowStatus: ExpertBlindFlowStatusEnum;
  flowStatusHistory?: Maybe<Array<ExpertBlindFlowStatusHistory>>;
  recommendation?: Maybe<Scalars['String']>;
  englishRecommendation?: Maybe<Scalars['String']>;
  score?: Maybe<CalculateScoreResultType>;
  feedback?: Maybe<ExpertBlindFlowStatusFeedbackEnum>;
  profileSource: ProfileSourceEnum;
  clientRate?: Maybe<Scalars['Float']>;
  tags: Array<ExpertTagType>;
  tagsVisibleOnBlind?: Maybe<Array<Scalars['String']>>;
  tagsSuperPowers?: Maybe<Array<Scalars['String']>>;
  recipients: Array<Scalars['String']>;
  isEmailSent: Scalars['Boolean'];
  expert: ExpertType;
  createdBy: UserType;
  contactPerson: ContactPersonType;
  sendDates: Array<Scalars['String']>;
  viewsCount: Scalars['Int'];
  jobOrder: JobOrderType;
  expertBlindsCounters: ExpertBlindCountersType;
};

export enum ProfileSourceEnum {
  Crm = 'CRM',
  Hub = 'Hub',
}

export type BlindsQueryResultType = {
  __typename?: 'BlindsQueryResultType';
  blinds: Array<ExpertBlindType>;
  total: Scalars['Int'];
};

export type ExpertBlindReportType = {
  __typename?: 'ExpertBlindReportType';
  blindId: Scalars['String'];
  expertId: Scalars['String'];
  expertCrmId: Scalars['String'];
  expertName: Scalars['String'];
  expertJobPositionName: Scalars['String'];
  expertRateDesired: Scalars['Float'];
  createdBy: Scalars['String'];
  createdAt: Scalars['String'];
  jobOrderCustomId: Scalars['Int'];
  jobOrderName: Scalars['String'];
  clientName: Scalars['String'];
  margin: Scalars['Float'];
  expertRateFinal: Scalars['Float'];
  expertRateFinalMonthly: Scalars['Float'];
  viewsCount: Scalars['Int'];
};

export type BlindsReportQueryResultType = {
  __typename?: 'BlindsReportQueryResultType';
  reports: Array<ExpertBlindReportType>;
};

export type CreateBlindResultType = {
  __typename?: 'CreateBlindResultType';
  blind: ExpertBlindType;
};

export type UpdateBlindResultType = {
  __typename?: 'UpdateBlindResultType';
  blind: ExpertBlindType;
};

export type ExpertBlindCountersType = {
  __typename?: 'ExpertBlindCountersType';
  allBlinds: Scalars['Int'];
  processedBlinds: Scalars['Int'];
};

export type ExpertBlindApplicationDetailsResultType = {
  __typename?: 'ExpertBlindApplicationDetailsResultType';
  hubBlindsCount: Scalars['Int'];
  isAppliedForJobOrder: Scalars['Boolean'];
};

export type ExpertsQueryResultType = {
  __typename?: 'ExpertsQueryResultType';
  experts: Array<ExpertType>;
};

export type JobOrderSearchPhraseResultType = {
  __typename?: 'JobOrderSearchPhraseResultType';
  searchPhrase: Scalars['String'];
};

export type JobOrderResultType = {
  __typename?: 'JobOrderResultType';
  total: Scalars['Int'];
  data: Array<JobOrderType>;
};

export type JobOrderWithScoreType = {
  __typename?: 'JobOrderWithScoreType';
  jobOrderId: Scalars['String'];
  customId: Scalars['Int'];
  name: Scalars['String'];
  clientBudget?: Maybe<Scalars['String']>;
  isRelocationRequired: Scalars['Boolean'];
  dateStart1?: Maybe<Scalars['DateTime']>;
  dateStart2?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  acceptanceDate?: Maybe<Scalars['DateTime']>;
  submittedForEvaluationDate?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<UserType>;
  assignees?: Maybe<Array<JobOrderAssigneesParamsType>>;
  leadingTechnology?: Maybe<Scalars['String']>;
  leadingTechnologyName?: Maybe<Scalars['String']>;
  bttl?: Maybe<Scalars['Int']>;
  stopBttlDate?: Maybe<Scalars['DateTime']>;
  vacancies?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  requirements?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  potentialClientId?: Maybe<Scalars['String']>;
  potentialClientName?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  deliveryInformation?: Maybe<Scalars['String']>;
  isRemotePossible: Scalars['Boolean'];
  jobPositionName?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  targetProjectInfo?: Maybe<Scalars['String']>;
  salesmanComment?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  interviewsCount?: Maybe<Scalars['Int']>;
  industries: Array<Scalars['String']>;
  hiredCount?: Maybe<Scalars['Int']>;
  itsMaxSalary?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  createdByEmail?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  statusCode: Scalars['Int'];
  statusName: Scalars['String'];
  businessStatus: JobOrderBusinessStatusEnum;
  definitionOfReadyStatus: JobOrderDefinitionOfReadyStatusEnum;
  tags: Array<ExpertTagType>;
  role?: Maybe<Scalars['String']>;
  seniority?: Maybe<SeniorityLevelEnum>;
  experienceYears?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  jobOrderDescription?: Maybe<Scalars['String']>;
  skillsMustHave: Array<ExpertTagType>;
  skillsNiceToHave: Array<ExpertTagType>;
  targetProductInfo?: Maybe<Scalars['String']>;
  workMethodology?: Maybe<WorkMethodologyEnum>;
  isEnglish?: Maybe<Scalars['Boolean']>;
  /** to be refined */
  minEnglishLevel?: Maybe<LanguageLevelEnum>;
  workMode?: Maybe<WorkModeEnum>;
  workModeParamsDescription?: Maybe<Scalars['String']>;
  locationPreference?: Maybe<Scalars['String']>;
  rateRangeFrom?: Maybe<Scalars['Float']>;
  rateRangeTo?: Maybe<Scalars['Float']>;
  minMargin?: Maybe<Scalars['Float']>;
  maxItcRate?: Maybe<Scalars['Float']>;
  workOrderLengthInMonths?: Maybe<Scalars['Int']>;
  workOrderLength?: Maybe<WorkOrderLengthEnum>;
  /** For how long the work order is open */
  maxAvailability?: Maybe<MaxAvailabilityEnum>;
  workHoursScope?: Maybe<WorkHourScopeEnum>;
  clientParamsScore: JobOrderClientParamsScoreType;
  fillRateParams: JobOrderFillRateParamsType;
  statusHistory: Array<JobOrderStatusHistoryType>;
  assignmentHistory: Array<JobOrderAssignmentHistoryType>;
  /** Additional custom flag (not mapped from CRM) to mark that JO has all required information */
  allRequiredFieldsAreFilled: Scalars['Boolean'];
  isChubVisible: Scalars['Boolean'];
  fillRateKpi: Scalars['Boolean'];
  fillRateKpiComment?: Maybe<FillRateKpiCommentEnum>;
  businessOwner: Array<JobOrderBusinessOwnerType>;
  hubSpotCompanyId?: Maybe<Scalars['String']>;
  hubSpotCompanyName?: Maybe<Scalars['String']>;
  createdBy?: Maybe<CreatedByUserType>;
  assignedUsers?: Maybe<Array<CreatedByUserType>>;
  resultStatus?: Maybe<JobOrderResultEnum>;
  phoneCallsCount: Scalars['Int'];
  scheduledCqCount: Scalars['Int'];
  completedCqCount: Scalars['Int'];
  lastCqUpdate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<JobOrderTypeEnum>;
  isPTCBusinessDeadlineEnabled: Scalars['Boolean'];
  PTCBusinessDeadline?: Maybe<Scalars['DateTime']>;
  publicationHistory: Array<JobOrderPublicationHistoryType>;
  matchedHubExpertsCount?: Maybe<Scalars['Int']>;
  matchedExpertsCount?: Maybe<Scalars['Int']>;
  summarySentAt?: Maybe<Array<Scalars['DateTime']>>;
  isReadyToEvaluate: Scalars['Boolean'];
  expertMatchedNotificationsCount: Scalars['Int'];
  jobOrderApplicationDetails: JobOrderApplicationDetailsType;
  publications: Array<JobOrderPublicationType>;
  score: CalculateScoreResultType;
  isHidden: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
  application?: Maybe<JobOrderApplicationWithScoreType>;
};

export type JobOrderSearchResultType = {
  __typename?: 'JobOrderSearchResultType';
  overallCount: Scalars['Int'];
  jobOrders: Array<JobOrderType>;
};

export type JobOrderApplicationDetailsType = {
  __typename?: 'JobOrderApplicationDetailsType';
  jobOrderCustomId: Scalars['Int'];
  isMeetingScheduled: Scalars['Boolean'];
};

export type LanguageType = {
  __typename?: 'LanguageType';
  name: Scalars['String'];
  languageId: Scalars['String'];
};

export type HubSpotCompanyType = {
  __typename?: 'HubSpotCompanyType';
  id: Scalars['String'];
  name: Scalars['String'];
  domain?: Maybe<Scalars['String']>;
};

export type HubSpotContactType = {
  __typename?: 'HubSpotContactType';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type TagQueryResultType = {
  __typename?: 'TagQueryResultType';
  tags: Array<TagType>;
  totalCount: Scalars['Int'];
};

export type UserEmailsResultType = {
  __typename?: 'UserEmailsResultType';
  total: Scalars['Int'];
  data: Array<Scalars['String']>;
};

export type UserAssignmentType = {
  __typename?: 'UserAssignmentType';
  assigned: Scalars['Int'];
  targeted: Scalars['Int'];
};

export type UserWithAssignmentType = {
  __typename?: 'UserWithAssignmentType';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  roles: Array<UserRole>;
  assignment: UserAssignmentType;
};

export type UserSalesType = {
  __typename?: 'UserSalesType';
  sales: Scalars['Int'];
  targeted: Scalars['Int'];
};

export type UserWithSalesType = {
  __typename?: 'UserWithSalesType';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  roles: Array<UserRole>;
  salesParams: UserSalesType;
};

export type ExpertFilesUrlsType = {
  __typename?: 'ExpertFilesUrlsType';
  wordCloudUrl?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
};

export type UpdateExpertNotificationConsentsResultType = {
  __typename?: 'UpdateExpertNotificationConsentsResultType';
  notificationConsents: ExpertNotificationConsentsType;
};

export type ToggleExpertHiddenJobOrderResultType = {
  __typename?: 'ToggleExpertHiddenJobOrderResultType';
  jobOrderId: Scalars['String'];
  isHidden: Scalars['Boolean'];
};

export type ExpertCvType = {
  __typename?: 'ExpertCvType';
  id: Scalars['String'];
  name: Scalars['String'];
  mimeType: Scalars['String'];
  expertId: Scalars['String'];
  size: Scalars['Int'];
  source: ExpertCvSourceEnum;
  offerId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  createdById?: Maybe<Scalars['String']>;
  status: ExpertCvUploadStatusEnum;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  roleAndLeadingTechnology?: Maybe<Scalars['String']>;
  jobOrderId?: Maybe<Scalars['String']>;
};

export enum ExpertCvSourceEnum {
  Crm = 'CRM',
  External = 'External',
  Hub = 'Hub',
  Matcher = 'Matcher',
}

export enum ExpertCvUploadStatusEnum {
  Pending = 'Pending',
  Success = 'Success',
}

export type MainTechnologyType = {
  __typename?: 'MainTechnologyType';
  tagId: Scalars['String'];
  defaultTagIds?: Maybe<Array<Scalars['String']>>;
};

export type ResolvedTagType = {
  __typename?: 'ResolvedTagType';
  id: Scalars['String'];
  name: Scalars['String'];
  type: TagTypeEnum;
};

export type MainTechnologiesResolvedType = {
  __typename?: 'MainTechnologiesResolvedType';
  tagId: Scalars['String'];
  defaultTagIds?: Maybe<Array<Scalars['String']>>;
  tag: ResolvedTagType;
};

export type AvailableMainTechnologiesResolvedType = {
  __typename?: 'AvailableMainTechnologiesResolvedType';
  name?: Maybe<Scalars['String']>;
  mainTechnologies: Array<MainTechnologiesResolvedType>;
};

export type JobPositionType = {
  __typename?: 'JobPositionType';
  jobPositionId: Scalars['String'];
  name: Scalars['String'];
  availableMainTechnologies: Array<AvailableMainTechnologiesResolvedType>;
  isOnMainList: Scalars['Boolean'];
  order?: Maybe<Scalars['Boolean']>;
};

export type IndustryType = {
  __typename?: 'IndustryType';
  industryId: Scalars['String'];
  name: Scalars['String'];
};

export type ExpertBlindViewHistoryType = {
  __typename?: 'ExpertBlindViewHistoryType';
  timestamp: Scalars['DateTime'];
  userAgent: Scalars['String'];
  ip: Scalars['String'];
};

export type BlindViewHistoryResultType = {
  __typename?: 'BlindViewHistoryResultType';
  history: Array<ExpertBlindViewHistoryType>;
};

export type NewsType = {
  __typename?: 'NewsType';
  newsId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  content: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
};

export type JobOrderApplicationType = {
  __typename?: 'JobOrderApplicationType';
  applicationId: Scalars['String'];
  jobOrderId: Scalars['String'];
  expertId: Scalars['String'];
  createdAt: Scalars['String'];
  meetingDates: Array<Scalars['String']>;
  handlerId: Scalars['String'];
  handlingExpiresAt?: Maybe<Scalars['String']>;
  handlingConfirmedAt?: Maybe<Scalars['String']>;
  status: JobOrderApplicationStatusEnum;
  selectedMeetingDate?: Maybe<Scalars['String']>;
  processStatus: JobOrderApplicationProcessStatusEnum;
  meetingUrl?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  processStatusChangedAt?: Maybe<Scalars['DateTime']>;
};

export enum JobOrderApplicationStatusEnum {
  WaitingForConfirmation = 'WaitingForConfirmation',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
}

export enum JobOrderApplicationProcessStatusEnum {
  Applied = 'Applied',
  ScheduledForCq = 'ScheduledForCQ',
  AcceptedAfterCq = 'AcceptedAfterCQ',
  RejectedAfterApplied = 'RejectedAfterApplied',
  RejectedAfterCq = 'RejectedAfterCQ',
  NotSent = 'NotSent',
  Sent = 'Sent',
  MeetingsScheduled = 'MeetingsScheduled',
  MeetingsCancelled = 'MeetingsCancelled',
  CiCancelledByExpert = 'CICancelledByExpert',
  CiCancelledByClient = 'CICancelledByClient',
  CiScheduled = 'CIScheduled',
  CiFinished = 'CIFinished',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  RejectedAfterCi = 'RejectedAfterCI',
  RejectedOffer = 'RejectedOffer',
  NoFeedback = 'NoFeedback',
  ExpertWithdrew = 'ExpertWithdrew',
  ClientWithdrew = 'ClientWithdrew',
  Placement = 'Placement',
  CiScheduledSecondStage = 'CIScheduledSecondStage',
  CiFinishedSecondStage = 'CIFinishedSecondStage',
  CiScheduledThirdStage = 'CIScheduledThirdStage',
  CiFinishedThirdStage = 'CIFinishedThirdStage',
  RejectedBySales = 'RejectedBySales',
  OfferExpired = 'OfferExpired',
  RejectedByExpert = 'RejectedByExpert',
}

export type JobOrderApplicationWithScoreType = {
  __typename?: 'JobOrderApplicationWithScoreType';
  applicationId: Scalars['String'];
  jobOrderId: Scalars['String'];
  expertId: Scalars['String'];
  createdAt: Scalars['String'];
  meetingDates: Array<Scalars['String']>;
  handlerId: Scalars['String'];
  handlingExpiresAt?: Maybe<Scalars['String']>;
  handlingConfirmedAt?: Maybe<Scalars['String']>;
  status: JobOrderApplicationStatusEnum;
  selectedMeetingDate?: Maybe<Scalars['String']>;
  processStatus: JobOrderApplicationProcessStatusEnum;
  meetingUrl?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  processStatusChangedAt?: Maybe<Scalars['DateTime']>;
  score: CalculateScoreResultType;
  jobOrder?: Maybe<JobOrderType>;
  expert?: Maybe<ExpertType>;
  handler?: Maybe<UserType>;
  isHubBlindCreated: Scalars['Boolean'];
};

export type ExpertApplicationsResultType = {
  __typename?: 'ExpertApplicationsResultType';
  applicationId: Scalars['String'];
  jobOrderId: Scalars['String'];
  expertId: Scalars['String'];
  createdAt: Scalars['String'];
  meetingDates: Array<Scalars['String']>;
  handlerId: Scalars['String'];
  handlingExpiresAt?: Maybe<Scalars['String']>;
  handlingConfirmedAt?: Maybe<Scalars['String']>;
  status: JobOrderApplicationStatusEnum;
  selectedMeetingDate?: Maybe<Scalars['String']>;
  processStatus: JobOrderApplicationProcessStatusEnum;
  meetingUrl?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  processStatusChangedAt?: Maybe<Scalars['DateTime']>;
  score: CalculateScoreResultType;
  jobOrder: JobOrderType;
  expert?: Maybe<ExpertType>;
  handler?: Maybe<UserType>;
  isHubBlindCreated: Scalars['Boolean'];
};

export type InfoType = {
  __typename?: 'InfoType';
  timestamp: Scalars['String'];
  version: Scalars['String'];
};

export type JobOrderExistsResultType = {
  __typename?: 'JobOrderExistsResultType';
  status: Scalars['String'];
  content?: Maybe<JobOrderWithScoreType>;
};

export type ExpertSearchResultType = {
  __typename?: 'ExpertSearchResultType';
  overallCount: Scalars['Int'];
  experts: Array<ExpertType>;
};

export type UpdateJobOrderApplicationResultType = {
  __typename?: 'UpdateJobOrderApplicationResultType';
  application: JobOrderApplicationType;
};

export type CandidatesResultType = {
  __typename?: 'CandidatesResultType';
  total: Scalars['Int'];
  data: Array<JobOrderApplicationWithScoreType>;
};

export type ExpertPubnubAccessDetailsType = {
  __typename?: 'ExpertPubnubAccessDetailsType';
  token: Scalars['String'];
  channel: Scalars['String'];
  uuid: Scalars['String'];
  expiresAt: Scalars['DateTime'];
};

export type ExpertNotificationRelationsType = {
  __typename?: 'ExpertNotificationRelationsType';
  jobOrderId?: Maybe<Scalars['String']>;
  jobOrderApplicationId?: Maybe<Scalars['String']>;
};

export type ExpertNotificationType = {
  __typename?: 'ExpertNotificationType';
  id: Scalars['String'];
  type: ExpertNotificationTypeEnum;
  expertId: Scalars['String'];
  relatesTo: ExpertNotificationRelationsType;
  metadata?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  viewedAt?: Maybe<Scalars['DateTime']>;
};

export enum ExpertNotificationTypeEnum {
  JobOrderMatched = 'JobOrderMatched',
  JobOrderApplicationMeetingScheduled = 'JobOrderApplicationMeetingScheduled',
  JobOrderApplicationStatusChange = 'JobOrderApplicationStatusChange',
}

export type ExpertNotificationsQueryResultType = {
  __typename?: 'ExpertNotificationsQueryResultType';
  notifications: Array<ExpertNotificationType>;
};

export type ExpertIdentityType = {
  __typename?: 'ExpertIdentityType';
  source: ExpertIdentitySourceEnum;
  externalId: Scalars['String'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

export enum ExpertIdentitySourceEnum {
  Cognito = 'Cognito',
  Google = 'Google',
  LinkedIn = 'LinkedIn',
}

export type PublicOfferType = {
  __typename?: 'PublicOfferType';
  id: Scalars['String'];
  jobOrderId: Scalars['String'];
  ownerId: Scalars['String'];
  status: PublicOfferStatusEnum;
  description: Scalars['String'];
  createdById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  isRateDisplayed: Scalars['Boolean'];
  context: PublicOfferContextEnum;
  contextContactEmail?: Maybe<Scalars['String']>;
};

export enum PublicOfferStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}

export enum PublicOfferContextEnum {
  Marketing = 'Marketing',
  JustJoinIt = 'JustJoinIT',
  JobBoard = 'JobBoard',
  NoFluffJobs = 'NoFluffJobs',
  LinkedIn = 'LinkedIn',
  Other = 'Other',
  Process = 'Process',
}

export type PublicOfferEmailType = {
  __typename?: 'PublicOfferEmailType';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  offerId: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
  to: Scalars['String'];
  createdById: Scalars['String'];
};

export type PublicOfferWithJobOrderType = {
  __typename?: 'PublicOfferWithJobOrderType';
  id: Scalars['String'];
  jobOrderId: Scalars['String'];
  ownerId: Scalars['String'];
  status: PublicOfferStatusEnum;
  description: Scalars['String'];
  createdById: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  isRateDisplayed: Scalars['Boolean'];
  context: PublicOfferContextEnum;
  contextContactEmail?: Maybe<Scalars['String']>;
  jobOrder?: Maybe<JobOrderType>;
  sentTo: Array<PublicOfferEmailType>;
  createdBy?: Maybe<CreatedByUserType>;
  owner: CreatedByUserType;
  backoffice: Array<PublicOfferEventType>;
};

export type PublicOffersQueryResultType = {
  __typename?: 'PublicOffersQueryResultType';
  overallCount: Scalars['Int'];
  offers: Array<PublicOfferWithJobOrderType>;
};

export type PublicOfferEventType = {
  __typename?: 'PublicOfferEventType';
  id: Scalars['String'];
  offerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  expertId?: Maybe<Scalars['String']>;
  type: PublicOfferEventTypeEnum;
  userAgent: Scalars['String'];
  ip: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export enum PublicOfferEventTypeEnum {
  Open = 'Open',
  Apply = 'Apply',
  Login = 'Login',
}

export type PublicOfferApplicationType = {
  __typename?: 'PublicOfferApplicationType';
  id: Scalars['String'];
  offerId: Scalars['String'];
  cvFileId: Scalars['String'];
  expertId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PublicOfferApplicationsQueryResultType = {
  __typename?: 'PublicOfferApplicationsQueryResultType';
  overallCount: Scalars['Int'];
  applications: Array<PublicOfferApplicationType>;
};

export type JobOrderPublicationType = {
  __typename?: 'JobOrderPublicationType';
  id: Scalars['String'];
  mediumId: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type JobOrderPublicationMediumType = {
  __typename?: 'JobOrderPublicationMediumType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SignExpertCvUploadCredentialsResultType = {
  __typename?: 'SignExpertCvUploadCredentialsResultType';
  fields: Array<Array<Scalars['String']>>;
  url: Scalars['String'];
  fileId: Scalars['String'];
  expertId: Scalars['String'];
};

export type SimpleReportValueRowType = {
  __typename?: 'SimpleReportValueRowType';
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type HubExpertsStatisticsType = {
  __typename?: 'HubExpertsStatisticsType';
  signedUpCount: Scalars['Int'];
  signedUpWithFinishedOnboardingCount: Scalars['Int'];
  onlyProspectsCount: Scalars['Int'];
};

export type ExpertWithScoreType = {
  __typename?: 'ExpertWithScoreType';
  expertId: Scalars['String'];
  crmId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crmCreatedAt?: Maybe<Scalars['DateTime']>;
  hubCreatedAt?: Maybe<Scalars['DateTime']>;
  lastNoteCreatedAt?: Maybe<Scalars['DateTime']>;
  tags: Array<ExpertTagType>;
  jobPositionName?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  jobPositionId?: Maybe<Scalars['String']>;
  mainTechnologyIds?: Maybe<Array<Scalars['String']>>;
  experienceYears?: Maybe<Scalars['Float']>;
  rateMin?: Maybe<Scalars['Float']>;
  rateMax?: Maybe<Scalars['Float']>;
  rateDesired?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  locationObject?: Maybe<GoogleLocationObject>;
  desiredOfficeLocations?: Maybe<Array<Scalars['String']>>;
  desiredTechnicalSkills?: Maybe<Array<Scalars['String']>>;
  desiredIndustries?: Maybe<Array<Scalars['String']>>;
  desiredFields?: Maybe<Array<Scalars['String']>>;
  workType?: Maybe<WorkModeEnum>;
  seniority?: Maybe<SeniorityLevelEnum>;
  languages?: Maybe<Array<ExpertLanguageType>>;
  leadingTechnologies?: Maybe<Array<TagType>>;
  tagsVisibleOnBlind?: Maybe<Array<Scalars['String']>>;
  tagsSuperPowers?: Maybe<Array<Scalars['String']>>;
  recommendation?: Maybe<Scalars['String']>;
  englishRecommendation?: Maybe<Scalars['String']>;
  certificates: Array<ExpertCertificateType>;
  workHistory: Array<ExpertWorkHistoryType>;
  availability?: Maybe<ExpertAvailabilityEnum>;
  noticeLength?: Maybe<Scalars['Int']>;
  isEmployedIts?: Maybe<Scalars['Boolean']>;
  manuallyAddedJobPositions: Array<Scalars['String']>;
  manuallyAddedMainTechnologies: Array<Scalars['String']>;
  manuallyAddedDesiredTechnicalSkills: Array<Scalars['String']>;
  avatar?: Maybe<ExpertAvatarEnum>;
  photoFileId?: Maybe<Scalars['String']>;
  wordCloudFileId?: Maybe<Scalars['String']>;
  savedJobOrderIds?: Maybe<Array<Scalars['String']>>;
  hiddenJobOrderIds?: Maybe<Array<Scalars['String']>>;
  isOnboardingFinished: Scalars['Boolean'];
  isCertificatesSkipped: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  notificationConsents: ExpertNotificationConsentsType;
  isVerified: Scalars['Boolean'];
  isVerifiedNotificationDisplayed: Scalars['Boolean'];
  dataSource: Array<ExpertDataSourceEnum>;
  githubUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  descriptiveLabel?: Maybe<Scalars['String']>;
  cvId?: Maybe<Scalars['String']>;
  crmDynamicsId?: Maybe<Scalars['String']>;
  jobOrderApplicationsCount: Scalars['Int'];
  fileUrls: ExpertFilesUrlsType;
  cv?: Maybe<ExpertCvType>;
  score: CalculateScoreResultType;
  matchedJobOrderId: Scalars['String'];
  hubNotifiedAt?: Maybe<Scalars['DateTime']>;
};

export type MatchExpertsToJobOrderResultType = {
  __typename?: 'MatchExpertsToJobOrderResultType';
  experts: Array<ExpertWithScoreType>;
  total: Scalars['Int'];
};

export type ExpertCertificateInputType = {
  name: Scalars['String'];
  issuer?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  englishDescription?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
};

export type ExpertWorkHistoryInputType = {
  jobPositionName?: InputMaybe<Scalars['String']>;
  englishJobPositionName?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  englishIndustry?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  dateStart: Scalars['DateTime'];
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  isCurrentJob?: InputMaybe<Scalars['Boolean']>;
  isCompanyNameSkipped?: InputMaybe<Scalars['Boolean']>;
  tagsIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  englishDescription?: InputMaybe<Scalars['String']>;
};

export type TermInput = {
  offset: Scalars['Int'];
  value: Scalars['String'];
};

export type MatchedSubstringInput = {
  offset: Scalars['Int'];
  length: Scalars['Int'];
};

export type StructuredFormattingInput = {
  main_text: Scalars['String'];
  secondary_text: Scalars['String'];
  main_text_matched_substrings: Array<MatchedSubstringInput>;
};

export type GoogleLocationObjectInput = {
  description: Scalars['String'];
  matched_substrings: Array<MatchedSubstringInput>;
  place_id: Scalars['String'];
  reference: Scalars['String'];
  structured_formatting: StructuredFormattingInput;
  terms: Array<TermInput>;
  types: Array<Scalars['String']>;
};

export type ExpertNotificationConsentsInputType = {
  OffersAndProfiles: Scalars['Boolean'];
  ApplicationAndMeetings: Scalars['Boolean'];
  News: Scalars['Boolean'];
};

export type JobOrderAssigneesParamsInputType = {
  brm?: InputMaybe<Scalars['String']>;
  talentAdvocates?: InputMaybe<Array<Scalars['String']>>;
};

export type JobOrderStatusHistoryInputType = {
  statusType: JobOrderStatusTypeEnum;
  changedFrom?: InputMaybe<JobOrderStatusMergeEnum>;
  changedTo: JobOrderStatusMergeEnum;
  changeDate: Scalars['DateTime'];
  changedById: Scalars['String'];
  resultStatus?: InputMaybe<JobOrderResultEnum>;
  comments?: InputMaybe<Scalars['String']>;
  isReverted: Scalars['Boolean'];
};

export type JobOrderBusinessOwnerInputType = {
  id: Scalars['String'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type JobOrderAssignmentHistoryInputType = {
  assignmentDate: Scalars['DateTime'];
  changedById: Scalars['String'];
  owner: Scalars['String'];
  assignees: Array<JobOrderAssigneesParamsInputType>;
  isBttlReset: Scalars['Boolean'];
  isReverted: Scalars['Boolean'];
};

export type MainTechnologyInputType = {
  tagId: Scalars['String'];
  defaultTagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type JobOrderPublicationMediumInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  hubSpotCompanies: Array<HubSpotCompanyType>;
  hubSpotCompanyContacts: Array<HubSpotContactType>;
  industries: Array<IndustryType>;
  jobPositions: Array<JobPositionType>;
  contactPeople: ContactPeopleQueryResultType;
  getBlind: ExpertBlindType;
  blinds: BlindsQueryResultType;
  jobOrderBlinds: BlindsQueryResultType;
  blind: ExpertBlindType;
  myBlinds: BlindsQueryResultType;
  adminBlinds: BlindsQueryResultType;
  blindsReport: BlindsReportQueryResultType;
  matchJobOrderToExpertWithCustomSkills: CalculateScoreResultType;
  expertBlindApplicationDetails: ExpertBlindApplicationDetailsResultType;
  expert: ExpertType;
  chubExperts: ExpertsQueryResultType;
  jobOrder: JobOrderType;
  jobOrderSearchPhrase: JobOrderSearchPhraseResultType;
  jobOrderSearch: JobOrderSearchResultType;
  myJobOrders: JobOrderResultType;
  planningJobOrders: JobOrderResultType;
  planningJobOrdersToExport: Array<JobOrderType>;
  matchJobOrdersToExpert: Array<JobOrderWithScoreType>;
  matchJobOrderToExpert: CalculateScoreResultType;
  languages: Array<LanguageType>;
  tags: Array<TagType>;
  tagsWithUsage: TagQueryResultType;
  me: UserType;
  user: UserType;
  usersEmails: UserEmailsResultType;
  users: Array<UserType>;
  usersWithSales: Array<UserWithSalesType>;
  usersWithAssignment: Array<UserWithAssignmentType>;
  myProfile: ExpertType;
  checkExpertVerifyStatus: Scalars['Boolean'];
  blindViewHistory: BlindViewHistoryResultType;
  news: Array<NewsType>;
  expertJobOrderApplications: Array<ExpertApplicationsResultType>;
  recommendedJobOrders: Array<JobOrderWithScoreType>;
  jobOrdersUnderMinScoreToApply: Array<JobOrderWithScoreType>;
  publicOfferAppliedJobOrders: Array<JobOrderWithScoreType>;
  activeApplicationJobOrders: Array<JobOrderWithScoreType>;
  endedApplicationJobOrders: Array<JobOrderWithScoreType>;
  savedJobOrders: Array<JobOrderWithScoreType>;
  hiddenJobOrders: Array<JobOrderWithScoreType>;
  checkIfOfferExists: JobOrderExistsResultType;
  info: InfoType;
  errorTest: Scalars['String'];
  searchExpertByAnnotations: ExpertSearchResultType;
  searchExpertByData: ExpertSearchResultType;
  myCandidates: CandidatesResultType;
  candidates: CandidatesResultType;
  expertNotifications: ExpertNotificationsQueryResultType;
  isExpertIdentityVerified: Scalars['Boolean'];
  currentExpertIdentity: ExpertIdentityType;
  publicOffer: PublicOfferWithJobOrderType;
  publicOffers: PublicOffersQueryResultType;
  publicOfferApplications: PublicOfferApplicationsQueryResultType;
  jobOrderPublicationAvailableMediums: Array<JobOrderPublicationMediumType>;
  expertCvs: Array<ExpertCvType>;
  hubExpertsStatistics: HubExpertsStatisticsType;
  lastWeekHubSignUpsReport: Array<SimpleReportValueRowType>;
  lastSixMonthsHubSignUpsReport: Array<SimpleReportValueRowType>;
  accumulatedHubSignUpsReport: Array<SimpleReportValueRowType>;
  lastWeekJobOrderApplicationsReport: Array<SimpleReportValueRowType>;
  lastSixMonthsJobOrderApplicationsReport: Array<SimpleReportValueRowType>;
  accumulatedJobOrderApplicationsReport: Array<SimpleReportValueRowType>;
  lastWeekOfferPublicationsReport: Array<SimpleReportValueRowType>;
  lastSixMonthsOfferPublicationsReport: Array<SimpleReportValueRowType>;
  accumulatedOfferPublicationsReport: Array<SimpleReportValueRowType>;
  matchExpertsToJobOrder: MatchExpertsToJobOrderResultType;
  jobOrderExpertsToNotifyCount: Scalars['Int'];
};

export type QueryHubSpotCompaniesArgs = {
  searchPhrase: Scalars['String'];
};

export type QueryHubSpotCompanyContactsArgs = {
  companyId: Scalars['String'];
};

export type QueryGetBlindArgs = {
  blindId: Scalars['String'];
};

export type QueryBlindsArgs = {
  expertId: Scalars['String'];
};

export type QueryJobOrderBlindsArgs = {
  jobOrderId: Scalars['String'];
};

export type QueryBlindArgs = {
  blindId: Scalars['String'];
};

export type QueryMyBlindsArgs = {
  pagination?: InputMaybe<ExpertBlindPaginationInput>;
  filters?: InputMaybe<BlindsFiltersInputType>;
  sortBy?: InputMaybe<BlindsSortInputType>;
};

export type QueryAdminBlindsArgs = {
  pagination?: InputMaybe<ExpertBlindPaginationInput>;
  filters?: InputMaybe<BlindsFiltersInputType>;
  sortBy?: InputMaybe<BlindsSortInputType>;
};

export type QueryMatchJobOrderToExpertWithCustomSkillsArgs = {
  params: MatchJobOrderToExpertWithCustomSkillsInputType;
};

export type QueryExpertBlindApplicationDetailsArgs = {
  expertId: Scalars['String'];
  jobOrderId: Scalars['String'];
};

export type QueryExpertArgs = {
  expertId: Scalars['String'];
};

export type QueryChubExpertsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  searchPhrase?: InputMaybe<Scalars['String']>;
};

export type QueryJobOrderArgs = {
  jobOrderId?: InputMaybe<Scalars['String']>;
  customId?: InputMaybe<Scalars['String']>;
};

export type QueryJobOrderSearchPhraseArgs = {
  jobOrderId: Scalars['String'];
};

export type QueryJobOrderSearchArgs = {
  searchPhrase: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  awaitForTagInsert?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMyJobOrdersArgs = {
  pagination: FindManyJobOrdersPaginationInput;
  filters?: InputMaybe<JobOrdersFiltersInputType>;
  sortBy?: InputMaybe<FindManyJobOrdersSortByInputType>;
};

export type QueryPlanningJobOrdersArgs = {
  pagination?: InputMaybe<FindManyJobOrdersPaginationInput>;
  filters?: InputMaybe<JobOrdersFiltersInputType>;
  sortBy?: InputMaybe<FindManyJobOrdersSortByInputType>;
};

export type QueryMatchJobOrdersToExpertArgs = {
  expertId: Scalars['String'];
  filters?: InputMaybe<JobOrdersFiltersInputType>;
  sortByScore?: InputMaybe<MatchJobOrdersSortByInputType>;
  sortByForMatcher?: InputMaybe<FindManyJobOrdersSortByInputType>;
};

export type QueryMatchJobOrderToExpertArgs = {
  expertId: Scalars['String'];
  jobOrderId: Scalars['String'];
};

export type QueryTagsArgs = {
  filters?: InputMaybe<TagsQueryFiltersType>;
};

export type QueryTagsWithUsageArgs = {
  filters?: InputMaybe<TagsQueryFiltersType>;
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryBlindViewHistoryArgs = {
  blindId: Scalars['String'];
};

export type QueryNewsArgs = {
  limit: Scalars['Int'];
};

export type QueryExpertJobOrderApplicationsArgs = {
  expertId: Scalars['String'];
};

export type QueryRecommendedJobOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryJobOrdersUnderMinScoreToApplyArgs = {
  params: OtherJobOrdersQueryInputType;
};

export type QueryCheckIfOfferExistsArgs = {
  id: Scalars['String'];
};

export type QuerySearchExpertByAnnotationsArgs = {
  searchPhrase: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  insertSearchLog?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchExpertByDataArgs = {
  searchPhrase: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryMyCandidatesArgs = {
  pagination?: InputMaybe<JobOrderApplicationPaginationInput>;
  filters?: InputMaybe<CandidatesFiltersInputType>;
};

export type QueryCandidatesArgs = {
  pagination?: InputMaybe<JobOrderApplicationPaginationInput>;
  filters?: InputMaybe<CandidatesFiltersInputType>;
};

export type QueryExpertNotificationsArgs = {
  sort?: InputMaybe<ExpertNotificationSortEnum>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPublicOfferArgs = {
  offerId: Scalars['String'];
  shouldCreateEvent?: InputMaybe<Scalars['Boolean']>;
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPublicOffersArgs = {
  filters?: InputMaybe<PublicOffersFiltersInputType>;
  sort?: InputMaybe<PublicOfferSortInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPublicOfferApplicationsArgs = {
  filters?: InputMaybe<PublicOfferApplicationsFiltersInputType>;
  sort?: InputMaybe<PublicOfferApplicationsSortInputType>;
  limit?: InputMaybe<Scalars['Float']>;
  skip?: InputMaybe<Scalars['Float']>;
};

export type QueryExpertCvsArgs = {
  expertId: Scalars['String'];
};

export type QueryMatchExpertsToJobOrderArgs = {
  jobOrderId: Scalars['String'];
  sortBy?: InputMaybe<MatchExpertsToJobOrderInputType>;
  filters?: InputMaybe<ExpertJobOrderFiltersInputType>;
  pagination?: InputMaybe<ExpertJobOrderPaginationInput>;
};

export type QueryJobOrderExpertsToNotifyCountArgs = {
  jobOrderId: Scalars['String'];
};

export type ExpertBlindPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type BlindsFiltersInputType = {
  jobOrderCustomId?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  createdByName?: InputMaybe<Scalars['String']>;
  crmId?: InputMaybe<Scalars['String']>;
  expertEmploymentStatus?: InputMaybe<Scalars['String']>;
  expertName?: InputMaybe<Scalars['String']>;
  flowStatus?: InputMaybe<Scalars['String']>;
  jobOrderName?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  profileSource?: InputMaybe<Array<ProfileSourceEnum>>;
};

export type BlindsSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type MatchJobOrderToExpertWithCustomSkillsInputType = {
  jobOrderId: Scalars['String'];
  expertId: Scalars['String'];
  blindTags?: InputMaybe<Array<ExpertTagWeightInputType>>;
};

export type ExpertTagWeightInputType = {
  tagId: Scalars['String'];
  weight: Scalars['Int'];
};

export type FindManyJobOrdersPaginationInput = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type JobOrdersFiltersInputType = {
  customId?: InputMaybe<Scalars['Int']>;
  experienceYears?: InputMaybe<NullableIntRangeType>;
  rate?: InputMaybe<NullableFloatRangeType>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  businessOwner?: InputMaybe<Scalars['String']>;
  businessStatus?: InputMaybe<JobOrderBusinessStatusEnum>;
  definitionOfReadyStatus?: InputMaybe<Array<JobOrderDefinitionOfReadyStatusEnum>>;
  skillsMustHave?: InputMaybe<Array<Scalars['String']>>;
  skillsNiceToHave?: InputMaybe<Array<Scalars['String']>>;
  createdByEmail?: InputMaybe<Array<Scalars['String']>>;
  assignee?: InputMaybe<Array<Scalars['String']>>;
  owner?: InputMaybe<Array<Scalars['String']>>;
  isAssigned?: InputMaybe<Scalars['Boolean']>;
  fillRateKpi?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  roleName?: InputMaybe<Array<Scalars['String']>>;
  leadingTechnologies?: InputMaybe<Array<Scalars['String']>>;
  seniority?: InputMaybe<Array<SeniorityLevelEnum>>;
  workMode?: InputMaybe<Array<WorkModeEnum>>;
  locationPreference?: InputMaybe<Scalars['String']>;
  minEnglishLevel?: InputMaybe<Array<LanguageLevelEnum>>;
  dateStart?: InputMaybe<DateRangeType>;
  assignmentDate?: InputMaybe<DateRangeType>;
  createdAt?: InputMaybe<DateRangeType>;
  submittedForEvaluationDate?: InputMaybe<DateRangeType>;
  acceptanceDate?: InputMaybe<DateRangeType>;
  isChubVisible?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<JobOrderTypeEnum>;
  publications?: InputMaybe<Array<JobOrderPublicationMediumEnum>>;
  finalScore?: InputMaybe<NullableIntRangeType>;
  summarySentAt?: InputMaybe<DateRangeType>;
};

export type NullableIntRangeType = {
  min?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['Int']>;
};

export type NullableFloatRangeType = {
  min?: InputMaybe<Scalars['Float']>;
  max?: InputMaybe<Scalars['Float']>;
};

export type DateRangeType = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export enum JobOrderPublicationMediumEnum {
  ConnectisWww = 'CONNECTIS_WWW',
  JustJoinIt = 'JUST_JOIN_IT',
  NoFluffJobs = 'NO_FLUFF_JOBS',
  Jobslot = 'JOBSLOT',
}

export type FindManyJobOrdersSortByInputType = {
  field: FindManyJobOrderSortByEnum;
  sort?: InputMaybe<FindManySortTypeEnum>;
};

export enum FindManyJobOrderSortByEnum {
  ClientName = 'ClientName',
  Name = 'Name',
  Role = 'Role',
  Rate = 'Rate',
  Availability = 'Availability',
  CreatedAt = 'CreatedAt',
  BusinessOwner = 'BusinessOwner',
  BusinessStatus = 'BusinessStatus',
  Seniority = 'Seniority',
  WorkMode = 'WorkMode',
  MinEnglishLevel = 'MinEnglishLevel',
  SubmittedForEvaluationDate = 'SubmittedForEvaluationDate',
  AcceptanceDate = 'AcceptanceDate',
  AssignmentDate = 'AssignmentDate',
  DateStart1 = 'DateStart1',
  Bttl = 'Bttl',
  JobOrderScore = 'JobOrderScore',
  FinalScore = 'FinalScore',
  ExperienceYears = 'ExperienceYears',
  RateRangeTo = 'RateRangeTo',
  Vacancies = 'Vacancies',
  CreatedByEmail = 'CreatedByEmail',
  Owner = 'Owner',
  DefinitionOfReadyStatus = 'DefinitionOfReadyStatus',
}

export enum FindManySortTypeEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type MatchJobOrdersSortByInputType = {
  field: MatchJobOrdersSortByEnum;
  sort?: InputMaybe<FindManySortTypeEnum>;
};

export enum MatchJobOrdersSortByEnum {
  MustHaveSkillsScore = 'MustHaveSkillsScore',
  NiceToHaveSkillsScore = 'NiceToHaveSkillsScore',
  SalaryScore = 'SalaryScore',
  WorkModeScore = 'WorkModeScore',
  EnglishLevelScore = 'EnglishLevelScore',
  AvailabilityScore = 'AvailabilityScore',
  FinalScore = 'FinalScore',
  MinimalSkillLevel = 'MinimalSkillLevel',
}

export type TagsQueryFiltersType = {
  type?: InputMaybe<TagTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type OtherJobOrdersQueryInputType = {
  sortBy?: InputMaybe<JobOrdersQuerySortByEnum>;
  limit?: InputMaybe<Scalars['Int']>;
};

export enum JobOrdersQuerySortByEnum {
  BestMatch = 'bestMatch',
  CreatedAtDesc = 'createdAtDesc',
  CreatedAtAsc = 'createdAtAsc',
  AssignmentDateDesc = 'assignmentDateDesc',
  AssignmentDateAsc = 'assignmentDateAsc',
  RateDesc = 'rateDesc',
  RateAsc = 'rateAsc',
}

export type JobOrderApplicationPaginationInput = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type CandidatesFiltersInputType = {
  name?: InputMaybe<Scalars['String']>;
  handlingConfirmedAt?: InputMaybe<DateRangeType>;
  handlingExpiresAt?: InputMaybe<DateRangeType>;
  createdAt?: InputMaybe<DateRangeType>;
  customId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<JobOrderApplicationStatusEnum>>;
  processStatus?: InputMaybe<Array<JobOrderApplicationProcessStatusEnum>>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  handlerId?: InputMaybe<Scalars['String']>;
};

export enum ExpertNotificationSortEnum {
  UnviewedFirst = 'UnviewedFirst',
  CreatedAtDesc = 'CreatedAtDesc',
  CreatedAtAsc = 'CreatedAtAsc',
}

export type PublicOffersFiltersInputType = {
  jobOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PublicOfferStatusEnum>;
  crmId?: InputMaybe<Scalars['Int']>;
  client?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  businessStatus?: InputMaybe<Array<JobOrderBusinessStatusEnum>>;
  definitionOfReadyStatus?: InputMaybe<Array<JobOrderDefinitionOfReadyStatusEnum>>;
  ownerIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateRangeType>;
  context?: InputMaybe<PublicOfferContextEnum>;
  contextContactEmail?: InputMaybe<Scalars['String']>;
  sentTo?: InputMaybe<Scalars['String']>;
};

export type PublicOfferSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type PublicOfferApplicationsFiltersInputType = {
  offerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PublicOfferApplicationStatusEnum>;
};

export enum PublicOfferApplicationStatusEnum {
  New = 'New',
}

export type PublicOfferApplicationsSortInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type MatchExpertsToJobOrderInputType = {
  field: Scalars['String'];
  direction: Scalars['Int'];
};

export type ExpertJobOrderFiltersInputType = {
  name?: InputMaybe<Scalars['String']>;
  locationPreference?: InputMaybe<Scalars['String']>;
  crmId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<DateRangeType>;
  crmCreatedAt?: InputMaybe<DateRangeType>;
  hubCreatedAt?: InputMaybe<DateRangeType>;
  lastNoteCreatedAt?: InputMaybe<DateRangeType>;
  businessStatus?: InputMaybe<JobOrderBusinessStatusEnum>;
  finalScore?: InputMaybe<NullableIntRangeType>;
  rate?: InputMaybe<NullableFloatRangeType>;
  email?: InputMaybe<Scalars['String']>;
  skillsMustHave?: InputMaybe<Array<Scalars['String']>>;
  englishLevel?: InputMaybe<Array<Scalars['String']>>;
  availability?: InputMaybe<Array<Scalars['String']>>;
  skillsNiceToHave?: InputMaybe<Array<Scalars['String']>>;
  leadingTechnologies?: InputMaybe<Array<Scalars['String']>>;
  roleName?: InputMaybe<Array<Scalars['String']>>;
  workType?: InputMaybe<Array<Scalars['String']>>;
  dataSource?: InputMaybe<Array<ExpertDataSourceEnum>>;
};

export type ExpertJobOrderPaginationInput = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  sendBlindNotificationEmail: Scalars['String'];
  insertIndustry: IndustryType;
  updateIndustry: IndustryType;
  deleteIndustry: Scalars['String'];
  insertJobPosition: JobPositionType;
  updateJobPosition: JobPositionType;
  deleteJobPosition: Scalars['String'];
  createBlind: CreateBlindResultType;
  updateBlind: UpdateBlindResultType;
  removeBlind: Scalars['String'];
  updateBlindFlowStatus: ExpertBlindType;
  revertBlindFlowStatus: ExpertBlindType;
  updateExpert: ExpertType;
  deleteExpertTag: Scalars['String'];
  updateJobOrder: JobOrderType;
  sendJobOrderSummaryEmail: Scalars['String'];
  updateJobOrderAssignment: JobOrderType;
  deleteJobOrderTag: Scalars['String'];
  deleteJobOrderSkillMustHave: Scalars['String'];
  deleteJobOrderSkillNiceToHave: Scalars['String'];
  revertLastJobOrderStatusHistoryRecord: JobOrderType;
  revertLastJobOrderAssignmentHistoryRecord: Array<JobOrderAssignmentHistoryType>;
  recalculateJobOrderFillRate: JobOrderFillRateParamsType;
  insertLanguage: LanguageType;
  deleteLanguage: Scalars['String'];
  deleteTag: Scalars['String'];
  deleteTags: Scalars['String'];
  updateTag: Scalars['String'];
  updateTags: Scalars['String'];
  updateUserRoles: UserType;
  createExpertIdentity: Scalars['String'];
  resendEmailVerification: Scalars['String'];
  completeEmailVerification: Scalars['DateTime'];
  updateExpertProfile: ExpertType;
  uploadExpertPhoto: Scalars['String'];
  uploadExpertWordCloud: Scalars['String'];
  toggleExpertSavedJobOrder: Array<Scalars['String']>;
  toggleExpertHiddenJobOrder: ToggleExpertHiddenJobOrderResultType;
  finishOnboarding: ExpertType;
  updateExpertNotificationConsents: UpdateExpertNotificationConsentsResultType;
  deleteExpertAccount: Scalars['String'];
  migrateExpertBlindsViewHistoryToMongoDb: Scalars['String'];
  createBlindViewHistory: Scalars['String'];
  insertNews: NewsType;
  updateNews: Scalars['String'];
  deleteNews: Scalars['String'];
  applyForJobOrder: JobOrderApplicationWithScoreType;
  confirmApplicationHanding: Scalars['String'];
  cancelApplication: Scalars['String'];
  updateJobOrderApplication: UpdateJobOrderApplicationResultType;
  changeApplicationStatus: JobOrderApplicationWithScoreType;
  changeApplicationHandler: Scalars['String'];
  synchronizeCRM: Scalars['String'];
  synchronizeJobOrderWithCRM: Scalars['String'];
  addQueueJob: Scalars['String'];
  createExpertPubnubAccessDetails: ExpertPubnubAccessDetailsType;
  markExpertNotificationAsViewed: ExpertNotificationType;
  resendOtp: Scalars['String'];
  startPasswordReset: Scalars['String'];
  verifyPasswordResetOtp: Scalars['Boolean'];
  createPublicOffer: PublicOfferType;
  updatePublicOffer: PublicOfferType;
  updatePublicOfferStatus: PublicOfferType;
  deletePublicOffer: Scalars['String'];
  registerExpertLoginFromPublicOffer: Scalars['String'];
  applyForPublicOffer: PublicOfferApplicationType;
  sendPublicOfferEmail: Scalars['String'];
  createJobOrderPublication: JobOrderPublicationType;
  updateJobOrderPublication: JobOrderPublicationType;
  updateJobOrderPublications: Array<JobOrderPublicationType>;
  deleteJobOrderPublication: Scalars['String'];
  setCognitoPassword: Scalars['String'];
  sendGenericPurposeOtp: Scalars['Int'];
  verifyGenericPurposeOtp: Scalars['Boolean'];
  signExpertCvUploadCredentials: SignExpertCvUploadCredentialsResultType;
  signExpertCvUploadCredentialsWithRecaptcha: SignExpertCvUploadCredentialsResultType;
  completeExpertCvUpload: Scalars['String'];
  deleteExpertCv: Scalars['String'];
  sendAllBlindsReport: Scalars['String'];
  notifyAboutMatchedJobOrder: Scalars['Int'];
};

export type MutationSendBlindNotificationEmailArgs = {
  input: SendBlindNotificationEmailInputType;
};

export type MutationInsertIndustryArgs = {
  name: Scalars['String'];
};

export type MutationUpdateIndustryArgs = {
  industryId: Scalars['String'];
  name: Scalars['String'];
};

export type MutationDeleteIndustryArgs = {
  industryId: Scalars['String'];
};

export type MutationInsertJobPositionArgs = {
  params: InsertJobPositionInputType;
};

export type MutationUpdateJobPositionArgs = {
  jobPositionId: Scalars['String'];
  params: UpdateJobPositionInputType;
};

export type MutationDeleteJobPositionArgs = {
  jobPositionId: Scalars['String'];
};

export type MutationCreateBlindArgs = {
  expertId: Scalars['String'];
  params: CreateBlindInputType;
};

export type MutationUpdateBlindArgs = {
  blindId: Scalars['String'];
  params?: InputMaybe<UpdateBlindInputType>;
};

export type MutationRemoveBlindArgs = {
  blindId: Scalars['String'];
};

export type MutationUpdateBlindFlowStatusArgs = {
  params: UpdateFlowStatusInputType;
};

export type MutationRevertBlindFlowStatusArgs = {
  blindId: Scalars['String'];
};

export type MutationUpdateExpertArgs = {
  expertId: Scalars['String'];
  params: UpdateExpertInputType;
};

export type MutationDeleteExpertTagArgs = {
  expertId: Scalars['String'];
  tagId: Scalars['String'];
};

export type MutationUpdateJobOrderArgs = {
  jobOrderId: Scalars['String'];
  params: UpdateJobOrderInputType;
};

export type MutationSendJobOrderSummaryEmailArgs = {
  jobOrderId: Scalars['String'];
  params: SendJobOrderSummaryEmailInputType;
};

export type MutationUpdateJobOrderAssignmentArgs = {
  jobOrderId: Scalars['String'];
  params: UpdateJobOrderAssignmentInputType;
};

export type MutationDeleteJobOrderTagArgs = {
  jobOrderId: Scalars['String'];
  tagId: Scalars['String'];
};

export type MutationDeleteJobOrderSkillMustHaveArgs = {
  jobOrderId: Scalars['String'];
  tagId: Scalars['String'];
};

export type MutationDeleteJobOrderSkillNiceToHaveArgs = {
  jobOrderId: Scalars['String'];
  tagId: Scalars['String'];
};

export type MutationRevertLastJobOrderStatusHistoryRecordArgs = {
  jobOrderId: Scalars['String'];
};

export type MutationRevertLastJobOrderAssignmentHistoryRecordArgs = {
  jobOrderId: Scalars['String'];
};

export type MutationRecalculateJobOrderFillRateArgs = {
  jobOrderId: Scalars['String'];
};

export type MutationInsertLanguageArgs = {
  params: InsertLanguageInputType;
};

export type MutationDeleteLanguageArgs = {
  languageId: Scalars['String'];
};

export type MutationDeleteTagArgs = {
  tagId: Scalars['String'];
};

export type MutationDeleteTagsArgs = {
  tagIds: Array<Scalars['String']>;
};

export type MutationUpdateTagArgs = {
  tagId: Scalars['String'];
  params: UpdateTagInputType;
};

export type MutationUpdateTagsArgs = {
  params: Array<UpdateTagsInputType>;
};

export type MutationUpdateUserRolesArgs = {
  userId: Scalars['String'];
  roles: Array<UserRole>;
};

export type MutationCompleteEmailVerificationArgs = {
  token: Scalars['String'];
};

export type MutationUpdateExpertProfileArgs = {
  expertId: Scalars['String'];
  params: UpdateExpertProfileInputType;
};

export type MutationUploadExpertPhotoArgs = {
  expertId: Scalars['String'];
  params: UploadExpertAvatarInputType;
};

export type MutationUploadExpertWordCloudArgs = {
  expertId: Scalars['String'];
  params: UploadExpertWordCloudInputType;
};

export type MutationToggleExpertSavedJobOrderArgs = {
  expertId: Scalars['String'];
  jobOrderId: Scalars['String'];
};

export type MutationToggleExpertHiddenJobOrderArgs = {
  expertId: Scalars['String'];
  jobOrderId: Scalars['String'];
};

export type MutationUpdateExpertNotificationConsentsArgs = {
  notificationConsents: ExpertNotificationConsentsInputType;
};

export type MutationCreateBlindViewHistoryArgs = {
  input: CreateExpertBlindViewHistoryInput;
};

export type MutationInsertNewsArgs = {
  params: InsertNewsInputType;
};

export type MutationUpdateNewsArgs = {
  newsId: Scalars['String'];
  params: UpdateNewsInputType;
};

export type MutationDeleteNewsArgs = {
  newsId: Scalars['String'];
};

export type MutationApplyForJobOrderArgs = {
  jobOrderId: Scalars['String'];
  meetingDates: Array<Scalars['String']>;
  finalScore: Scalars['Float'];
};

export type MutationConfirmApplicationHandingArgs = {
  applicationId: Scalars['String'];
};

export type MutationCancelApplicationArgs = {
  applicationId: Scalars['String'];
};

export type MutationUpdateJobOrderApplicationArgs = {
  applicationId: Scalars['String'];
  params?: InputMaybe<UpdateJobOrderApplicationInputType>;
};

export type MutationChangeApplicationStatusArgs = {
  applicationId: Scalars['String'];
  newStatus: Scalars['String'];
  meetingDate?: InputMaybe<Scalars['String']>;
  meetingUrl?: InputMaybe<Scalars['String']>;
};

export type MutationChangeApplicationHandlerArgs = {
  applicationId: Scalars['String'];
  handlerId: Scalars['String'];
};

export type MutationSynchronizeJobOrderWithCrmArgs = {
  jobOrderId: Scalars['String'];
};

export type MutationAddQueueJobArgs = {
  queueType: QueueTypeEnum;
  data: Scalars['String'];
};

export type MutationMarkExpertNotificationAsViewedArgs = {
  notificationId: Scalars['String'];
};

export type MutationResendOtpArgs = {
  email: Scalars['String'];
};

export type MutationStartPasswordResetArgs = {
  email: Scalars['String'];
};

export type MutationVerifyPasswordResetOtpArgs = {
  email: Scalars['String'];
  otp: Scalars['String'];
};

export type MutationCreatePublicOfferArgs = {
  params: CreatePublicOfferInputType;
};

export type MutationUpdatePublicOfferArgs = {
  offerId: Scalars['String'];
  params: UpdatePublicOfferInputType;
};

export type MutationUpdatePublicOfferStatusArgs = {
  offerId: Scalars['String'];
  status: PublicOfferStatusEnum;
};

export type MutationDeletePublicOfferArgs = {
  offerId: Scalars['String'];
};

export type MutationRegisterExpertLoginFromPublicOfferArgs = {
  expertId: Scalars['String'];
  offerId: Scalars['String'];
};

export type MutationApplyForPublicOfferArgs = {
  params: ApplyForPublicOfferInputType;
};

export type MutationSendPublicOfferEmailArgs = {
  params: SendPublicOfferEmailInputType;
};

export type MutationCreateJobOrderPublicationArgs = {
  jobOrderId: Scalars['String'];
  mediumId: Scalars['String'];
  url: Scalars['String'];
};

export type MutationUpdateJobOrderPublicationArgs = {
  jobOrderId: Scalars['String'];
  mediumId: Scalars['String'];
  url: Scalars['String'];
};

export type MutationUpdateJobOrderPublicationsArgs = {
  jobOrderId: Scalars['String'];
  publications: Array<UpdateJobOrderPublicationsInputType>;
};

export type MutationDeleteJobOrderPublicationArgs = {
  jobOrderId: Scalars['String'];
  mediumId: Scalars['String'];
};

export type MutationSetCognitoPasswordArgs = {
  password: Scalars['String'];
};

export type MutationVerifyGenericPurposeOtpArgs = {
  otp: Scalars['String'];
};

export type MutationSignExpertCvUploadCredentialsArgs = {
  params: SignExpertCvUploadCredentialsInputType;
};

export type MutationSignExpertCvUploadCredentialsWithRecaptchaArgs = {
  params: SignExpertCvUploadCredentialsWithRecaptchaInputType;
};

export type MutationCompleteExpertCvUploadArgs = {
  fileId: Scalars['String'];
};

export type MutationDeleteExpertCvArgs = {
  fileId: Scalars['String'];
};

export type MutationSendAllBlindsReportArgs = {
  microsoftGraphAccessToken: Scalars['String'];
};

export type MutationNotifyAboutMatchedJobOrderArgs = {
  jobOrderId: Scalars['String'];
  expertsCount: Scalars['Int'];
};

export type SendBlindNotificationEmailInputType = {
  blindIds: Array<Scalars['String']>;
  text: Scalars['String'];
  accessToken: Scalars['String'];
  to: Array<Scalars['String']>;
  cc?: InputMaybe<Array<Scalars['String']>>;
  attachments?: InputMaybe<Array<SendBlindAttachmentType>>;
  subject?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  isPdfAttached: Scalars['Boolean'];
};

export type SendBlindAttachmentType = {
  contentBase64: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
};

export type InsertJobPositionInputType = {
  name: Scalars['String'];
  defaultTechnologies?: InputMaybe<Array<Scalars['String']>>;
  availableMainTechnologies?: InputMaybe<Array<JobPositionMainTechnologiesInputType>>;
  isOnMainList?: InputMaybe<Scalars['Boolean']>;
};

export type JobPositionMainTechnologiesInputType = {
  name?: InputMaybe<Scalars['String']>;
  mainTechnologies: Array<MainTechnologyInputType>;
};

export type UpdateJobPositionInputType = {
  name?: InputMaybe<Scalars['String']>;
  defaultTechnologies?: InputMaybe<Array<Scalars['String']>>;
  availableMainTechnologies?: InputMaybe<Array<JobPositionMainTechnologiesInputType>>;
  isOnMainList?: InputMaybe<Scalars['Boolean']>;
};

export type CreateBlindInputType = {
  availability: Scalars['String'];
  jobOrderId: Scalars['String'];
  contactPersonId: Scalars['String'];
  recommendation?: InputMaybe<Scalars['String']>;
  englishRecommendation?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  margin: Scalars['Float'];
  isMoneyRateDisplayed: Scalars['Boolean'];
  isRecommendationDisplayed: Scalars['Boolean'];
  isWorkHistoryDisplayed: Scalars['Boolean'];
  isCertificatesDisplayed: Scalars['Boolean'];
  isBlindMinus: Scalars['Boolean'];
  isBlindPlus: Scalars['Boolean'];
  isVendorless: Scalars['Boolean'];
  isDisplayOnlyFirstLetterOfLastNameChosen: Scalars['Boolean'];
  isDatepickerHidden: Scalars['Boolean'];
  isFullProfile: Scalars['Boolean'];
  profileSource: ProfileSourceEnum;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsVisibleOnBlind?: InputMaybe<Array<Scalars['String']>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateBlindInputType = {
  availability?: InputMaybe<Scalars['String']>;
  recommendation?: InputMaybe<Scalars['String']>;
  englishRecommendation?: InputMaybe<Scalars['String']>;
  margin?: InputMaybe<Scalars['Float']>;
  isMoneyRateDisplayed?: InputMaybe<Scalars['Boolean']>;
  isRecommendationDisplayed?: InputMaybe<Scalars['Boolean']>;
  isWorkHistoryDisplayed?: InputMaybe<Scalars['Boolean']>;
  isCertificatesDisplayed?: InputMaybe<Scalars['Boolean']>;
  isBlindMinus?: InputMaybe<Scalars['Boolean']>;
  isBlindPlus?: InputMaybe<Scalars['Boolean']>;
  isDatepickerHidden?: InputMaybe<Scalars['Boolean']>;
  isVendorless?: InputMaybe<Scalars['Boolean']>;
  isDisplayOnlyFirstLetterOfLastNameChosen?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isFullProfile?: InputMaybe<Scalars['Boolean']>;
  contactPersonId?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsVisibleOnBlind?: InputMaybe<Array<Scalars['String']>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateFlowStatusInputType = {
  blindId: Scalars['String'];
  flowStatus: ExpertBlindFlowStatusEnum;
  sentTo?: InputMaybe<Array<Scalars['String']>>;
  changedAt?: InputMaybe<Scalars['DateTime']>;
  meetingDate?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<ExpertBlindFlowStatusFeedbackEnum>;
  meetingUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateExpertInputType = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  jobPositionId?: InputMaybe<Scalars['String']>;
  mainTechnologyIds?: InputMaybe<Array<Scalars['String']>>;
  experienceYears?: InputMaybe<Scalars['Int']>;
  rateMin?: InputMaybe<Scalars['Float']>;
  rateMax?: InputMaybe<Scalars['Float']>;
  rateDesired?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  workType?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<SeniorityLevelEnum>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsVisibleOnBlind?: InputMaybe<Array<Scalars['String']>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<LanguageInputType>>;
  certificates?: InputMaybe<Array<ExpertCertificateInputType>>;
  workHistory?: InputMaybe<Array<ExpertWorkHistoryInputType>>;
  recommendation?: InputMaybe<Scalars['String']>;
  englishRecommendation?: InputMaybe<Scalars['String']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  availability?: InputMaybe<ExpertAvailabilityEnum>;
  noticeLength?: InputMaybe<Scalars['Int']>;
  githubUrl?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  descriptiveLabel?: InputMaybe<Scalars['String']>;
  cvId?: InputMaybe<Scalars['String']>;
};

export type LanguageInputType = {
  languageId: Scalars['String'];
  level: LanguageLevelEnum;
};

export type UpdateJobOrderInputType = {
  jobOrderId?: InputMaybe<Scalars['String']>;
  customId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  clientBudget?: InputMaybe<Scalars['String']>;
  isRelocationRequired?: InputMaybe<Scalars['Boolean']>;
  dateStart1?: InputMaybe<Scalars['DateTime']>;
  dateStart2?: InputMaybe<Scalars['DateTime']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  owner?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<UpdateJobOrderAssigneesParams>>;
  leadingTechnology?: InputMaybe<Scalars['String']>;
  bttl?: InputMaybe<Scalars['Int']>;
  vacancies?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  requirements?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  potentialClientId?: InputMaybe<Scalars['String']>;
  potentialClientName?: InputMaybe<Scalars['String']>;
  responsibilities?: InputMaybe<Scalars['String']>;
  deliveryInformation?: InputMaybe<Scalars['String']>;
  isRemotePossible?: InputMaybe<Scalars['Boolean']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  additionalInfo?: InputMaybe<Scalars['String']>;
  targetProjectInfo?: InputMaybe<Scalars['String']>;
  salesmanComment?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  interviewsCount?: InputMaybe<Scalars['Int']>;
  industries?: InputMaybe<Array<Scalars['String']>>;
  hiredCount?: InputMaybe<Scalars['Int']>;
  itsMaxSalary?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdByEmail?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  statusCode?: InputMaybe<Scalars['Int']>;
  statusName?: InputMaybe<Scalars['String']>;
  businessStatus?: InputMaybe<JobOrderBusinessStatusEnum>;
  definitionOfReadyStatus?: InputMaybe<JobOrderDefinitionOfReadyStatusEnum>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  role?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<SeniorityLevelEnum>;
  experienceYears?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  jobOrderDescription?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  skillsMustHave?: InputMaybe<Array<ExpertTagWeightInputType>>;
  skillsNiceToHave?: InputMaybe<Array<ExpertTagWeightInputType>>;
  targetProductInfo?: InputMaybe<Scalars['String']>;
  workMethodology?: InputMaybe<WorkMethodologyEnum>;
  isEnglish?: InputMaybe<Scalars['Boolean']>;
  /** to be refined */
  minEnglishLevel?: InputMaybe<LanguageLevelEnum>;
  workMode?: InputMaybe<WorkModeEnum>;
  workModeParamsDescription?: InputMaybe<Scalars['String']>;
  locationPreference?: InputMaybe<Scalars['String']>;
  rateRangeFrom?: InputMaybe<Scalars['Int']>;
  rateRangeTo?: InputMaybe<Scalars['Int']>;
  minMargin?: InputMaybe<Scalars['Float']>;
  workOrderLengthInMonths?: InputMaybe<Scalars['Int']>;
  workOrderLength?: InputMaybe<WorkOrderLengthEnum>;
  /** For how long the work order is open */
  maxAvailability?: InputMaybe<MaxAvailabilityEnum>;
  workHoursScope?: InputMaybe<WorkHourScopeEnum>;
  clientParamsScore?: InputMaybe<UpdateJobOrderClientParamsScore>;
  /** Additional custom flag (not mapped from CRM) to mark that JO has all required information */
  allRequiredFieldsAreFilled?: InputMaybe<Scalars['Boolean']>;
  statusHistory?: InputMaybe<Array<JobOrderStatusHistoryInputType>>;
  assignmentHistory?: InputMaybe<Array<JobOrderAssignmentHistoryInputType>>;
  isChubVisible?: InputMaybe<Scalars['Boolean']>;
  fillRateKpi?: InputMaybe<Scalars['Boolean']>;
  fillRateKpiComment?: InputMaybe<FillRateKpiCommentEnum>;
  businessOwner?: InputMaybe<Array<JobOrderBusinessOwnerInputType>>;
  hubSpotCompanyId?: InputMaybe<Scalars['String']>;
  hubSpotCompanyName?: InputMaybe<Scalars['String']>;
  resultStatus?: InputMaybe<JobOrderResultEnum>;
  type?: InputMaybe<JobOrderTypeEnum>;
  isPTCBusinessDeadlineEnabled?: InputMaybe<Scalars['Boolean']>;
  PTCBusinessDeadline?: InputMaybe<Scalars['DateTime']>;
  summarySentAt?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type UpdateJobOrderAssigneesParams = {
  brm?: InputMaybe<Scalars['String']>;
  talentAdvocates?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateJobOrderClientParamsScore = {
  roleLeadingTechnologyScore?: InputMaybe<DifficultyLevelEnum>;
  rateScore?: InputMaybe<DifficultyLevelEnum>;
  businessDescriptionScore?: InputMaybe<QualityLevelEnum>;
  jobOrderDescriptionScore?: InputMaybe<QualityLevelEnum>;
  businessRequirementsDescriptionScore?: InputMaybe<QualityLevelEnum>;
  businessResponsibilityDescriptionScore?: InputMaybe<QualityLevelEnum>;
  clientPtcFeedbackScore?: InputMaybe<FeedbackScoreEnum>;
  clientCiFeedbackScore?: InputMaybe<FeedbackScoreEnum>;
  clientRecruitmentProcessLength?: InputMaybe<RecruitmentProcessLengthEnum>;
  /** how many profiles should be sent before JO can be considered "done" */
  fillRateThresholdPerOpening?: InputMaybe<Scalars['Int']>;
  recruitmentTest?: InputMaybe<RecruitmentTestEnum>;
  isVerificationNeeded?: InputMaybe<Scalars['Boolean']>;
  contractSigned?: InputMaybe<ContractSignedEnum>;
  jobOrderScore?: InputMaybe<Scalars['Float']>;
};

export type SendJobOrderSummaryEmailInputType = {
  to: Array<Scalars['String']>;
  subject: Scalars['String'];
  message: Scalars['String'];
  accessToken: Scalars['String'];
};

export type UpdateJobOrderAssignmentInputType = {
  owner?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<UpdateJobOrderAssigneesParams>>;
  shouldResetBttl?: InputMaybe<Scalars['Boolean']>;
};

export type InsertLanguageInputType = {
  name: Scalars['String'];
};

export type UpdateTagInputType = {
  name?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagTypeEnum>;
};

export type UpdateTagsInputType = {
  name?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagTypeEnum>;
  id: Scalars['String'];
};

export type UpdateExpertProfileInputType = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  jobPositionName?: InputMaybe<Scalars['String']>;
  jobPositionId?: InputMaybe<Scalars['String']>;
  mainTechnologyIds?: InputMaybe<Array<Scalars['String']>>;
  experienceYears?: InputMaybe<Scalars['Int']>;
  rateMin?: InputMaybe<Scalars['Float']>;
  rateMax?: InputMaybe<Scalars['Float']>;
  rateDesired?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationObject?: InputMaybe<GoogleLocationObjectInput>;
  desiredOfficeLocations?: InputMaybe<Array<Scalars['String']>>;
  desiredTechnicalSkills?: InputMaybe<Array<Scalars['String']>>;
  desiredIndustries?: InputMaybe<Array<Scalars['String']>>;
  desiredFields?: InputMaybe<Array<Scalars['String']>>;
  workType?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<ExpertTagWeightInputType>>;
  tagsSuperPowers?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<LanguageInputType>>;
  seniority?: InputMaybe<SeniorityLevelEnum>;
  certificates?: InputMaybe<Array<ExpertCertificateInputType>>;
  workHistory?: InputMaybe<Array<ExpertWorkHistoryInputType>>;
  availability?: InputMaybe<Scalars['String']>;
  noticeLength?: InputMaybe<Scalars['Int']>;
  manuallyAddedJobPositions?: InputMaybe<Array<Scalars['String']>>;
  manuallyAddedMainTechnologies?: InputMaybe<Array<Scalars['String']>>;
  manuallyAddedDesiredTechnicalSkills?: InputMaybe<Array<Scalars['String']>>;
  avatar?: InputMaybe<ExpertAvatarEnum>;
  isVerifiedNotificationDisplayed?: InputMaybe<Scalars['Boolean']>;
  githubUrl?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  isCertificatesSkipped?: InputMaybe<Scalars['Boolean']>;
  cvId?: InputMaybe<Scalars['String']>;
};

export type UploadExpertAvatarInputType = {
  name: Scalars['String'];
  fileBase64: Scalars['String'];
  mimeType: Scalars['String'];
};

export type UploadExpertWordCloudInputType = {
  name: Scalars['String'];
  fileBase64: Scalars['String'];
};

export type CreateExpertBlindViewHistoryInput = {
  timestamp?: InputMaybe<Scalars['DateTime']>;
  userAgent: Scalars['String'];
  blindId: Scalars['String'];
  ip: Scalars['String'];
};

export type InsertNewsInputType = {
  title: Scalars['String'];
  content: Scalars['String'];
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateNewsInputType = {
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateJobOrderApplicationInputType = {
  meetingDates?: InputMaybe<Array<Scalars['String']>>;
  handlerId?: InputMaybe<Scalars['String']>;
  handlingExpiresAt?: InputMaybe<Scalars['String']>;
  handlingConfirmedAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobOrderApplicationStatusEnum>;
  selectedMeetingDate?: InputMaybe<Scalars['String']>;
  processStatus?: InputMaybe<JobOrderApplicationProcessStatusEnum>;
  meetingUrl?: InputMaybe<Scalars['String']>;
};

export enum QueueTypeEnum {
  CrmJobOrderSyncRequest = 'CrmJobOrderSyncRequest',
  ExpertBlindUpdated = 'ExpertBlindUpdated',
  ExpertBlindCreated = 'ExpertBlindCreated',
  ExpertBlindPlacement = 'ExpertBlindPlacement',
  ExpertNotificationInserted = 'ExpertNotificationInserted',
  ExpertSearchLog = 'ExpertSearchLog',
  ExpertUpdated = 'ExpertUpdated',
  JobOrderInserted = 'JobOrderInserted',
  JobOrderUpdated = 'JobOrderUpdated',
  JobOrderVisibilityChanged = 'JobOrderVisibilityChanged',
  JobOrderMatchedToExpert = 'JobOrderMatchedToExpert',
  JobOrderApplicationUpdated = 'JobOrderApplicationUpdated',
  NewTag = 'NewTag',
  UserRequest = 'UserRequest',
  ExpertAppliedForJobOrderNotification = 'ExpertAppliedForJobOrderNotification',
  JobOrderApplicationExpired = 'JobOrderApplicationExpired',
  JobOrderApplicationCiScheduled = 'JobOrderApplicationCiScheduled',
  JobOrderFillRateUpdated = 'JobOrderFillRateUpdated',
  AdminBlindsReportRequested = 'AdminBlindsReportRequested',
}

export type CreatePublicOfferInputType = {
  jobOrderId: Scalars['String'];
  ownerId: Scalars['String'];
  description: Scalars['String'];
  isRateDisplayed: Scalars['Boolean'];
  context: PublicOfferContextEnum;
  contextContactEmail?: InputMaybe<Scalars['String']>;
};

export type UpdatePublicOfferInputType = {
  ownerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isRateDisplayed?: InputMaybe<Scalars['Boolean']>;
  context?: InputMaybe<PublicOfferContextEnum>;
  contextContactEmail?: InputMaybe<Scalars['String']>;
};

export type ApplyForPublicOfferInputType = {
  offerId: Scalars['String'];
  cvFileId: Scalars['String'];
  expertId: Scalars['String'];
  recaptchaToken: Scalars['String'];
  recaptchaAction: Scalars['String'];
};

export type SendPublicOfferEmailInputType = {
  offerId: Scalars['String'];
  accessToken: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
  attachments: Array<SendPublicOfferAttachmentInputType>;
};

export type SendPublicOfferAttachmentInputType = {
  name: Scalars['String'];
  mimeType: Scalars['String'];
  contentBase64: Scalars['String'];
};

export type UpdateJobOrderPublicationsInputType = {
  mediumId: Scalars['String'];
  url: Scalars['String'];
};

export type SignExpertCvUploadCredentialsInputType = {
  name: Scalars['String'];
  size: Scalars['Int'];
  mimeType: Scalars['String'];
  source: ExpertCvSourceEnum;
  offerId?: InputMaybe<Scalars['String']>;
  expertId?: InputMaybe<Scalars['String']>;
  expertEmail?: InputMaybe<Scalars['String']>;
};

export type SignExpertCvUploadCredentialsWithRecaptchaInputType = {
  name: Scalars['String'];
  size: Scalars['Int'];
  mimeType: Scalars['String'];
  source: ExpertCvSourceEnum;
  offerId?: InputMaybe<Scalars['String']>;
  expertId?: InputMaybe<Scalars['String']>;
  expertEmail?: InputMaybe<Scalars['String']>;
  recaptchaToken: Scalars['String'];
  recaptchaAction: Scalars['String'];
};
